import React from "react";
import { Link } from "react-router-dom";
import passvissIcon from "../assets/images/passvissIcon.png";
import eyeslash from "../assets/images/eyeslash.svg";
import loginlogo from "../assets/images/loginlogo.png";
import loginrightbg from "../assets/images/loginrightbg.png";
import { useState } from "react";
import { fetchMe, login } from "../services/home";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Footer from "../components/footer";
import showNotification from "../components/notification/notification";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [eror, setEror] = useState(false);
  const [erorPass, setErorPass] = useState(false);
  const loginFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      const res = await login(values);
      if (res.status) {
        localStorage.setItem("auth-token", res.data.token);
        navigate("/");
        getMeData();
      } else {
        // showNotification(res, "danger");
        if (res.status === 0) {
          // showNotification(res.message, "danger");
          setEror(false);
          setErorPass(true);
        } else {
          // showNotification(res, "danger");
          setErorPass(false);
          setEror(true);
        }
      }
    },
  });

  const getMeData = async () => {
    const res = await fetchMe();
    if (res.status) {
      localStorage.setItem("data", JSON.stringify(res.data[0]));
    }
  };

  return (
    <form onSubmit={loginFormik.handleSubmit}>
      <div class="loginPage">
        <div class="loginPageLeft">
          <div class="loginlogo">
            <img
              src={loginlogo}
              alt=""
            />
          </div>
          <div class="loginleftText">
            <p>Welcome to Agro</p>
            <h2>Agro Management Application</h2>
          </div>
        </div>
        <div class="loginPageRight">
          <div class="loginRightContent ">
            <h3 className="heading3">Login</h3>

            <div class={eror ? "userName eror" : "userName"}>
              <div class="loginUsrname">
                <label class="loginUsrnameLabel">User Name</label>
                <input
                  required
                  name="email"
                  type="Email"
                  placeholder="Enter user name"
                  value={loginFormik.values.email}
                  onChange={loginFormik.handleChange}
                />
              </div>
              <p class="emaileror">
                The email address you entered isn't connected to an account
              </p>
            </div>
            <div
              class={
                erorPass
                  ? "loginUsrname password eror"
                  : "loginUsrname password "
              }>
              <label class="loginUsrnameLabel">Password</label>
              <input
                required
                type={showPassword ? "text" : "Password"}
                name="password"
                placeholder="Enter password"
                value={loginFormik.values.password}
                onChange={loginFormik.handleChange}
                maxlength="20"
                minLength={6}
              />
              <div
                class="vissIcon"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}>
                <img
                  src={showPassword ? eyeslash : passvissIcon}
                  alt=""
                />
              </div>
            </div>
            <div class="forgottenPass">
              {erorPass ? (
                <p className="passEror ">Enter correct password</p>
              ) : (
                " "
              )}
              {erorPass ? (
                ""
              ) : (
                <Link
                  className="forgotPasswordlink"
                  to="/forgotPassword">
                  Forgot Password?
                </Link>
              )}
            </div>
            <div class={erorPass ? "loginbtn passwordRightLogIn" : "loginbtn"}>
              {erorPass ? (
                <Link to="/forgotPassword">Forgot Password?</Link>
              ) : (
                ""
              )}
              <button type="submit">LOGIN</button>
            </div>
          </div>
          <div className="imgbg">
            <img
              className="loginRightbg"
              src={loginrightbg}
              alt="aa"
            />
            <div className="copy">
              © All copyrights reserved by Latitude Geospatial .
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Login;
