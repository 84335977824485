import React, { useEffect, useState } from "react";
import cardBoxImage from "../../../assets/images/cardboxbg.png";
import Back from "../../../assets/images/backbtn.svg";
import { useNavigate } from "react-router-dom";
import { fetchProjectMonitoring } from "../../../services/report";
import CsvDownloadButton from "react-json-to-csv";
// import CsvDownload from "../../../components/downloadReport";
import Papa from "papaparse";
import { Box, Pagination } from "@mui/material";
import Notfound from "../../data-not-found";
import { downloadCsv } from "../../../services/user";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import CommentM from "../../../assets/images/commentmark.png";
import CSV from "../../../assets/images/csv.svg";
import Pdf from "../../../assets/images/pdf.svg";
import { useSelector } from "react-redux";
var fileDownload = require("js-file-download");

const ProjectMonitoringList = () => {
  const zoneRefresh = useSelector((state) => state.updateZone.value);

  const [reportList, setReportList] = useState([]);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [pageSize, setPageSize] = useState(0);
  const [comments, setComments] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getReportList = async (page) => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const res = await fetchProjectMonitoring(
      page,
      regionId,
      startDate,
      endDate
    );
    if (res.status) {
      setReportList({
        header: res.header,
        row: res.row,
      });
      getNoOfPage(res);
      setPageSize(res.count);
    }
  };

  const convertToCsv = async (downloadType) => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    downloadCsv(12, downloadType, regionId, startDate, endDate).then((res) => {
      if (res) {
        if (downloadType == 1) {
          createCsv(res);
        } else {
          fileDownload(res, "file.pdf");
        }
      }
    });
  };
  const createCsv = (res, downloadType) => {
    const blob = new Blob([res], { type: "text/csv;charset=utf-8;" });
    // Create a temporary URL for the Blob
    const csvUrl = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const downloadLink = document.createElement("a");
    downloadLink.href = csvUrl;
    downloadLink.download = "file.csv";
    // Simulate a click on the link to trigger the download
    downloadLink.click();

    // Clean up by revoking the URL
    window.URL.revokeObjectURL(csvUrl);
  };

  useEffect(() => {
    getReportList(page);
  }, [page, zoneRefresh]);

  const itemsPerPage = 10;

  const getNoOfPage = (data) => {
    const res = Math.ceil(data.count / itemsPerPage);

    setNoOfPages(res);
  };

  const handleChangePagination = async (event, value) => {
    setPage(value);
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader tablePageHeader">
          <h3>Project Monitoring</h3>
          <div className="tablePageButtons">
            <button onClick={goBack}>
              {" "}
              <img
                src={Back}
                alt=""
              />{" "}
              Back
            </button>
            <div className="downloadBtns">
              <button
                className={
                  pageSize === 0
                    ? "fadeDownload downloadtblbtn"
                    : "downloadtblbtn"
                }
                onClick={() => {
                  convertToCsv(1);
                }}
                type="button">
                Download CSV
                <img
                  className="dwlnldIcon"
                  src={CSV}
                  alt=""
                />
              </button>
              <button
                className={
                  pageSize === 0
                    ? "fadeDownload downloadtblbtn"
                    : "downloadtblbtn"
                }
                onClick={() => {
                  convertToCsv(2);
                }}
                type="button">
                Download PDF
                <img
                  className="dwlnldIcon"
                  src={Pdf}
                  alt=""
                />
              </button>
            </div>
          </div>
          {/* <button onClick={goBack}><img src={Download} alt="" /> DOWNLOAD</button> */}
        </div>
        <div className="cardbg">
          <img
            src={cardBoxImage}
            alt="card bg"
          />
        </div>
      </div>{" "}
      <form>
        <div className="politryStockForm">
          <div className="usermngntPageTable tableBlockPage">
            <table className="usermngmntTable incomeReportTable">
              <thead>
                <tr>
                  <th
                    className="incomereportTableHead"
                    colSpan="9">
                    Farming Districts
                  </th>
                </tr>
                <tr>
                  {reportList &&
                    reportList?.header?.map((value) => {
                      return (
                        <th rowSpan="1">
                          {" "}
                          <strong>{value}</strong>
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {reportList && reportList?.row?.length > 0 ? (
                  <>
                    {reportList &&
                      reportList?.row?.map((value) => {
                        return (
                          <tr>
                            <td className="employeeName">{value.indicator}</td>
                            <td className="cliTotal">{value.total}</td>
                            {value.count.map((data) => {
                              return <td>{data.count}</td>;
                            })}
                            <td>
                              <Button
                                className="commentMarkbtn"
                                onClick={(e) => {
                                  handleShow();
                                  setComments(value?.commentDetails);
                                }}>
                                <div className="incomeNotes">
                                  {value.comments === "" ||
                                  value.comments === " "
                                    ? "-"
                                    : value.comments}{" "}
                                  <img
                                    className="commentMark"
                                    src={CommentM}
                                    alt=""
                                  />
                                </div>
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </>
                ) : (
                  <div className="tablenotfound">
                    <Notfound />
                  </div>
                )}
              </tbody>
            </table>
            {/* {pageSize > 10 ? (
              <Box
                justifyContent={"center"}
                alignItems="center"
                display={"flex"}
                sx={{ margin: "20px 0px" }}
              >
                <Pagination
                  page={page}
                  count={noOfPages}
                  variant="outlined"
                  color="primary"
                  onChange={handleChangePagination}
                  showFirstButton
                  showLastButton
                />
              </Box>
            ) : (
              ""
            )} */}
          </div>
        </div>
      </form>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Comments</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            {comments.map((value) => {
              return <li>{value}</li>;
            })}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default ProjectMonitoringList;
