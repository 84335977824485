import { requestWithToken, requestWithoutToken } from "../helpers/helpers";
const login = async (payload) => {
  try {
    const response = await requestWithoutToken("POST", "/user/login", payload);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchMe = async () => {
  try {
    const response = await requestWithToken("GET", "/user/me");
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export { 
  login,
  fetchMe,
};
