import { useState, React } from "react";
import cardBoxImage from "../../assets/images/cardboxbg.png";
import Back from "../../assets/images/backbtn.svg";
import {useNavigate} from 'react-router-dom';

const menubuttons = [
  {
    id: 2,
    name: "Fruits",
    lable: "Fruits",
  },
  {
    id: 3,
    name: "Roots",
    lable: "Roots",
  },
  {
    id: 4,
    name: "Spices",
    lable: "Spices",
  },
  {
    id: 5,
    name: "Pulses",
    lable: "Pulses",
  },
  {
    id: 6,
    name: "Vegetables",
    lable: "Vegetables",
  },
];

const AddProduction = () => {
  const [selectedMenuButtons, setSelectedMenuButtons] = useState([]);
  const [input, setInput] = useState({
    farmersCount: "",
    Cattle: "",
    Sheep: "",
    Goat: "",
    Swine: "",
    Poultry: "",
    Others: "",
  });

  const buttonClicked = (button) => {
    const index = selectedMenuButtons.findIndex((x) => x.id === button.id);
    if (index >= 0) {
      const menuButtons = selectedMenuButtons.filter((b) => b.id !== button.id);
      setSelectedMenuButtons(menuButtons);
    } else {
      setSelectedMenuButtons([...selectedMenuButtons, button]);
    }
  };

  const handleOnChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const tempInput = { ...input, [name]: value };

    setInput(tempInput);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1);  
  }
  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader">
          <h3>Add Production</h3>
          <button onClick={goBack}><img src={Back} alt="" /> Back</button>
        </div>
        <div className="cardbg">
          <img src={cardBoxImage} alt="card bg" />
        </div>
      </div>{" "}
      <form onSubmit={handleSubmit}>
        <div className="politryStockForm">
          <div className="politryStock">
            <div className="AddprodRow1">
            <div className="addproductionRadiobuttonsBox">
            Production
            <div className="addproductionRadiobuttons">
            <div className="addRadio">
               <input type="radio" id="Crop" name="fav_language" value="Crop"/>
               <label for="Crop">Crop</label>
             </div>
             <div className="addRadio ">
                 <input type="radio" id="Livestock" name="fav_language" value="Livestock"/>
                 <label for="Livestock">Livestock</label>
            </div>
            <div className="addRadio">
                 <input type="radio" id="Schools" name="fav_language" value="Schools"/>
                  <label for="Schools">Schools' Agriculture Support Program</label>
              </div>   
              </div>
              </div>           
           
              <div className="farmersBox">
                No. of Farmers
                <div className="farmers">
                  <input
                    type="number"
                    pattern="[0-9]*"
                    min="1"
                    id="number"
                    step="any"
                    value={input.farmersCount || ""}
                    name="farmersCount"
                    onChange={handleOnChangeInput}
                  />
                  <div className="farmersbtn">
                    <label id="discr">-</label>
                    <label id="insr" className="active">
                      +
                    </label>
                  </div>
                </div>
              </div>
             
            </div>
            <div className="AddprodRow3 ">
                <p class="schoolAgricltr addschool">School Agriculture Support Program Production Performance</p>
              <div className="addprodctRow addschool">
              <div className="row">
              <div className="col-md-3">
                <label className="prodctRowCol2"> Kitchen Garden</label>
                </div>
                <div className="col-md-3">
                <div className="inputAdd">
                  <label className="inputAddLabel">Crop</label>
                  <input type="text" placeholder="Enter Qty" />
                </div>
                </div>
                <div className="col-md-3">
                <div className="inputAdd">
                  <label className="inputAddLabel">Unit</label>
                  <input type="text" placeholder="Enter Qty" />
                </div>
                </div>
                </div>
                </div>
              <div className="addprodctRow addschool">
              <div className="row">
              <div className="col-md-3">
                <label className="prodctRowCol2">Shadehouse</label>
                </div>
                <div className="col-md-3">
                <div className="inputAdd">
                  <label className="inputAddLabel">Crop</label>
                  <input type="text" placeholder="Enter Qty" />
                </div>
                </div>
                <div className="col-md-3">
                <div className="inputAdd">
                  <label className="inputAddLabel">Unit</label>
                  <input type="text" placeholder="Enter Qty" />
                </div>
                </div>
                </div>
              </div>
              <div className="addprodctRow ">
              <div className="row">
              <div className="col-md-3">
                <label className="prodctRowCol2">Poultry Rearing</label>
                </div>
                <div className="col-md-3">
                <div className="inputAdd">
                  <label className="inputAddLabelWait">Live Weight</label>
                  <input type="text" placeholder="Enter Qty" />
                </div>
                </div>
                <div className="col-md-3">
                <div className="inputAdd">
                  <label className="inputAddLabelWait">Slaughter Weight</label>
                  <input type="text" placeholder="Enter Qty" />
                </div>
                </div>
                </div>
              </div>
            </div>
            </div>

          <div className="bottomButtons">
             <button type="submit" className="addprdt active" id="addprdt">
              Add production
            </button>
            <button type="reset" className="reset" id="rst" onclick="rst()">
              {" "}
              Reset
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddProduction;

