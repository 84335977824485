import React, { useEffect, useState } from "react";
import {
  fetchFoodSecurityCategery,
  fetchFoodSecurityIndicator,
} from "../../services/foodSecurity";
import { useNavigate } from "react-router-dom";
import cardboxbg from "./../../assets/images/cardboxbg.png";
import Back from "./../../assets/images/backbtn.svg";
import Pdf from "./../../assets/images/pdf.svg";
import CSV from "./../../assets/images/csv.svg";
import Notfound from "../../components/data-not-found";
import { convertToCsv } from "../../helpers/helpers";
import { downloadCsv } from "../../services/user";
import Shimmer from "../../components/shimmer";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";
import CommentM from "./../../assets/images/commentmark.png";
import moment from "moment";
import { useSelector } from "react-redux";

var fileDownload = require("js-file-download");

const FoodSecurityList = () => {
  const zoneRefresh = useSelector((state) => state.updateZone.value);

  const [tableHeader, setTableHeader] = useState([]);
  const [indicator, setIndicator] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [comments, setComments] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [firstData, setFirstData] = useState(false);
  const [secondData, setSecondData] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getFoodSecurityIndicator = async () => {
    let regionId = localStorage.getItem("selectedZone");
    let start = startDate;
    let end = endDate;
    const res = await fetchFoodSecurityIndicator(regionId, start, end);
    if (res.status) {
      setLoading(false);
      console.log(`${res.header} "ssssssss"`);
      setTableHeader([
        {
          header: res.data.header,
          row: res.data.row,
        },
      ]);
    } else {
      setSecondData(true);
    }
  };

  const getFoodSecurityCategery = async () => {
    let regionId = localStorage.getItem("selectedZone");
    let start = startDate;
    let end = endDate;
    const res = await fetchFoodSecurityCategery(regionId, start, end);
    if (res.status) {
      setLoading(false);
      setIndicator([
        {
          row: res.data.row,
        },
      ]);
    } else {
      setFirstData(true);
    }
  };

  useEffect(() => {
    getFoodSecurityIndicator();
    getFoodSecurityCategery();
  }, [zoneRefresh]);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const convertToCsv = async (downloadType) => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    downloadCsv(15, downloadType, regionId, startDate, endDate).then((res) => {
      if (res) {
        if (downloadType === 1) {
          createCsv(res);
        } else {
          fileDownload(res, "file.pdf");
        }
      }
    });
  };
  const createCsv = (res, downloadType) => {
    const blob = new Blob([res], { type: "text/csv;charset=utf-8;" });
    // Create a temporary URL for the Blob
    const csvUrl = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const downloadLink = document.createElement("a");
    downloadLink.href = csvUrl;
    downloadLink.download = "file.csv";
    // Simulate a click on the link to trigger the download
    downloadLink.click();

    // Clean up by revoking the URL
    window.URL.revokeObjectURL(csvUrl);
  };

  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader tablePageHeader reportTabledwnBtn ">
          <h3>Food Security </h3>
          <div className="tablePageButtons foodsecuritybackAnddwn">
            <button onClick={goBack}>
              <img
                src={Back}
                alt=""
              />{" "}
              Back
            </button>
            <div className="downloadBtns">
              <button
                className={
                  indicator[0]?.row?.length === 0 &&
                  tableHeader[0]?.row?.length === 0
                    ? "downloadtblbtn fadeDownload"
                    : "downloadtblbtn"
                }
                onClick={() => {
                  convertToCsv(1);
                }}
                type="button">
                Download CSV
                <img
                  className="dwlnldIcon"
                  src={CSV}
                  alt=""
                />
              </button>
              <button
                className={
                  indicator[0]?.row?.length === 0 &&
                  tableHeader[0]?.row?.length === 0
                    ? "downloadtblbtn fadeDownload"
                    : "downloadtblbtn"
                }
                onClick={() => {
                  convertToCsv(2);
                }}
                type="button">
                Download PDF
                <img
                  className="dwlnldIcon"
                  src={Pdf}
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
        <div className="cardbg">
          <img
            src={cardboxbg}
            alt="card bg"
          />
        </div>
      </div>
      <form>
        <div className="politryStockForm">
          <div className="politryStock addincomeForm addForm usrlsttbl ">
            <div className="row">
              <div className="col-md-3">
                <div className=" incomeRow incReport">
                  <label>Start Date</label>
                  <input
                    name="date"
                    type="date"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                    max={moment(new Date()).format("YYYY-MM-DD")}
                    required
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className=" incomeRow incReport">
                  <label>End Date</label>
                  <input
                    name="date"
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    max={moment(new Date()).format("YYYY-MM-DD")}
                    required
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="bottomButtons userMngmntBtn">
                  <button
                    type="button"
                    className="addprdt mngmntApplybtn active"
                    id="addprdt"
                    onClick={() => {
                      getFoodSecurityIndicator();
                      getFoodSecurityCategery();
                    }}>
                    APPLY
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="usermngntPageTable tableBlockPage foodSecurityTablePage">
          {loading ? (
            <Shimmer />
          ) : (
            <table className="table table-bordered usermngmntTable userListTbl incomeReportTable ">
              <thead>
                <tr>
                  <th
                    className="incomereportTableHead"
                    colSpan={8}>
                    Farming Districts
                  </th>
                </tr>
                <tr>
                  {tableHeader &&
                    tableHeader[0]?.header?.map((value, index) => {
                      if (index < 1) {
                        return (
                          <th
                            rowSpan={2}
                            class="thd alignmdleft">
                            <strong>{value}</strong>
                          </th>
                        );
                      }
                      return (
                        <th className="userListtbl">
                          {" "}
                          <strong>{value}</strong>
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {indicator[0]?.row?.length === 0 &&
                tableHeader[0]?.row?.length === 0 ? (
                  <Notfound />
                ) : (
                  <>
                    {tableHeader &&
                      tableHeader[0]?.row?.map((value, index) => {
                        return (
                          <>
                            <tr>
                              <td
                                className="medicalDrug"
                                colSpan={8}>
                                {value.indicator}
                              </td>
                            </tr>

                            {value.subIndicators.map((data, index) => {
                              return (
                                <tr>
                                  <td className="foodsecIndicators">
                                    {data.subIndicator}
                                  </td>
                                  <td className="fsTotal">{data.total}</td>
                                  {data.regionCounts.map((counts) => {
                                    return <td>{counts.count}</td>;
                                  })}
                                  <td>
                                    <Button
                                      className="commentMarkbtn"
                                      onClick={(e) => {
                                        handleShow();
                                        setComments(data.commentDetails);
                                      }}>
                                      <div className="incomeNotes">
                                        {data.commentCount === "" ||
                                        data.commentCount === " "
                                          ? "-"
                                          : data.commentCount}{" "}
                                        <img
                                          className="commentMark"
                                          src={CommentM}
                                          alt=""
                                        />{" "}
                                      </div>
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        );
                      })}
                    {indicator &&
                      indicator[0]?.row?.map((value, index) => {
                        return (
                          <>
                            <tr>
                              <td
                                className="medicalDrug"
                                colSpan={8}>
                                {" "}
                                {value.indicator}
                              </td>
                            </tr>
                            <tr>
                              <td
                                className="foodsecMedicalIndicators"
                                colSpan={8}>
                                {value.subIndicator}
                              </td>
                            </tr>
                            {value.categories.map((data, index) => {
                              return (
                                <tr>
                                  <td className="foodsecIndicators">
                                    {data.categoryName}
                                  </td>
                                  <td className="fsTotal">{data.total}</td>
                                  {data.regionCounts.map((counts) => {
                                    return <td>{counts.count}</td>;
                                  })}
                                  <td>
                                    <Button
                                      className="commentMarkbtn"
                                      onClick={(e) => {
                                        handleShow();
                                        setComments(data.commentDetails);
                                      }}>
                                      <div className="incomeNotes">
                                        {data.commentCount === "" ||
                                        data.commentCount === " "
                                          ? "-"
                                          : data.commentCount}{" "}
                                        <img
                                          className="commentMark"
                                          src={CommentM}
                                          alt=""
                                        />{" "}
                                      </div>
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
          )}
        </div>
      </form>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Comments</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            {comments.map((value) => {
              return <li>{value}</li>;
            })}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default FoodSecurityList;
