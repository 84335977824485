import React, { useEffect, useState } from "react";
import cardBoxImage from "./../assets/images/cardboxbg.png";
import Back from "./../assets/images/backbtn.svg";
import CSV from "./../assets/images/csv.svg";
import Pdf from "./../assets/images/pdf.svg";
import { useNavigate } from "react-router-dom";
import { fetchExpenseReport } from "../services/expanse";
import { Box, Pagination } from "@mui/material";
import moment from "moment";
import Notfound from "../components/data-not-found";
import { downloadIncomeExpenseReport } from "../services/user";
var fileDownload = require("js-file-download");

const ReportExpense = () => {
  const [page, setPage] = useState(1);
  const [reportList, setReportList] = useState([]);
  const [data, setData] = useState([]);
  const [transactionType, setTransactionType] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState(0);

  const getReportList = async () => {
    const payload = {
      transactionType: Number(transactionType),
      startDate: startDate,
      endDate: endDate,
    };

    const res = await fetchExpenseReport(page, pageSize, payload);
    if (res.status) {
      setTotalData(res.count);
      setReportList([
        {
          header: res.header,
          row: res.row,
        },
      ]);
      setData(res);
    }
  };

  useEffect(() => {
    getReportList();
  }, [page, pageSize]);

  const handleChangePagination = async (event, value) => {
    setPage(value);
  };

  const convertToCsv = async (downloadType) => {
    // let startDate = localStorage.getItem("startDate");
    // let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");

    downloadIncomeExpenseReport(
      7,
      downloadType,
      transactionType,
      regionId,
      startDate,
      endDate
    ).then((res) => {
      if (res) {
        if (downloadType == 1) {
          createCsv(res);
        } else {
          fileDownload(res, "file.pdf");
        }
      }
    });
  };
  const createCsv = (res) => {
    const blob = new Blob([res], { type: "text/csv;charset=utf-8;" });
    // Create a temporary URL for the Blob
    const csvUrl = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const downloadLink = document.createElement("a");
    downloadLink.href = csvUrl;
    downloadLink.download = "file.csv";
    // Simulate a click on the link to trigger the download
    downloadLink.click();

    // Clean up by revoking the URL
    window.URL.revokeObjectURL(csvUrl);
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader tablePageHeader reportTabledwnBtn">
          <h3>Report - Expense</h3>
          <div className="tablePageButtons">
            <button onClick={goBack}>
              {" "}
              <img
                src={Back}
                alt=""
              />{" "}
              Back
            </button>
            <div className="downloadBtns">
              <button
                className={
                  totalData === 0
                    ? "fadeDownload downloadtblbtn"
                    : "downloadtblbtn"
                }
                onClick={() => {
                  convertToCsv(1);
                }}
                type="button">
                Download CSV
                <img
                  className="dwlnldIcon"
                  src={CSV}
                  alt=""
                />
              </button>
              <button
                className={
                  totalData === 0
                    ? "fadeDownload downloadtblbtn"
                    : "downloadtblbtn"
                }
                onClick={() => {
                  convertToCsv(2);
                }}
                type="button">
                Download PDF
                <img
                  className="dwlnldIcon"
                  src={Pdf}
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
        <div className="cardbg">
          <img
            src={cardBoxImage}
            alt="card bg"
          />
        </div>
      </div>{" "}
      <form>
        <div className="politryStockForm">
          <div className="politryStock addincomeForm addForm usrlsttbl ">
            <div className="row">
              <div className="col-md-3">
                <div className=" incomeRow incReport">
                  <label> Type</label>
                  <select
                    name=""
                    id=""
                    value={transactionType}
                    onChange={(e) => {
                      setTransactionType(e.target.value);
                    }}
                    required>
                    <option
                      value=""
                      recuired>
                      Select Type
                    </option>
                    <option value={1}>Cash</option>
                    <option value={2}>MMG+</option>
                    <option value={3}>Net Banking</option>
                    <option value={4}>Cheque</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className=" incomeRow incReport">
                  <label>Start Date</label>
                  <input
                    name="date"
                    type="date"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                    max={moment(new Date()).format("YYYY-MM-DD")}
                    required
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className=" incomeRow incReport">
                  <label>End Date</label>
                  <input
                    name="date"
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    max={moment(new Date()).format("YYYY-MM-DD")}
                    required
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="bottomButtons userMngmntBtn">
                  <button
                    type="button"
                    className="addprdt mngmntApplybtn active"
                    id="addprdt"
                    onClick={() => {
                      getReportList();
                      setPage(1);
                    }}>
                    APPLY
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="usermngntPageTable tableBlockPage">
          <table className="usermngmntTable incomeReportTable">
            <thead>
              <tr>
                {reportList &&
                  reportList[0]?.header?.map((value) => {
                    return (
                      <th
                        rowSpan="2"
                        className="">
                        {" "}
                        <strong>{value}</strong>
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {reportList && reportList[0]?.row?.length > 0 ? (
                <>
                  {reportList &&
                    reportList[0]?.row?.map((value) => {
                      return (
                        <tr>
                          <td className="income-date">
                            {moment(value.saleDate).format("DD MMM yyyy")}
                          </td>
                          <td className="amount">{value.expenseCost}</td>
                          <td className="amount2">{value.amountPurchased}</td>
                          <td className="alignmdleft">
                            {value.type == 1
                              ? "Cash"
                              : value.type == 2
                              ? "MMG+"
                              : value.type == 3
                              ? "Net Banking"
                              : value.type == 4
                              ? "Cheque"
                              : " "}
                          </td>
                          <td>{value.units}</td>
                          <td>
                            {value.unitType === " "
                              ? "-"
                              : value.unitType === ""
                              ? "-"
                              : value.unitType}
                          </td>
                          <td className="reporttableComment">
                            <div className="incomeNotes">
                              {value.notes === "" ? "-" : value.notes}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  <tr className="totalIncome">
                    <td className="incometotaltext">Total</td>
                    <td class="ttlincomedata">{data.totalAmount}</td>
                    <td class="ttlincomedata">{data.totalAmountPurchased}</td>
                    <td
                      class="ttlincomedata"
                      colSpan={6}></td>
                  </tr>
                </>
              ) : (
                <div className="tablenotfound">
                  <Notfound />
                </div>
              )}
            </tbody>
          </table>
        </div>
        {totalData > 10 ? (
          <div className="paginantionBox">
            <div>
              <Pagination
                page={page}
                count={Math.ceil(Number(totalData / pageSize))}
                variant="outlined"
                color="primary"
                onChange={handleChangePagination}
                showFirstButton
                showLastButton
              />
            </div>
            <div className="perPageBox">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                  setPage(1);
                }}>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
              </select>
            </div>
          </div>
        ) : (
          " "
        )}
      </form>
    </div>
  );
};

export default ReportExpense;
