import { useState, React, useEffect } from "react";
import cardBoxImage from "../../assets/images/cardboxbg.png";
import Back from "../../assets/images/backbtn.svg";
import { createEggLog, fetchAnimals } from "../../services/addEgg";
import Animaltype from "./animaltype";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useFormik } from "formik";
import showNotification from "../../components/notification/notification";
import { Button, Modal } from "react-bootstrap";

const AddEggLogPage = () => {
  const navigate = useNavigate();
  const [menubuttons, setMenubuttons] = useState([]);
  const [eggbuttons, setEggbuttons] = useState([]);
  const [selectedMenuButtons, setSelectedMenuButtons] = useState([]);
  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(1);

  const addEggFormik = useFormik({
    initialValues: {
      date: "",
      data: [],
    },
    onSubmit: async (values) => {
      if (values.data.length == 1 && values.data[0].logDetails.length == 0) {
        showNotification("Please add some data", "danger");
      } else if (values.data.length) {
        const res = await createEggLog(values.data);
        if (res.status) {
          navigate("/");
          showNotification("Egg Log data added successfully!", " success");
        }
      } else {
        showNotification("Please select animal", "danger");
      }
    },
  });

  const getAnimalList = async () => {
    const res = await fetchAnimals();
    if (res.status) {
      setMenubuttons(res.data);
    }
  };

  useEffect(() => {
    getAnimalList();
  }, []);

  const onAnimalSelect = (button) => {
    const index = selectedMenuButtons.findIndex((x) => x._id === button._id);
    if (index >= 0) {
      const menuButtons = selectedMenuButtons.filter(
        (b) => b._id !== button._id
      );
      setSelectedMenuButtons(menuButtons);
    } else {
      setSelectedMenuButtons([...selectedMenuButtons, button]);
    }

    const regionId = JSON.parse(localStorage.getItem("data")).regionDetails[0]
      ._id;

    /// set egg colors
    button.colors.map((value) => {
      let checkValue = eggbuttons.some((egg) => egg._id === value._id);
      if (!checkValue) {
        setEggbuttons((eggbuttons) => [...eggbuttons, value]);
      }
    });

    let checkArrayExist = addEggFormik.values.data.some(
      (details) => details.animalTypeId === button._id
    );
    if (checkArrayExist) {
      addEggFormik.setFieldValue(
        "data",
        addEggFormik.values.data.filter((value) => {
          return value.animalTypeId !== button._id;
        })
      );
    } else {
      addEggFormik.setFieldValue("data", [
        ...addEggFormik.values.data,
        {
          date: addEggFormik.values.date,
          regionId: regionId,
          animalTypeId: button._id,
          animalTypeName: button.name,
          logDetails: button.colors.map((colorData) => {
            const colorPayload = {};
            colorPayload.colorId = colorData._id;
            colorPayload.name = colorData.name;
            colorPayload.eggCount = button.sizes.map((inputData) => {
              const payload = {};
              payload.sizeId = inputData._id;
              payload.name = inputData.name;
              payload.count = "";
              return payload;
            });
            return colorPayload;
          }),
        },
      ]);
    }
  };

  const reset = () => {
    let tempArray = [...addEggFormik.values.data];
    setSelectedMenuButtons([]);
    tempArray.forEach((element) => {
      element.logDetails.forEach((log) => {
        log.eggCount.forEach((egg) => {
          egg.count = null;
        });
      });
    });
    addEggFormik.setFieldValue("data", []);
  };

  const changeFormValue = (
    firstIndex,
    logDetailsIndex,
    eggCountIndex,
    value
  ) => {
    let tempArray = [...addEggFormik.values.data];
    tempArray[firstIndex].logDetails[logDetailsIndex].eggCount[
      eggCountIndex
    ].count = value;
    addEggFormik.setFieldValue("data", tempArray);
  };

  const deleteEggLog = (firstIndex, colorId) => {
    let tempArray = [...addEggFormik.values.data];
    let tempArray2 = [];
    tempArray2 = tempArray[firstIndex].logDetails.filter((value) => {
      return value.colorId !== colorId;
    });
    tempArray[firstIndex].logDetails = tempArray2;

    tempArray.map((value) => {
      if (!value.logDetails.length) {
        setSelectedMenuButtons(
          selectedMenuButtons.filter((item) => {
            return item._id !== value.animalTypeId;
          })
        );
      }
    });
    addEggFormik.setFieldValue(
      "data",
      tempArray.filter((value) => {
        return value.logDetails.length;
      })
    );
  };

  const goBack = () => {
    navigate(-1);
  };
  const addUserReset = () => {
    reset();
    setShow(false);
    setOpacity(0);
  };
  const handleClose = () => {
    setShow(false);
    setOpacity(0);
  };
  const handleShow = () => {
    setShow(true);
    setOpacity(1);
  };

  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader">
          <h3>Add Egg Log</h3>
          <button onClick={goBack}>
            <img src={Back} alt="" /> BACK
          </button>
        </div>
        <div className="cardbg">
          <img src={cardBoxImage} alt="card bg" />
        </div>
      </div>
      {/* -------------------Form---------------------- */}
      <form onSubmit={addEggFormik.handleSubmit}>
        <div className="politryStockForm">
          <div className="politryStock">
            <div className="AddprodRow1Egg">
              <div className="eggLogFarmer">
                <div className="farmersBox">
                  Date
                  <div className="farmersCelender">
                    <input
                      name="date"
                      type="date"
                      value={addEggFormik.values.date}
                      onChange={(e) => {
                        addEggFormik.setFieldValue("date", e.target.value);
                        if (addEggFormik.values.data.length) {
                          addEggFormik.values.data.map((value) => {
                            value.date = e.target.value;
                          });
                        }
                      }}
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      required
                    />
                  </div>
                </div>
                <div className="farmersBox">
                  Animal Type
                  <div className="buttons">
                    {menubuttons &&
                      menubuttons.map((button, index) => (
                        <button
                          onClick={() => onAnimalSelect(button)}
                          className={`${
                            selectedMenuButtons?.findIndex(
                              (x) => x._id === button._id
                            ) >= 0
                              ? "active"
                              : ""
                          }`}
                          key={`clBtn-${index}`}
                          type="button"
                        >
                          {button.name}
                        </button>
                      ))}
                  </div>
                </div>
              </div>

              <div className="farmersBox">
                Color of Eggs laid
                <div className="buttons">
                  {eggbuttons.map((button, index) => (
                    <button
                      type="button"
                      className={`active`}
                      key={`clBtn-${index}`}
                    >
                      {button.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            {addEggFormik.values.data.map((value, index) => {
              return (
                <Animaltype
                  firstIndex={index}
                  animalData={value}
                  changeFormValue={changeFormValue}
                  deleteEggLog={deleteEggLog}
                />
              );
            })}
          </div>
          {addEggFormik.values.data.length ? (
            <div className="bottomButtons">
              <button type="submit" className="addprdt active" id="addprdt">
                Add Egg
              </button>
              <button
                type="reset"
                className="reset"
                id="rst"
                onClick={handleShow}
              >
                {" "}
                Reset
              </button>
            </div>
          ) : (
            <div className="select-animal-type">
              {" "}
              <p>Please Select Animal </p>
            </div>
          )}
        </div>
      </form>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ opacity: opacity }}
      >
        <Modal.Header closeButton>
          <Modal.Title> Reset?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={addUserReset}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddEggLogPage;
