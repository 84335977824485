import React from "react";

const Datanot = () => {
  return (
    <div className="notfoundDatatext">
      <p>Data not found</p>
    </div>
  );
};

export default Datanot;
