import { React, useState } from "react";

const AddFoodSecurityForm = ({
  index,
  formData,
  changeFormValue,
  changeCategoryFormValue,
}) => {
  const onInputChange = (value, reportIndex, key) => {
    changeFormValue(index, reportIndex, value, key);
  };

  const onInputCategoryChange = (value, reportIndex, categoryIndex, key) => {
    changeCategoryFormValue(index, reportIndex, categoryIndex, value, key);
  };

  return (
    <div>
      <div className="foddsecurityRadio">
        <label className="subcatageryId">Sub Indicator</label>
        <div className="buttons AddPlantHealthbtn">
          {formData.reportDetails.map((value, reportIndex) => {
            return (
              <div className="foodsecurityRadioinput">
                <button
                  type="button"
                  key={value._id}
                  className={`${value.selected ? "active" : ""}`}
                  onClick={() => {
                    if (value?.categories) {
                      onInputChange(!value.selected, reportIndex, "selected");
                      console.log("jsdofj", value.categoryDetails);
                      // setSubIndicatorCategoryList(value.categories);
                    } else {
                      onInputChange(!value.selected, reportIndex, "selected");
                    }
                  }}
                  id={value._id}
                >
                  {value.name}
                </button>
              </div>
            );
          })}
        </div>
      </div>

      {formData.reportDetails.map((data, reportIndex) => {
        if (data.selected) {
          if (data.categoryDetails) {
            return (
              <div className="buttons AddPlantHealthbtn">
                <div className="col-md-3">
                  <div className="foodlabel">{data.name}</div>
                </div>{" "}
                {data.categoryDetails.map((categories, ind) => {
                  return (
                    <div className="foodsecurityRadioinput foodsecbtn">
                      <button
                        type="button"
                        key={ind}
                        className={`${categories.selected ? "active" : ""}`}
                        onClick={() => {
                          onInputCategoryChange(
                            !categories.selected,
                            reportIndex,
                            ind,
                            "selected"
                          );
                        }}
                        id={categories._id}
                      >
                        {categories.name}
                      </button>
                    </div>
                  );
                })}
                {data.categoryDetails.map((categories, ind) => {
                  if (categories.selected) {
                    return (
                      <div className="prodctRow fsproduct foodsecurityaddform">
                        <div className="col-md-3">
                          <div className="foodlabel">{data.name}</div>
                        </div>{" "}
                        <div className="col-md-4">
                          <div className="inputAdd">
                            <label className="inputAddLabel">Count</label>
                            <input
                              required
                              type="number"
                              value={categories?.count}
                              name="small"
                              onChange={(e) => {
                                onInputCategoryChange(
                                  e.target.value,
                                  reportIndex,
                                  ind,
                                  "count"
                                );
                              }}
                              placeholder="Enter count"
                              onWheel={(event) => event.currentTarget.blur()}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="inputAdd">
                            <label className="inputAddLabel">Comment</label>
                            <input
                              required
                              type="text"
                              value={categories?.comments}
                              name="small"
                              onChange={(e) => {
                                onInputCategoryChange(
                                  e.target.value,
                                  reportIndex,
                                  ind,
                                  "comments"
                                );
                              }}
                              placeholder="Enter comments"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            );
          } else {
            return (
              <div className="prodctRow fsproduct foodsecurityaddform">
                <div className="col-md-3">
                  <div className="foodlabel">{data.name}</div>
                </div>
                <div className="col-md-4">
                  <div className="inputAdd">
                    <label className="inputAddLabel">Count</label>
                    <input
                      required
                      type="number"
                      value={data?.count}
                      name="small"
                      onChange={(e) => {
                        onInputChange(e.target.value, reportIndex, "count");
                      }}
                      placeholder="Enter count"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="inputAdd">
                    <label className="inputAddLabel">Comment</label>
                    <input
                      required
                      type="text"
                      value={data?.comments}
                      name="small"
                      onChange={(e) => {
                        onInputChange(e.target.value, reportIndex, "comments");
                      }}
                      placeholder="Enter comments"
                    />
                  </div>
                </div>
              </div>
            );
          }
        }
      })}
    </div>
  );
};

export default AddFoodSecurityForm;
