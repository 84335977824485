import React, { useEffect, useState } from "react";
import { Box, Pagination } from "@mui/material";
import moment from "moment";
import { fetchMeatInspection } from "../../../services/meatInspection";
import { useSelector } from "react-redux";
import Shimmer from "../../shimmer";
import Notfound from "../../data-not-found";
import Papa from "papaparse";

const MeatInspectionTable = () => {
  const zoneRefresh = useSelector((state) => state.updateZone.value);
  const [loading, setLoading] = useState(true);
  const [livestockList, setLivestockList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const getLivestock = async (page) => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const res = await fetchMeatInspection(
      page,
      pageSize,
      regionId,
      startDate,
      endDate
    );
    if (res.status) {
      setLoading(false);
      setLivestockList([
        {
          header: res.header,
          row: res.row,
        },
      ]);
      setTotalData(res.count);
    }
  };

  useEffect(() => {
    getLivestock(page);
  }, [page, pageSize, zoneRefresh]);

  const handleChangePagination = async (event, value) => {
    setPage(value);
  };
  return (
    <>
      {loading ? (
        <Shimmer />
      ) : (
        <div className="homepgtable">
          <table className="table-bordered home-table">
            <thead>
              <tr>
                {livestockList &&
                  livestockList[0]?.header?.map((value) => {
                    return (
                      <th
                        rowSpan="2"
                        className="thd emplName alignmd">
                        {" "}
                        <strong>{value}</strong>
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {livestockList && livestockList[0]?.row?.length > 0 ? (
                <>
                  {livestockList &&
                    livestockList[0]?.row?.map((value) => {
                      return (
                        <tr>
                          <td className="employeeName">{value.userName}</td>
                          <td className="dates">
                            {moment(value.Date).format("DD-MMM-yyyy")}
                          </td>
                          <td className="animaltype">{value.animalName}</td>
                          <td className="alignmd">
                            {value.slaughterPlace === "" ||
                            value.slaughterPlace === " "
                              ? "-"
                              : value.slaughterPlace}
                          </td>
                          <td className="alignmd">{value.animalAge}</td>
                          <td className="alignmd">{value.animalsAffected}</td>
                          <td className="alignmd">
                            {value.anteMortemFindings === "" ||
                            value.anteMortemFindings === " "
                              ? "-"
                              : value.anteMortemFindings}
                          </td>
                          <td className="alignmd">
                            {value.postMortemFindings === "" ||
                            value.postMortemFindings === " "
                              ? "-"
                              : value.postMortemFindings}
                          </td>
                          <td className="alignmd">
                            {value.gender === 1 ? "Male" : "Female"}
                          </td>
                          <td>
                            <div className="incomeNotes">
                              {value.comments === "" || value.comments === " "
                                ? "-"
                                : value.comments}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </>
              ) : (
                <div className="tablenotfound">
                  <Notfound />
                </div>
              )}
            </tbody>
          </table>
        </div>
      )}
      {totalData > 10 ? (
        <div className="paginantionBox">
          <div>
            <Pagination
              page={page}
              count={Math.ceil(Number(totalData / pageSize))}
              variant="outlined"
              color="primary"
              onChange={handleChangePagination}
              showFirstButton
              showLastButton
            />
          </div>
          <div className="perPageBox">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                setPage(1);
              }}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div>
        </div>
      ) : (
        " "
      )}{" "}
    </>
  );
};

export default MeatInspectionTable;
