import { requestWithToken } from "../helpers/helpers";

const createExpanse = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      "/cashbook/expense",
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchUnitType = async () => {
  try {
    const response = await requestWithToken("GET", "/admin/unitTypes");

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchExpenseReport = async (pageNo, pageSize, payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/cashbook/expenseReport?pageNo=${pageNo}&pageSize=${pageSize}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export { createExpanse, fetchUnitType, fetchExpenseReport };
