import { useState, React, useEffect } from "react";
import cardBoxImage from "../../assets/images/cardboxbg.png";
import Back from "../../assets/images/backbtn.svg";
import { useNavigate } from "react-router-dom";
import {
  createPlantHealthControl,
  fetchCropSubCategories,
} from "../../services/plantHealthControl";
import { useFormik } from "formik";
import showNotification from "./../../components/notification/notification";
import AddPlantHealthControlForm from "../../components/plantHealthControl/plantHealthControlForm";
import { Button, Modal } from "react-bootstrap";

const AddCropDisease = () => {
  const navigate = useNavigate();
  const [selectedMenuButtons, setSelectedMenuButtons] = useState([]);
  const [menubuttons, setMenubuttons] = useState([]);
  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(1);

  const getCropSubCategories = async () => {
    const res = await fetchCropSubCategories();
    if (res.status) {
      setMenubuttons(res.data);
    }
  };
  // console.log(`"selected menu buttons length ="${selectedMenuButtons.length}`);
  // console.log(`"selected menu buttons"${selectedMenuButtons}`);

  useEffect(() => {
    getCropSubCategories();
  }, []);

  const addProductionFormik = useFormik({
    initialValues: {
      cropDiseaseDetails: [],
    },
    onSubmit: async (values) => {
      if (values.cropDiseaseDetails.length) {
        const res = await createPlantHealthControl(values);
        if (res.status) {
          // alert("Log created successfully");
          navigate("/");
          showNotification(
            " Plant Health data added successfully!",
            " success"
          );
        }
      } else {
        showNotification("Please select crop", "danger");
      }
    },
  });

  const reset = () => {
    let tempArray = [...addProductionFormik.values.cropDiseaseDetails];
    // tempArray=[];
    setSelectedMenuButtons([]);
    addProductionFormik.setFieldValue("cropDiseaseDetails", []);
  };

  const onAnimalSelect = (button) => {
    const index = selectedMenuButtons.findIndex((x) => x._id === button._id);
    if (index >= 0) {
      const menuButtons = selectedMenuButtons.filter(
        (b) => b._id !== button._id
      );
      setSelectedMenuButtons(menuButtons);
    } else {
      setSelectedMenuButtons([...selectedMenuButtons, button]);
    }
    // if (index === 0) {
    //   const menuButtons = selectedMenuButtons.filter(
    //     (b) => b._id === button._id
    //   );
    //   setSelectedMenuButtons(menuButtons);
    // }
    const regionId = JSON.parse(localStorage.getItem("data")).regionDetails[0]
      ._id;

    let checkArrayExist = addProductionFormik.values.cropDiseaseDetails.some(
      (details) => details.button === button._id
    );
    if (checkArrayExist) {
      addProductionFormik.setFieldValue(
        "cropDiseaseDetails",
        addProductionFormik.values.cropDiseaseDetails.filter((value) => {
          return value.button !== button._id;
        })
      );
    } else {
      button.categories.map((value) => {
        addProductionFormik.values.cropDiseaseDetails.push({
          regionId: regionId,
          button: button._id,
          cropId: value._id,
          animalTypeName: value.name,
          diseaseName: "",
          villagesAffected: "",
          severity: "",
          agroRecommended: "",
          comment: "",
        });
      });
    }
  };

  const changeFormValue = (index, value, changeKey) => {
    let tempArray = [...addProductionFormik.values.cropDiseaseDetails];
    tempArray[index][`${changeKey}`] = value;
    addProductionFormik.setFieldValue("cropDiseaseDetails", tempArray);
  };

  const deleteEggLog = (cropId) => {
    let tempArray = [...addProductionFormik.values.cropDiseaseDetails];
    let tempArray2 = [];
    tempArray2 = tempArray.filter((value) => {
      return value.cropId !== cropId;
    });
    tempArray = tempArray2;
    addProductionFormik.setFieldValue("cropDiseaseDetails", tempArray);
    tempArray = tempArray2;
    let dataSelect = "";

    selectedMenuButtons.map((value) => {
      let tempArr = tempArray.filter((data) => {
        return data.button == value._id;
      });
      if (tempArr.length == 0) {
        dataSelect = value._id;
      }
    });

    if (dataSelect.length) {
      setSelectedMenuButtons(
        selectedMenuButtons.filter((item) => {
          return item._id !== dataSelect;
        })
      );
    }
    // if (tempArray.button !== "649293926a6940168a95eb70") {
    //   setSelectedMenuButtons(
    //     selectedMenuButtons.filter((value) => {
    //       return value.cropId !== value.cropId;
    //     })
    //   );
    // }
  };

  const addUserReset = () => {
    reset();
    setShow(false);
    setOpacity(0);
  };
  const handleClose = () => {
    setShow(false);
    setOpacity(0);
  };
  const handleShow = () => {
    setShow(true);
    setOpacity(1);
  };
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader">
          <h3>Add Plant Health & Chemical Control</h3>
          <button onClick={goBack}>
            <img src={Back} alt="" /> Back
          </button>
        </div>
        <div className="cardbg">
          <img src={cardBoxImage} alt="card bg" />
        </div>
      </div>{" "}
      <form onSubmit={addProductionFormik.handleSubmit}>
        <div className="politryStockForm">
          <div className="politryStock">
            <div className="farmersBox">
              <div className="buttons">
                {menubuttons &&
                  menubuttons[0]?.Crops?.map((button, index) => (
                    <button
                      onClick={() => onAnimalSelect(button)}
                      className={`${
                        selectedMenuButtons?.findIndex(
                          (x) => x._id === button._id
                        ) >= 0
                          ? "active"
                          : ""
                      }`}
                      key={`clBtn-${index}`}
                      type="button"
                    >
                      {button.name}
                    </button>
                  ))}
              </div>
            </div>

            {addProductionFormik.values.cropDiseaseDetails.map(
              (crops, mainIndex) => {
                return (
                  <div className="AddprodRow2">
                    <h5 className="addboxtitle">{crops.cropName}</h5>
                    <div className="inputBoxes">
                      <div className="row">
                        {
                          <AddPlantHealthControlForm
                            index={mainIndex}
                            formData={crops}
                            changeFormValue={changeFormValue}
                            deleteEggLog={deleteEggLog}
                          />
                        }
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
          {addProductionFormik.values.cropDiseaseDetails.length > 0 ? (
            <div className="bottomButtons">
              <button type="submit" className="addprdt active" id="addprdt">
                Add production
              </button>
              <button
                type="reset"
                className="reset"
                id="rst"
                onClick={handleShow}
              >
                {" "}
                Reset
              </button>
            </div>
          ) : (
            <div className="select-animal-type">
              <p>Please Select Crop </p>
            </div>
          )}
        </div>
      </form>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ opacity: opacity }}
      >
        <Modal.Header closeButton>
          <Modal.Title> Reset?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={addUserReset}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddCropDisease;
