import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { revenueChart } from "../../services/Chart";
import Shimmer from "../shimmer";
import Notfound from "../data-not-found";
import { useSelector } from "react-redux";
import moment from "moment";
import Download from "../../assets/images/mngmntEdit.png";
import html2canvas from "html2canvas";

const RevenueChart = ({ chartRef }) => {
  const zoneRefresh = useSelector((state) => state.updateZone.value);
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [series, setSeries] = useState([]);
  const [loader, setLoader] = useState(true);

  const mapSeriesData = async () => {
    setLoader(true);
    let regionId = localStorage.getItem("selectedZone");
    let seriesTemp = [];
    let start =
      startDate ?? moment(new Date()).subtract(7, "days").format("YYYY-MM-DD");
    let end = endDate ?? moment(new Date()).format("YYYY-MM-DD");
    const response = await revenueChart(start, end, regionId);
    setLoader(false);
    if (response.status) {
      setData(response.data);
      if (response?.data?.income?.IncomeAvg) {
        seriesTemp.push(response?.data?.income?.IncomeAvg);
      }
      if (response?.data?.expense?.ExpenseAvg) {
        seriesTemp.push(response?.data?.expense?.ExpenseAvg);
      }
      setSeries(seriesTemp);
    }
  };

  useEffect(() => {
    mapSeriesData();
    // if (localStorage.getItem("startDate") && localStorage.getItem("endDate")) {
    //   setStartDate(localStorage.getItem("startDate"));
    //   setEndDate(localStorage.getItem("endDate"));
    //   setSelectedDate(10);
    // }
  }, [zoneRefresh]);

  useEffect(() => {
    mapSeriesData();
  }, [startDate, endDate]);

  const chartOptions = {
    series: series,
    options: {
      colors: ["#03BF9E", "#F17456"],
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
          },
          export: {
            csv: {
              filename: "RevenueChart.csv",
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: "RevenueChart.svg",
            },
            png: {
              filename: "RevenueChart.png",
            },
          },
        },
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
            },
            total: {
              show: true,
              label: "Income / Expense",
              formatter: function (w) {
                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                return `${data.income.totalIncome} / ${data.expense?.totalExpense}`;
              },
            },
          },
        },
      },
      labels: ["Income", "Expense"],
    },
  };

  return (
    <>
      {loader ? (
        <Shimmer />
      ) : (
        <div id="chart">
          {data?.income?.IncomeAvg || data?.expense?.ExpenseAvg ? (
            <div>
              <p className="revanuedateRangetop">
                {startDate
                  ? moment(startDate).format("DD MMM YYYY")
                  : moment(new Date())
                      .subtract(7, "days")
                      .format("DD MMM YYYY")}{" "}
                -{" "}
                {endDate
                  ? moment(endDate).format("DD MMM YYYY")
                  : moment(new Date()).format("DD MMM YYYY")}
              </p>
              <div>
                <ReactApexChart
                  options={chartOptions.options}
                  series={chartOptions.series}
                  type="radialBar"
                />
              </div>
              <div className="date-select-text">
                <p
                  className={`${selectedDate === 0 ? "active" : ""}`}
                  onClick={() => {
                    setSelectedDate(0);
                    setStartDate(
                      moment(new Date())
                        .subtract(30, "days")
                        .format("YYYY-MM-DD")
                    );
                    setEndDate(moment(new Date()).format("YYYY-MM-DD"));
                  }}
                >
                  1M
                </p>
                <p
                  className={`${selectedDate === 1 ? "active" : ""}`}
                  onClick={() => {
                    setSelectedDate(1);
                    setStartDate(
                      moment(new Date())
                        .subtract(90, "days")
                        .format("YYYY-MM-DD")
                    );
                    setEndDate(moment(new Date()).format("YYYY-MM-DD"));
                  }}
                >
                  3M
                </p>
                <p
                  className={`${selectedDate === 2 ? "active" : ""}`}
                  onClick={() => {
                    setSelectedDate(2);
                    setStartDate(
                      moment(new Date())
                        .subtract(180, "days")
                        .format("YYYY-MM-DD")
                    );
                    setEndDate(moment(new Date()).format("YYYY-MM-DD"));
                  }}
                >
                  6M
                </p>
                <p
                  className={`${selectedDate === 3 ? "active" : ""}`}
                  onClick={() => {
                    setSelectedDate(3);
                    setStartDate(
                      moment(new Date())
                        .subtract(1, "year")
                        .format("YYYY-MM-DD")
                    );
                    setEndDate(moment(new Date()).format("YYYY-MM-DD"));
                  }}
                >
                  1YR
                </p>
                <p
                  className={`${selectedDate === 4 ? "active" : ""}`}
                  onClick={() => {
                    setSelectedDate(4);
                    setStartDate(
                      moment(new Date())
                        .subtract(3, "year")
                        .format("YYYY-MM-DD")
                    );
                    setEndDate(moment(new Date()).format("YYYY-MM-DD"));
                  }}
                >
                  3YR
                </p>
                {/* <p>Inception</p> */}
              </div>
            </div>
          ) : (
            <div className="revanue">
              <Notfound />
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default RevenueChart;
