import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false,
};

export const updateZoneSlice = createSlice({
  name: "refreshZone",
  initialState,
  reducers: {
    updateZone: (state) => {
      state.value = !state.value;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateZone } = updateZoneSlice.actions;

export default updateZoneSlice.reducer;
