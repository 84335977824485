import { React } from "react";
import { handleKeyDown } from "../../helpers/helpers";

const AddMeatInspectionForm = ({ index, formData, changeFormValue }) => {
  const onInputChange = (value, key) => {
    changeFormValue(index, value, key);
  };

  return (
    <div>
      <div className="prodctRow">
        <label className="prodctRowCol1">{`${formData.animalTypeName} Details`}</label>
        <div className="prodctRowinputBoxes">
          <div className="row">
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Slaughter Place</label>
                <input
                  required
                  type="text"
                  value={formData?.slaughterPlace}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "slaughterPlace");
                  }}
                  placeholder="Enter Slaughter Place"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Animal Age</label>
                <input
                  required
                  type="number"
                  value={formData?.animalAge}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "animalAge");
                  }}
                  placeholder="Enter Animal Age"
                  onKeyDown={handleKeyDown}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Animal Affected</label>
                <input
                  required
                  type="number"
                  value={formData?.animalAffected}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "animalAffected");
                  }}
                  placeholder="Enter Animal Affected"
                  onKeyDown={handleKeyDown}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Ante Mortem Findings</label>
                <input
                  required
                  type="text"
                  value={formData?.anteMortemFindings}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "anteMortemFindings");
                  }}
                  placeholder="Enter Ante Mortem Findings"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Post Mortem Findings</label>
                <input
                  required
                  type="text"
                  value={formData?.postMortemFindings}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "postMortemFindings");
                  }}
                  placeholder="Enter Post Mortem Findings"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Comments</label>
                <input
                  required
                  type="text"
                  value={formData?.comments}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "comments");
                  }}
                  placeholder="Enter Comments"
                />
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="adduserbtn buttons">
                {/* <button
                  type="button"
                  className={formData?.sex == 1 ? "active" : ""}
                  onClick={() => {
                    onInputChange(1, "sex");
                  }}
                >
                  Male
                </button>
                <button
                  type="button"
                  className={formData?.sex == 2 ? "active" : ""}
                  onClick={() => {
                    onInputChange(2, "sex");
                  }}
                >
                  Female
                </button> */}
                <div className="meatinspRadiobtn">
                  <input
                    className="meatinspRadio"
                    type="radio"
                    id="male"
                    name={formData.animalTypeName}
                    checked={formData?.sex === 1}
                    onClick={() => {
                      onInputChange(1, "sex");
                    }}
                    required
                  />{" "}
                  <label htmlFor="male">Male</label>
                </div>
                <div className="meatinspRadiobtn">
                  <input
                    className="meatinspRadio"
                    type="radio"
                    id="female"
                    name={formData.animalTypeName}
                    checked={formData?.sex === 2}
                    onClick={() => {
                      onInputChange(2, "sex");
                    }}
                    // required
                  />{" "}
                  <label htmlFor="female">Female</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMeatInspectionForm;
