import React, { useEffect, useState } from "react";
import cardBoxImage from "../../assets/images/cardboxbg.png";
import Back from "../../assets/images/backbtn.svg";
import { useFormik } from "formik";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { createExpanse, fetchUnitType } from "../../services/expanse";
import showNotification from "../../components/notification/notification";
import { handleKeyDown } from "../../helpers/helpers";
import { Button, Modal } from "react-bootstrap";

const AddExpense = () => {
  const navigate = useNavigate();
  const [unitList, setUnitList] = useState("");
  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const addExpanseFormik = useFormik({
    initialValues: {
      date: "",
      amount: "",
      amountPurchased: "",
      regionId: JSON.parse(localStorage.getItem("data"))?.regionDetails[0]?._id,
      unit: " ",
      unitType: "",
      transactionType: "",
      notes: "",
    },
    onSubmit: async (values) => {
      const res = await createExpanse(values);
      if (res.status) {
        // alert("Expanse created successfully");
        navigate("/");
        showNotification("Expense data added successfully!", " success");
      } else {
        showNotification(`${res.message}`, "danger");
      }
    },
  });

  const goBack = () => {
    navigate(-1);
  };

  const getUnitType = async () => {
    const res = await fetchUnitType();
    if (res.status) {
      setUnitList(res.data);
    }
  };

  const reset = () => {
    console.log("called");
    addExpanseFormik.setFieldValue("amount", "");
    addExpanseFormik.setFieldValue("amountPurchased", "");
    addExpanseFormik.setFieldValue("unit", "");
    addExpanseFormik.setFieldValue("unitType", "");
    addExpanseFormik.setFieldValue("transactionType", "");
    addExpanseFormik.setFieldValue("notes", "");
  };

  useEffect(() => {
    getUnitType();
  }, []);
  const addUserReset = () => {
    reset();
    setShow(false);
    setOpacity(0);
  };
  const handleClose = () => {
    setShow(false);
    setOpacity(0);
  };
  const handleShow = () => {
    setShow(true);
    setOpacity(1);
  };

  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader">
          <h3>Add Expense</h3>
          <button onClick={goBack}>
            <img src={Back} alt="" /> BACK
          </button>
        </div>
        <div className="cardbg">
          <img src={cardBoxImage} alt="card bg" />
        </div>
      </div>{" "}
      <form onSubmit={addExpanseFormik.handleSubmit}>
        <div className="politryStockForm">
          <div className="politryStock addForm">
            <div className="addFormPage">
              <div className="row1">
                <div className="row">
                  <div className="col-md-3">
                    <div className=" addFormRow">
                      <label>Expense Date</label>
                      <input
                        required
                        name="date"
                        type="date"
                        placeholder="select date"
                        value={addExpanseFormik.values.date}
                        max={moment(new Date()).format("YYYY-MM-DD")}
                        onChange={addExpanseFormik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="addFormRow">
                      <label> Expense Cost</label>
                      <input
                        name="amount"
                        type="number"
                        placeholder="Enter Amount"
                        onKeyDown={handleKeyDown}
                        value={addExpanseFormik.values.amount}
                        onChange={addExpanseFormik.handleChange}
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="addFormRow">
                      <label>Amount Purchased</label>
                      <input
                        required
                        name="amountPurchased"
                        type="number"
                        placeholder="Enter Amount"
                        value={addExpanseFormik.values.amountPurchased}
                        onChange={addExpanseFormik.handleChange}
                        onKeyDown={handleKeyDown}
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="addFormRow">
                      <label>Units</label>
                      <input
                        required
                        name="unit"
                        type="number"
                        placeholder="Enter Units"
                        onKeyDown={handleKeyDown}
                        value={addExpanseFormik.values.unit}
                        onChange={addExpanseFormik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row2">
                <div className="row">
                  <div className="col-md-3">
                    <div className="addFormRow">
                      <label>Type</label>
                      <select
                        value={addExpanseFormik.values.transactionType}
                        onChange={(e) => {
                          addExpanseFormik.setFieldValue(
                            "transactionType",
                            Number(e.target.value)
                          );
                        }}
                      >
                        <option value="">Select Type</option>
                        <option value={1}>Cash</option>
                        <option value={2}>MMG+</option>
                        <option value={3}>Net Banking</option>
                        <option value={4}>Cheque</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="addFormRow">
                      <label> Unit Type</label>
                      <select
                        name="unitType"
                        value={addExpanseFormik.values.unitType}
                        onChange={addExpanseFormik.handleChange}
                      >
                        <option value="">Select Unit Type</option>
                        {unitList &&
                          unitList?.map((value) => {
                            return (
                              <option value={value._id}>{value.name}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row3">
                <div className="row">
                  <div className="col-md-6">
                    <div className="addFormRow">
                      <label>Notes</label>
                      <input
                        name="notes"
                        type="text"
                        placeholder="Enter Notes"
                        value={addExpanseFormik.values.notes}
                        onChange={addExpanseFormik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottomButtons">
            <button type="submit" className="addprdt active" id="addprdt">
              Add Expense
            </button>
            <button type="button" className="reset" onClick={handleShow}>
              {" "}
              Reset
            </button>
          </div>
        </div>
      </form>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ opacity: opacity }}
      >
        <Modal.Header closeButton>
          <Modal.Title> Reset?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={addUserReset}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddExpense;
