import { React } from "react";
import { handleKeyDown } from "../../helpers/helpers";

const AddLivestockServiceForm = ({ index, formData, changeFormValue }) => {
  const onInputChange = (value, key) => {
    changeFormValue(index, value, key);
  };

  return (
    <div>
      <div className="prodctRow">
        <label className="prodctRowCol1">{`${formData.animalTypeName} Details`}</label>
        <div className="prodctRowinputBoxes">
          <div className="row">
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">AI</label>
                <input
                  required
                  type="number"
                  value={formData?.ai}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "ai");
                  }}
                  placeholder="Enter AI Count"
                  onKeyDown={handleKeyDown}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Trimming Hooves</label>
                <input
                  required
                  type="number"
                  value={formData?.trimmingHooves}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "trimmingHooves");
                  }}
                  placeholder="Enter Trimming Hooves Count"
                  onKeyDown={handleKeyDown}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Dehorn</label>
                <input
                  required
                  type="number"
                  value={formData?.dehorns}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "dehorns");
                  }}
                  placeholder="Enter Dehorn Count"
                  onKeyDown={handleKeyDown}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Docking Tails</label>
                <input
                  required
                  type="number"
                  value={formData?.dockingTails}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "dockingTails");
                  }}
                  placeholder="Enter Docking Tails Count"
                  onKeyDown={handleKeyDown}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Clipping Teeth</label>
                <input
                  required
                  type="number"
                  value={formData?.clippingTeeth}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "clippingTeeth");
                  }}
                  placeholder="Enter Clipping Teeth Count"
                  onKeyDown={handleKeyDown}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Debeaking</label>
                <input
                  required
                  type="number"
                  value={formData?.debeaking}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "debeaking");
                  }}
                  placeholder="Enter Debeaking Count"
                  onKeyDown={handleKeyDown}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Castration</label>
                <input
                  required
                  type="number"
                  value={formData?.castrations}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "castrations");
                  }}
                  placeholder="Enter Castration Count"
                  onKeyDown={handleKeyDown}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLivestockServiceForm;
