import { requestWithToken } from "../helpers/helpers";

const fetchRoles = async (payload) => {
  try {
    const response = await requestWithToken("GET", "/admin/roles", payload);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchRegions = async (pageNo) => {
  try {
    const response = await requestWithToken("GET", `/admin/regions`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createUser = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/admin/officer`, payload);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const editUser = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/admin/officer/${id}`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchUserDetails = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/officerDetails/${id}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchUserList = async (payload, startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "POST",
      startDate
        ? `/admin/officerList?startDate=${startDate}&endDate=${endDate}`
        : `/admin/officerList`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response;
  }
};

const updateUserStatus = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/user/status/${id}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const forgetPassword = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/user/forgotPassword`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const resetPassword = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/user/resetPassword`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const downloadCsv = async (
  type,
  downloadType,
  regionId,
  startDate,
  endDate
) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/user/downloadCsv?type=${type}&downloadType=${downloadType}&regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/user/downloadCsv?type=${type}&downloadType=${downloadType}&regionId=${regionId}`,
      {},
      "blob"
    );

    return response.data;
  } catch (err) {
    return err.message;
  }
};

const downloadIncomeExpenseReport = async (
  type,
  downloadType,
  transactionType,
  regionId,
  startDate,
  endDate
) => {
  try {
    const response = await requestWithToken(
      "GET",
      transactionType && startDate
        ? `/user/downloadCsv?type=${type}&downloadType=${downloadType}&transactionType=${transactionType}&regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : transactionType
        ? `/user/downloadCsv?type=${type}&downloadType=${downloadType}&transactionType=${transactionType}&regionId=${regionId}`
        : startDate
        ? `/user/downloadCsv?type=${type}&downloadType=${downloadType}&regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/user/downloadCsv?type=${type}&downloadType=${downloadType}&regionId=${regionId}`,
      {},
      "blob"
    );

    return response.data;
  } catch (err) {
    return err.message;
  }
};

export {
  fetchRoles,
  fetchRegions,
  createUser,
  fetchUserList,
  fetchUserDetails,
  editUser,
  updateUserStatus,
  forgetPassword,
  resetPassword,
  downloadCsv,
  downloadIncomeExpenseReport,
};
