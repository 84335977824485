import { useEffect, useRef, useState } from "react";
import anim from "../assets/images/animalPawn.svg";
import crops from "../assets/images/crops.svg";
import upArrow from "../assets/images/upArrow.svg";
import downArrow from "../assets/images/downArrow.svg";
import reports from "../assets/images/reports.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { USER_ROLE } from "../helpers/userRoleEnum";
import { Collapse } from "react-bootstrap";

const SideDrawer = ({ showSideMenu, hideSideMenu }) => {
  const location = useLocation();

  const ref = useRef();

  const [activeTab, setActiveTab] = useState(0);
  const [openDranage, setOpenDranage] = useState(false);
  const [openFarmToMarket, setOpenFarmToMarket] = useState(false);
  const [openFoodSecurity, setOpenFoodSecurity] = useState(false);
  const [openAgricultureSupport, setAgricultureSupport] = useState(false);
  const [openProjectMonitoring, setOpenProjectMonitoring] = useState(false);
  const [openMarketing, setOpenMarketing] = useState(false);
  const [openAgricultureClimate, setOpenAgricultureClimate] = useState(false);
  const [openSchoolAgriculture, setOpenSchoolAgriculture] = useState(false);

  const activeSelectedLocation = () => {
    switch (location.pathname) {
      case "/drainage-table":
        setOpenDranage(true);
        setActiveTab(1);
        break;
      case "/drainage":
        setOpenDranage(true);
        setActiveTab(1);
        break;
      case "/marketAccess-table":
        setOpenFarmToMarket(true);
        setActiveTab(2);
        break;
      case "/add-FarmMarketAccess":
        setOpenFarmToMarket(true);
        setActiveTab(2);
        break;
      case "/foodSecurity":
        setOpenFoodSecurity(true);
        setActiveTab(3);
        break;
      case "/add-foodSecurity":
        setOpenFoodSecurity(true);
        setActiveTab(3);
        break;
      case "/add-agroSupportService":
        setAgricultureSupport(true);
        setActiveTab(4);
        break;
      case "/agroSupportService-table":
        setAgricultureSupport(true);
        setActiveTab(4);
        break;
      case "/add-projectMonitoring":
        setOpenProjectMonitoring(true);
        setActiveTab(5);
        break;
      case "/projectMonitoring-table":
        setOpenProjectMonitoring(true);
        setActiveTab(5);
        break;
      case "/marketing-table":
        setOpenMarketing(true);
        setActiveTab(6);
        break;
      case "/add-marketing":
        setOpenMarketing(true);
        setActiveTab(6);
        break;
      case "/add-agroClimateResilience":
        setOpenAgricultureClimate(true);
        setActiveTab(7);
        break;
      case "/climateResilience-table":
        setOpenAgricultureClimate(true);
        setActiveTab(7);
        break;
      case "/add-schoolProgram":
        setOpenSchoolAgriculture(true);
        setActiveTab(8);
        break;
      case "/schoolAgroSupportProgram-table":
        setOpenSchoolAgriculture(true);
        setActiveTab(8);
        break;
      case "/production-table":
        setActiveTab(9);
        break;
      default:
        setActiveTab(0);
        break;
    }
  };

  useEffect(() => {
    setOpenFarmToMarket(false);
    setOpenDranage(false);
    setOpenFoodSecurity(false);
    setAgricultureSupport(false);
    setOpenProjectMonitoring(false);
    setOpenMarketing(false);
    setOpenAgricultureClimate(false);
    setOpenSchoolAgriculture(false);

    activeSelectedLocation();
  }, [location.pathname, showSideMenu]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showSideMenu && ref.current && !ref.current.contains(e.target)) {
        hideSideMenu();
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showSideMenu]);

  const role = JSON.parse(localStorage.getItem("data"))?.roles?.some(
    (value) => {
      return value.shortCode === USER_ROLE.FIELDOFFICER;
    }
  );

  return (
    <div
      className={`w3-sidebar  w3-bar-block w3-border-right ${
        showSideMenu ? "show" : "hide"
      }`}
      ref={ref}>
      <button
        onClick={() => {
          hideSideMenu();
        }}
        className="w3-bar-item w3-large sidedrawerclosebtn">
        <img
          src="./cut.svg"
          alt=""
        />
      </button>
      <div className="sidemenuHead">
        <img
          className="sidemenuheadImage"
          src={crops}
          alt=""
        />
        <p className="croptext">CROPS</p>
      </div>
      <ul className="sideMenu">
        <li className={activeTab === 1 ? "active link" : "link"}>
          <div
            className="sideLink"
            onClick={() => {
              setOpenDranage(!openDranage);
            }}>
            <label className="sidemenu-label">Drainage and Irrigation </label>{" "}
            <img
              className="fltright"
              src={openDranage ? upArrow : downArrow}
              alt=""
            />
          </div>
          <Collapse in={openDranage}>
            <div className="sideLink sublink">
              {role === true ? (
                <Link
                  to="/drainage"
                  className={location.pathname === "/drainage" ? "active" : " "}
                  onClick={() => {
                    hideSideMenu();
                  }}>
                  Add Drainage
                </Link>
              ) : (
                " "
              )}
              <Link
                to="/drainage-table"
                className={
                  location.pathname === "/drainage-table" ? "active" : " "
                }
                onClick={() => {
                  hideSideMenu();
                }}>
                {" "}
                Drainage List
              </Link>
            </div>
          </Collapse>
        </li>

        <li className={activeTab === 2 ? "active link" : "link"}>
          <div
            className="sideLink"
            onClick={() => {
              setOpenFarmToMarket(!openFarmToMarket);
            }}>
            <label className="sidemenu-label">Farm To Market Access </label>
            <img
              className="fltright"
              src={openFarmToMarket ? upArrow : downArrow}
              alt=""
            />
          </div>
          <Collapse in={openFarmToMarket}>
            <div className="sideLink sublink">
              {role === true ? (
                <Link
                  to="/add-FarmMarketAccess"
                  className={
                    location.pathname === "/add-FarmMarketAccess"
                      ? "active"
                      : " "
                  }
                  onClick={() => {
                    hideSideMenu();
                  }}>
                  Add Farm To Market Access
                </Link>
              ) : (
                " "
              )}
              <Link
                to="/marketAccess-table"
                className={
                  location.pathname === "/marketAccess-table" ? "active" : " "
                }
                onClick={() => {
                  hideSideMenu();
                }}>
                {" "}
                Market Access List
              </Link>
            </div>
          </Collapse>
        </li>

        <li className={activeTab === 3 ? "active link" : "link"}>
          <div
            className="sideLink"
            onClick={() => {
              setOpenFoodSecurity(!openFoodSecurity);
            }}>
            <label className="sidemenu-label">Food Security Programs</label>
            <img
              className="fltright"
              src={openFoodSecurity ? upArrow : downArrow}
              alt=""
            />
          </div>
          <Collapse in={openFoodSecurity}>
            <div className="sideLink sublink">
              {role === true ? (
                <Link
                  to="/add-foodSecurity"
                  className={
                    location.pathname === "/add-foodSecurity" ? "active" : " "
                  }
                  onClick={() => {
                    hideSideMenu();
                  }}>
                  Add Food Security Programs
                </Link>
              ) : (
                " "
              )}
              <Link
                to="/foodSecurity"
                className={
                  location.pathname === "/foodSecurity" ? "active" : " "
                }
                onClick={() => {
                  hideSideMenu();
                }}>
                {" "}
                Food Security Programs List
              </Link>
            </div>
          </Collapse>
        </li>

        <li className={activeTab === 4 ? "active link" : "link"}>
          <div
            className="sideLink"
            onClick={() => {
              setAgricultureSupport(!openAgricultureSupport);
            }}>
            <label className="sidemenu-label">
              Agriculture Support Service
            </label>
            <img
              className="fltright"
              src={openAgricultureSupport ? upArrow : downArrow}
              alt=""
            />
          </div>
          <Collapse in={openAgricultureSupport}>
            <div className="sideLink sublink">
              {role === true ? (
                <Link
                  to="/add-agroSupportService"
                  className={
                    location.pathname === "/add-agroSupportService"
                      ? "active"
                      : " "
                  }
                  onClick={() => {
                    hideSideMenu();
                  }}>
                  Add Agriculture Support Service
                </Link>
              ) : (
                " "
              )}
              <Link
                to="/agroSupportService-table"
                className={
                  location.pathname === "/agroSupportService-table"
                    ? "active"
                    : " "
                }
                onClick={() => {
                  hideSideMenu();
                }}>
                {" "}
                Agriculture Support Service List
              </Link>
            </div>
          </Collapse>
        </li>

        <li className={activeTab === 5 ? "active link" : "link"}>
          <div
            className="sideLink"
            onClick={() => {
              setOpenProjectMonitoring(!openProjectMonitoring);
            }}>
            <label className="sidemenu-label">Project Monitoring</label>
            <img
              className="fltright"
              src={openProjectMonitoring ? upArrow : downArrow}
              alt=""
            />
          </div>
          <Collapse in={openProjectMonitoring}>
            <div className="sideLink sublink">
              {role === true ? (
                <Link
                  to="/add-projectMonitoring"
                  className={
                    location.pathname === "/add-projectMonitoring"
                      ? "active"
                      : " "
                  }
                  onClick={() => {
                    hideSideMenu();
                  }}>
                  Add Project Monitoring
                </Link>
              ) : (
                " "
              )}
              <Link
                to="/projectMonitoring-table"
                className={
                  location.pathname === "/projectMonitoring-table"
                    ? "active"
                    : " "
                }
                onClick={() => {
                  hideSideMenu();
                }}>
                {" "}
                Project Monitoring List
              </Link>
            </div>
          </Collapse>
        </li>

        <li className={activeTab === 6 ? "active link" : "link"}>
          <div
            className="sideLink"
            onClick={() => {
              setOpenMarketing(!openMarketing);
            }}>
            <label
              className="sidemenu-label"
              onClick={() => {
                // setOpenMarketing(!openMarketing);
              }}>
              Marketing
            </label>
            <img
              className="fltright"
              src={openMarketing ? upArrow : downArrow}
              alt=""
            />
          </div>
          <Collapse in={openMarketing}>
            <div className="sideLink sublink">
              {role === true ? (
                <Link
                  to="/add-marketing"
                  className={
                    location.pathname === "/add-marketing" ? "active" : " "
                  }
                  onClick={() => {
                    hideSideMenu();
                  }}>
                  Add Marketing
                </Link>
              ) : (
                <></>
              )}
              <Link
                to="/marketing-table"
                className={
                  location.pathname === "/marketing-table" ? "active" : " "
                }
                onClick={() => {
                  hideSideMenu();
                }}>
                {" "}
                Marketing List
              </Link>
            </div>
          </Collapse>
        </li>

        <li className={activeTab === 7 ? "active link" : "link"}>
          <div
            className="sideLink"
            onClick={() => {
              setOpenAgricultureClimate(!openAgricultureClimate);
            }}>
            <label className="sidemenu-label">
              Agriculture Climate Resilience
            </label>
            <img
              className="fltright"
              src={openAgricultureClimate ? upArrow : downArrow}
              alt=""
            />
          </div>
          <Collapse in={openAgricultureClimate}>
            <div className="sideLink sublink">
              {role === true ? (
                <Link
                  to="/add-agroClimateResilience"
                  className={
                    location.pathname === "/add-agroClimateResilience"
                      ? "active"
                      : " "
                  }
                  onClick={() => {
                    hideSideMenu();
                  }}>
                  Add Agriculture Climate Resilience
                </Link>
              ) : (
                " "
              )}
              <Link
                to="/climateResilience-table"
                className={
                  location.pathname === "/climateResilience-table"
                    ? "active"
                    : " "
                }
                onClick={() => {
                  hideSideMenu();
                }}>
                {" "}
                Agriculture Climate Resilience List
              </Link>
            </div>
          </Collapse>
        </li>

        <li className={activeTab === 8 ? "active link" : "link"}>
          <div
            className="sideLink"
            onClick={() => {
              setOpenSchoolAgriculture(!openSchoolAgriculture);
            }}>
            <label className="sidemenu-label">
              Schools' Agriculture Support Programs
            </label>
            <img
              className="fltright"
              src={openSchoolAgriculture ? upArrow : downArrow}
              alt=""
            />
          </div>
          <Collapse in={openSchoolAgriculture}>
            <div className="sideLink sublink">
              {role === true ? (
                <Link
                  to="/add-schoolProgram"
                  className={
                    location.pathname === "/add-schoolProgram" ? "active" : " "
                  }
                  onClick={() => {
                    hideSideMenu();
                  }}>
                  Add Schools' Agriculture Support Program
                </Link>
              ) : (
                ""
              )}
              <Link
                to="/schoolAgroSupportProgram-table"
                className={
                  location.pathname === "/schoolAgroSupportProgram-table"
                    ? "active"
                    : " "
                }
                onClick={() => {
                  hideSideMenu();
                }}>
                {" "}
                Schools' Agriculture Support Program List
              </Link>
            </div>
          </Collapse>
        </li>

        <li className="link singleLink">
          <Link
            to="/production-table"
            className={
              location.pathname === "/production-table" ? "active" : " "
            }
            onClick={() => {
              hideSideMenu();
            }}>
            {" "}
            Production List
          </Link>
        </li>
      </ul>

      <div className="sidemenuHead">
        <img
          className="sidemenuheadImage"
          src={anim}
          alt=""
        />
        <p className="sidemenuAnimaltext">Animals</p>
      </div>

      <ul className="sideMenu">
        <li className="link singleLink">
          <Link
            to="/livestockSlaughterTable-page"
            className={
              location.pathname === "/livestockSlaughterTable-page"
                ? "active"
                : " "
            }
            onClick={() => {
              hideSideMenu();
            }}>
            Livestock Slaughtered
          </Link>
        </li>
        <li className="link singleLink">
          <Link
            to="/meatInspectionTable-page"
            className={
              location.pathname === "/meatInspectionTable-page" ? "active" : " "
            }
            onClick={() => {
              hideSideMenu();
            }}>
            Meat Inspection
          </Link>
        </li>
        <li className="link singleLink">
          <Link
            to="/medicationtable-page"
            className={
              location.pathname === "/medicationtable-page" ? "active" : " "
            }
            onClick={() => {
              hideSideMenu();
            }}>
            Livestock Health
          </Link>
        </li>
        <li className="link singleLink">
          <Link
            to="/dippingTable-page"
            className={
              location.pathname === "/dippingTable-page" ? "active" : " "
            }
            onClick={() => {
              hideSideMenu();
            }}>
            Dipping, Spraying & Vaccination{" "}
          </Link>{" "}
        </li>
        <li className="link singleLink">
          <Link
            to="/livestockServiceTable-page"
            className={
              location.pathname === "/livestockServiceTable-page"
                ? "active"
                : " "
            }
            onClick={() => {
              hideSideMenu();
            }}>
            Livestock Service
          </Link>
        </li>
      </ul>
      <div className="sidemenuHead">
        <img
          className="sidemenuheadImage"
          src={reports}
          alt=""
        />
        <p className="sidemenuReporttext">Reports</p>
      </div>
      <ul className="sideMenu">
        <li className="link singleLink ">
          <Link
            to="/income-report"
            className={location.pathname === "/income-report" ? "active" : " "}
            onClick={() => {
              hideSideMenu();
            }}>
            Report-Income
          </Link>
        </li>
        <li className="link singleLink ">
          <Link
            to="/expense-report"
            className={location.pathname === "/expense-report" ? "active" : " "}
            onClick={() => {
              hideSideMenu();
            }}>
            Report-Expense
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default SideDrawer;
