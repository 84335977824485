import React, { useEffect, useState } from "react";
import { Box, Pagination } from "@mui/material";
import moment from "moment";
import { fetchCropDiseaseList } from "../../services/plantHealthControl";
import { useNavigate } from "react-router-dom";
import cardboxbg from "./../../assets/images/cardboxbg.png";
import Back from "./../../assets/images/backbtn.svg";
import CSV from "./../../assets/images/csv.svg";
import Pdf from "./../../assets/images/pdf.svg";
import Notfound from "../../components/data-not-found";
import { useSelector } from "react-redux";
import Shimmer from "../../components/shimmer";
import { convertToCsv } from "../../helpers/helpers";
import { downloadCsv } from "../../services/user";
var fileDownload = require("js-file-download");

const CropDiseaseTablePage = () => {
  const zoneRefresh = useSelector((state) => state.updateZone.value);
  const [loading, setLoading] = useState(true);
  const [cropDiseaseList, setCropDiseaseList] = useState([]);
  const [page, setPage] = useState(1);
  const [csvData, setCsvData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState(0);

  const getDiseaseList = async (page) => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const res = await fetchCropDiseaseList(
      page,
      pageSize,
      regionId,
      startDate,
      endDate
    );
    if (res.status) {
      setLoading(false);
      setTotalData(res.count);
      setCropDiseaseList([
        {
          header: res.header,
          row: res.row,
        },
      ]);
    }
  };

  useEffect(() => {
    getDiseaseList(page);
  }, [page, pageSize, zoneRefresh]);

  const convertToCsv = async (downloadType) => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    downloadCsv(19, downloadType, regionId, startDate, endDate).then((res) => {
      // console.log(res);
      if (res) {
        if (downloadType == 1) {
          createCsv(res);
        } else {
          fileDownload(res, "file.pdf");
        }
      }
    });
  };
  const createCsv = (res, downloadType) => {
    const blob = new Blob([res], { type: "text/csv;charset=utf-8;" });
    // Create a temporary URL for the Blob
    const csvUrl = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const downloadLink = document.createElement("a");
    downloadLink.href = csvUrl;
    downloadLink.download = "file.csv";
    // Simulate a click on the link to trigger the download
    downloadLink.click();

    // Clean up by revoking the URL
    window.URL.revokeObjectURL(csvUrl);
  };

  const handleChangePagination = async (event, value) => {
    setPage(value);
  };
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div>
      <div className="theme-container">
        <div className="contentCard2 ">
          <div className="contentCardHeader addProdctHeader tablePageHeader">
            <h3>Plant Health & Chemical Control</h3>
            <div className="tablePageButtons">
              <button onClick={goBack}>
                {" "}
                <img src={Back} alt="" /> Back
              </button>
              <div className="downloadBtns">
                <button
                  className={
                    totalData === 0
                      ? "fadeDownload downloadtblbtn"
                      : "downloadtblbtn"
                  }
                  onClick={() => {
                    convertToCsv(1);
                  }}
                  type="button"
                >
                  Download CSV
                  <img className="dwlnldIcon" src={CSV} alt="" />
                </button>
                <button
                  className={
                    totalData === 0
                      ? "fadeDownload downloadtblbtn"
                      : "downloadtblbtn"
                  }
                  onClick={() => {
                    convertToCsv(2);
                  }}
                  type="button"
                >
                  Download PDF
                  <img className="dwlnldIcon" src={Pdf} alt="" />
                </button>
              </div>
            </div>
          </div>
          <div className="cardbg ">
            <img src={cardboxbg} alt="card bg" />
          </div>
        </div>
        <div className="politryStockForm politryStockFormtbl">
          <section className="bottom tablePagesection">
            <div className="themeCard tableThemeCard">
              <div className="CardContainer">
                <div className="tableBlockPage">
                  {loading ? (
                    <Shimmer />
                  ) : (
                    <table className="table-bordered home-table">
                      <thead>
                        <tr>
                          <th className="incomereportTableHead" colSpan="8">
                            Farming Districts
                          </th>
                        </tr>
                        <tr>
                          {cropDiseaseList &&
                            cropDiseaseList[0]?.header?.map((value, index) => {
                              return (
                                <th
                                  rowSpan="2"
                                  className={
                                    index < 4 ? "meatinspheadLeft" : ""
                                  }
                                >
                                  {" "}
                                  <strong>{value}</strong>
                                </th>
                              );
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        {cropDiseaseList &&
                        cropDiseaseList[0]?.row?.length > 0 ? (
                          <>
                            {cropDiseaseList &&
                              cropDiseaseList[0]?.row?.map((value) => {
                                return (
                                  <tr>
                                    <td className="employeeName">
                                      {value.userName}
                                    </td>
                                    <td className="dates">
                                      {moment(value.date).format("DD-MMM-yyyy")}
                                    </td>
                                    <td className="animaltype">
                                      {value.cropName === " " ||
                                      value.cropName === ""
                                        ? "-"
                                        : value.cropName}
                                    </td>
                                    <td className="animaltype">
                                      {value.diseaseName === " " ||
                                      value.diseaseName === ""
                                        ? "-"
                                        : value.diseaseName}
                                    </td>
                                    <td className="alignmd">
                                      {value.villagesAffected}
                                    </td>
                                    <td className="alignmd">
                                      {value.severity === " "
                                        ? "-"
                                        : value.severity}
                                    </td>
                                    <td>
                                      <div className="incomeNotes recommended">
                                        {value.agroRecommended === "" ||
                                        value.agroRecommended === " "
                                          ? "-"
                                          : value.agroRecommended}
                                      </div>
                                    </td>
                                    <td className="incomeNotesbox">
                                      <div className="incomeNotes">
                                        {value.comment === "" ||
                                        value.comment === " "
                                          ? "-"
                                          : value.comment}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </>
                        ) : (
                          <div className="tablenotfound">
                            <Notfound />
                          </div>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>

                {totalData > 10 ? (
                  <div className="paginantionBox">
                    <div>
                      <Pagination
                        page={page}
                        count={Math.ceil(Number(totalData / pageSize))}
                        variant="outlined"
                        color="primary"
                        onChange={handleChangePagination}
                        showFirstButton
                        showLastButton
                      />
                    </div>
                    <div className="perPageBox">
                      <select
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(Number(e.target.value));
                          setPage(1);
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                      </select>
                    </div>
                  </div>
                ) : (
                  " "
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CropDiseaseTablePage;
