import { useState, React, useEffect } from "react";
import cardBoxImage from "../../assets/images/cardboxbg.png";
import Back from "../../assets/images/backbtn.svg";
import { useNavigate } from "react-router-dom";
import { fetchCropSubCategories } from "../../services/plantHealthControl";
import { useFormik } from "formik";
import { crateProduction } from "../../services/production";
import showNotification from "./../../components/notification/notification";
import { handleKeyDown } from "../../helpers/helpers";
import { Button, Modal } from "react-bootstrap";

const AddProduction = () => {
  const navigate = useNavigate();
  const [farmersNo, setFarmersNo] = useState(0);
  const [selectedMenuButtons, setSelectedMenuButtons] = useState([]);
  const [menubuttons, setMenubuttons] = useState([]);
  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(1);

  const getCropSubCategories = async () => {
    const res = await fetchCropSubCategories();
    if (res.status) {
      setMenubuttons(res.data);
    }
  };

  useEffect(() => {
    getCropSubCategories();
  }, []);

  const addProductionFormik = useFormik({
    initialValues: {
      productDetails: [],
    },
    onSubmit: async (values) => {
      const res = await crateProduction(values);
      if (res.status) {
        navigate("/");
        showNotification("Production data added successfully!", "success");
      }
    },
  });

  const reset = () => {
    let tempArray = [...addProductionFormik.values.productDetails];
    // tempArray=[];
    setSelectedMenuButtons([]);
    setFarmersNo();
    tempArray.forEach((element) => {
      element.products.forEach((log) => {
        log.count = null;
        log.productId = "";
      });
    });
    addProductionFormik.setFieldValue("productDetails", []);
  };

  const onAnimalSelect = (button) => {
    const index = selectedMenuButtons.findIndex((x) => x._id === button._id);
    if (index >= 0) {
      const menuButtons = selectedMenuButtons.filter(
        (b) => b._id !== button._id
      );
      setSelectedMenuButtons(menuButtons);
    } else {
      setSelectedMenuButtons([...selectedMenuButtons, button]);
    }

    const regionId = JSON.parse(localStorage.getItem("data")).regionDetails[0]
      ._id;

    let checkArrayExist = addProductionFormik.values.productDetails.some(
      (details) => details.productCategoryId === button._id
    );
    if (checkArrayExist) {
      addProductionFormik.setFieldValue(
        "productDetails",
        addProductionFormik.values.productDetails.filter((value) => {
          return value.productCategoryId !== button._id;
        })
      );
    } else {
      addProductionFormik.setFieldValue("productDetails", [
        ...addProductionFormik.values.productDetails,
        {
          farmersCount: farmersNo,
          productCategoryId: button._id,
          cropName: button.name,
          regionId: regionId,
          products: button.categories.map((value) => {
            return { productId: value._id, count: "", name: value.name };
          }),
        },
      ]);
    }
  };

  const goBack = () => {
    navigate(-1);
  };
  const addUserReset = () => {
    reset();
    setShow(false);
    setOpacity(0);
  };
  const handleClose = () => {
    setShow(false);
    setOpacity(0);
  };
  const handleShow = () => {
    setShow(true);
    setOpacity(1);
  };
  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader">
          <h3>Add Production</h3>
          <button onClick={goBack}>
            <img
              src={Back}
              alt=""
            />{" "}
            Back
          </button>
        </div>
        <div className="cardbg">
          <img
            src={cardBoxImage}
            alt="card bg"
          />
        </div>
      </div>{" "}
      <form onSubmit={addProductionFormik.handleSubmit}>
        <div className="politryStockForm">
          <div className="politryStock">
            <div className="AddprodRow1">
              {/* <div className="addproductionRadiobuttonsBox">
                Cropland Under cultivation
                <div className="addproductionRadiobuttons">
                  <div className="addRadio">
                    <input
                      type="radio"
                      id="Crop"
                      name="fav_language"
                      value="Crop"
                    />
                      <label for="Crop">Crop</label>
                  </div>
                  <div className="addRadio">
                     {" "}
                    <input
                      type="radio"
                      id="Livestock"
                      name="fav_language"
                      value="Livestock"
                    />
                      <label for="Livestock">Livestock</label>
                  </div>
                  <div className="addRadio">
                     {" "}
                    <input
                      type="radio"
                      id="Schools"
                      name="fav_language"
                      value="Schools"
                    />
                     {" "}
                    <label for="Schools">
                      Schools' Agriculture Support Program
                    </label>
                  </div>
                </div>
              </div> */}

              <div className="farmersBox">
                No. of Farmers
                <div className="farmers">
                  <input
                    required
                    type="number"
                    pattern="[1-9]*"
                    min={1}
                    id="number"
                    step="any"
                    name="farmersCount"
                    value={farmersNo || ""}
                    onChange={(e) => {
                      console.log("value");
                      console.log(Number(e.target.value));
                      setFarmersNo(Number(e.target.value));
                      if (addProductionFormik.values.productDetails.length) {
                        addProductionFormik.values.productDetails.map(
                          (value) => {
                            return (value.farmersCount = Number(
                              e.target.value
                            ));
                          }
                        );
                      }
                    }}
                    onKeyDown={handleKeyDown}
                    onWheel={(event) => event.currentTarget.blur()}
                  />
                  <div className="farmersbtn">
                    <label
                      id="discr"
                      onClick={() => {
                        if (farmersNo !== 0) {
                          setFarmersNo(Number(farmersNo) - 1);
                          if (
                            addProductionFormik.values.productDetails.length
                          ) {
                            addProductionFormik.values.productDetails.map(
                              (value) => {
                                return (value.farmersCount =
                                  Number(farmersNo) - 1);
                              }
                            );
                          }
                        }
                      }}>
                      -
                    </label>
                    <label
                      id="insr"
                      className="active"
                      onClick={() => {
                        setFarmersNo(Number(farmersNo) + 1);
                        if (addProductionFormik.values.productDetails.length) {
                          addProductionFormik.values.productDetails.map(
                            (value) => {
                              return (value.farmersCount =
                                Number(farmersNo) + 1);
                            }
                          );
                        }
                      }}>
                      +
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="farmersBox">
              Cropland Under Cultivation
              <div className="buttons">
                {menubuttons &&
                  menubuttons[0]?.Crops?.map((button, index) => (
                    <button
                      onClick={() => onAnimalSelect(button)}
                      className={`${
                        selectedMenuButtons?.findIndex(
                          (x) => x._id === button._id
                        ) >= 0
                          ? "active"
                          : ""
                      }`}
                      key={`clBtn-${index}`}
                      type="button">
                      {button.name}
                    </button>
                  ))}
              </div>
            </div>

            {addProductionFormik.values.productDetails.map(
              (crops, mainIndex) => {
                return (
                  <div className="AddprodRow2">
                    <h5 className="addboxtitle">{crops.cropName}</h5>
                    <div className="inputBoxes">
                      <div className="row">
                        {crops?.products?.map((value, subIndex) => {
                          return (
                            <div className="col-md-3">
                              <div className="inputAdd addProductbox">
                                <label className="inputAddLabel">
                                  {value.name}
                                </label>
                                <input
                                  required
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  // min="1"
                                  placeholder="Enter Qty"
                                  name="Cattle"
                                  value={value.count || ""}
                                  onChange={(e) => {
                                    addProductionFormik.setFieldValue(
                                      `productDetails[${mainIndex}].products[${subIndex}].count`,
                                      e.target.value
                                    );
                                  }}
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>

          <div className="bottomButtons">
            <button
              type="submit"
              className="addprdt active"
              id="addprdt">
              Add production
            </button>
            <button
              type="reset"
              className="reset"
              id="rst"
              onClick={handleShow}>
              {" "}
              Reset
            </button>
          </div>
        </div>
      </form>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ opacity: opacity }}>
        <Modal.Header closeButton>
          <Modal.Title> Reset?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}>
            No
          </Button>
          <Button
            variant="primary"
            onClick={addUserReset}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddProduction;
