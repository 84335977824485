import React, { useEffect, useState } from "react";
import { Box, Pagination } from "@mui/material";
import moment from "moment";
import { fetchPoultryLogList } from "../../services/poultryLog";
import { useSelector } from "react-redux";
import Shimmer from "../shimmer";
import { useNavigate } from "react-router";
import cardboxbg from "./../../assets/images/cardboxbg.png";
import Back from "./../../assets/images/backbtn.svg";
import Notfound from "../data-not-found";

const PoultryLogTablePage = () => {
  const zoneRefresh = useSelector((state) => state.updateZone.value);
  const [loading, setLoading] = useState(true);
  const [poultryLogList, setPoultryLogList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalData, setTotalData] = useState(0);

  const getPoultryLogList = async (page) => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const res = await fetchPoultryLogList(
      page,
      pageSize,
      regionId,
      startDate,
      endDate
    );
    if (res.status) {
      setLoading(false);
      setTotalData(res.count);
      setPoultryLogList([
        {
          header: res.header,
          row: res.row,
        },
      ]);
    }
  };

  useEffect(() => {
    getPoultryLogList(page);
  }, [page, zoneRefresh]);

  const handleChangePagination = async (event, value) => {
    setPage(value);
  };
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div>
      <div className="theme-container">
        <div className="contentCard2 tablePagetop">
          <div className="contentCardHeader addProdctHeader">
            <h3>Poultry Log</h3>
            <button onClick={goBack}>
              {" "}
              <img src={Back} alt="" /> Back
            </button>
          </div>
          <div className="cardbg">
            <img src={cardboxbg} alt="card bg" />
          </div>
        </div>
        <section className="bottom">
          <div className="themeCard ">
            <div className="CardContainer">
              <div>
                {loading ? (
                  <Shimmer />
                ) : (
                  <table className="table-bordered home-table">
                    <thead>
                      <tr>
                        {poultryLogList &&
                          poultryLogList[0]?.header?.map((value, index) => {
                            return (
                              <th
                                rowSpan="2"
                                className={index < 9 ? "meatinspheadLeft" : ""}
                              >
                                {" "}
                                <strong>{value}</strong>
                              </th>
                            );
                          })}
                      </tr>
                    </thead>
                    <tbody>
                      {poultryLogList && poultryLogList[0]?.row?.length > 0 ? (
                        <>
                          {poultryLogList &&
                            poultryLogList[0]?.row?.map((value) => {
                              return (
                                <tr>
                                  <td className="employeeName">
                                    {value.userName}
                                  </td>
                                  <td className="employeeName">
                                    {value.funName}
                                  </td>
                                  <td className="dates">
                                    {moment(value.dob).format("DD-MM-yyyy")}
                                  </td>
                                  <td className="animaltypes">
                                    {value.breed === "" || value.breed === " "
                                      ? "-"
                                      : value.breed}
                                  </td>
                                  <td className="animaltypes">
                                    {value.size === "" || value.size === " "
                                      ? "-"
                                      : value.size}
                                  </td>
                                  <td className="animaltypes">
                                    {value.color === "" || value.color === " "
                                      ? "-"
                                      : value.color}
                                  </td>
                                  <td className="dates">
                                    {moment(value.purchaseDate).format(
                                      "DD-MMM-yyyy"
                                    )}
                                  </td>
                                  <td className="dates">
                                    {moment(value.eggLayingDate).format(
                                      "DD-MMM-yyyy"
                                    )}
                                  </td>
                                  <td className="dates">
                                    {moment(value.saleDate).format(
                                      "DD-MMM-yyyy"
                                    )}
                                  </td>
                                  <td className="dates">
                                    {moment(value.chickPurchaseDate).format(
                                      "DD-MMM-yyyy"
                                    )}
                                  </td>
                                  <td className="">{value.type}</td>
                                </tr>
                              );
                            })}
                        </>
                      ) : (
                        <div className="tablenotfound">
                          <Notfound />
                        </div>
                      )}
                    </tbody>
                  </table>
                )}
                <Box
                  justifyContent={"center"}
                  alignItems="center"
                  display={"flex"}
                  sx={{ margin: "20px 0px" }}
                >
                  {/* <Typography>{page}</Typography> */}
                  <Pagination
                    page={page}
                    count={Math.ceil(Number(totalData / pageSize))}
                    variant="outlined"
                    color="primary"
                    onChange={handleChangePagination}
                    showFirstButton
                    showLastButton
                  />
                </Box>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default PoultryLogTablePage;
