import React from "react";
import cardBoxImage from "../assets/images/cardboxbg.png";
import { useNavigate } from "react-router-dom";

const ReportPage = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div class="theme-container">
      <div class="contentCard2">
        <div class="contentCardHeader addProdctHeader">
          <h3>Report - Food Security</h3>
          <button onClick={goBack}>&larr; BACK</button>
        </div>
        <div class="cardbg">
          <img src={cardBoxImage} alt="card bg" />
        </div>
      </div>
      <div class="politryStockForm">
        <div class="politryStock">
          <div class="AddprodRow1">
            <div class="formatDis">
              formating district
              <select name="" id="">
                <option value="">All</option>
                <option value="">First</option>
              </select>
            </div>
            <div class="formatDis">
              Date Range
              <input
                class="dateInput"
                type="text"
                name="daterange"
                value="01/01/2018 - 01/15/2018"
              />
            </div>
            <div class="applyButton">
              <button class="reset active" id="reset" onclick="reset()">
                {" "}
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="tablebox">
        <table class="table table-bordered table-striped">
          <tr>
            <td rowSpan="2" style={{ verticalAlign: "middle" }}>
              <strong>INDICATORS</strong>
            </td>
            <td colSpan="7" class="text-center">
              <strong>FARMING DISTRICTS</strong>
            </td>
          </tr>
          <tr>
            <td class="tdTotal">TOTAL</td>
            <td>LINDEN/MOBLISSA</td>
            <td>UPPER DEMERARA RIVER</td>
            <td>LOWER DEMERARA RIVER</td>
            <td>UPPER BERBICE RIVER</td>
            <td>UPPER ESSEQUIBO RIVER</td>
            <td class="tComments">COMMENTS</td>
          </tr>
          <tr>
            <td class="indication">No. of Agricultural Exhibitions</td>
            <td class="tdTotal">680</td>
            <td>140</td>
            <td>170</td>
            <td>80</td>
            <td>94</td>
            <td>160</td>
            <td class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </td>
          </tr>
          <tr>
            <td class="indication">No. of Farmers' Market Day</td>
            <td class="tdTotal">680</td>
            <td>140</td>
            <td>170</td>
            <td>80</td>
            <td>94</td>
            <td>160</td>
            <td class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </td>
          </tr>
          <tr>
            <td class="indication">No. of Active Vertical Planting Units</td>
            <td class="tdTotal">680</td>
            <td>140</td>
            <td>170</td>
            <td>80</td>
            <td>94</td>
            <td>160</td>
            <td class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </td>
          </tr>
          <tr>
            <td class="indication">Acoushi Ant Bait Distributed</td>
            <td class="tdTotal">680</td>
            <td>140</td>
            <td>170</td>
            <td>80</td>
            <td>94</td>
            <td>160</td>
            <td class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </td>
          </tr>
          <tr class="bgblue">
            <td colSpan="8">Agricultural Inputs Distribution</td>
          </tr>
          <tr>
            <td class="indication">No. of Distirbutions Conducted</td>
            <td class="tdTotal">680</td>
            <td>140</td>
            <td>170</td>
            <td>80</td>
            <td>94</td>
            <td>160</td>
            <td class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </td>
          </tr>
          <tr>
            <td class="indication">No. of Farmers Group Benefiting</td>
            <td class="tdTotal">680</td>
            <td>140</td>
            <td>170</td>
            <td>80</td>
            <td>94</td>
            <td>160</td>
            <td class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </td>
          </tr>
          <tr>
            <td class="indication">No. of Farmers Benefiting</td>
            <td class="tdTotal">680</td>
            <td>140</td>
            <td>170</td>
            <td>80</td>
            <td>94</td>
            <td>160</td>
            <td class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </td>
          </tr>
          <tr class="bgblue">
            <td colSpan="8">Apiculture Support</td>
          </tr>
          <tr>
            <td class="indication">No. of Apiculturists</td>
            <td class="tdTotal">680</td>
            <td>140</td>
            <td>170</td>
            <td>80</td>
            <td>94</td>
            <td>160</td>
            <td class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </td>
          </tr>
          <tr>
            <td class="indication">No. of Distributions</td>
            <td class="tdTotal">680</td>
            <td>140</td>
            <td>170</td>
            <td>80</td>
            <td>94</td>
            <td>160</td>
            <td class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </td>
          </tr>
          <tr>
            <td class="indication">No. of Apiculturists Benefiting</td>
            <td class="tdTotal">680</td>
            <td>140</td>
            <td>170</td>
            <td>80</td>
            <td>94</td>
            <td>160</td>
            <td class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </td>
          </tr>
          <tr class="bgblue">
            <td colSpan="8">Media Coverage Awareness Programs</td>
          </tr>
          <tr>
            <td class="indication">No. of TV Programs/Appearances Conducted</td>
            <td class="tdTotal">680</td>
            <td>140</td>
            <td>170</td>
            <td>80</td>
            <td>94</td>
            <td>160</td>
            <td class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </td>
          </tr>
          <tr>
            <td class="indication">No. Radio Programs Conducted</td>
            <td class="tdTotal">680</td>
            <td>140</td>
            <td>170</td>
            <td>80</td>
            <td>94</td>
            <td>160</td>
            <td class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </td>
          </tr>
          <tr class="bgblue">
            <td colSpan="8">Media Coverage Awareness Programs</td>
          </tr>
          <tr class="bgorange">
            <td colSpan="8">No. of Animals Treated</td>
          </tr>
          <tr>
            <td class="indication">Swine</td>
            <td class="tdTotal">680</td>
            <td>140</td>
            <td>170</td>
            <td>80</td>
            <td>94</td>
            <td>160</td>
            <td class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </td>
          </tr>
          <tr>
            <td class="indication">Small Ruminants</td>
            <td class="tdTotal">680</td>
            <td>140</td>
            <td>170</td>
            <td>80</td>
            <td>94</td>
            <td>160</td>
            <td class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </td>
          </tr>
          <tr>
            <td class="indication">Cattle</td>
            <td class="tdTotal">680</td>
            <td>140</td>
            <td>170</td>
            <td>80</td>
            <td>94</td>
            <td>160</td>
            <td class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </td>
          </tr>
          <tr class="bgorange">
            <td colSpan="8">Drugs Administered and Distributed</td>
          </tr>
          <tr>
            <td class="indication">Anti-inflammatory</td>
            <td class="tdTotal">680</td>
            <td>140</td>
            <td>170</td>
            <td>80</td>
            <td>94</td>
            <td>160</td>
            <td class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </td>
          </tr>
          <tr>
            <td class="indication">Antibiotics</td>
            <td class="tdTotal">680</td>
            <td>140</td>
            <td>170</td>
            <td>80</td>
            <td>94</td>
            <td>160</td>
            <td class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </td>
          </tr>
          <tr>
            <td class="indication">Antiparasitics</td>
            <td class="tdTotal">680</td>
            <td>140</td>
            <td>170</td>
            <td>80</td>
            <td>94</td>
            <td>160</td>
            <td class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </td>
          </tr>
          <tr>
            <td class="indication">Vitamins & Minerals</td>
            <td class="tdTotal">680</td>
            <td>140</td>
            <td>170</td>
            <td>80</td>
            <td>94</td>
            <td>160</td>
            <td class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </td>
          </tr>
          <tr>
            <td class="indication">Emergency</td>
            <td class="tdTotal">680</td>
            <td>140</td>
            <td>170</td>
            <td>80</td>
            <td>94</td>
            <td>160</td>
            <td class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </td>
          </tr>
          <tr class="bgblue">
            <td colSpan="8">Schools' Agriculture Support Program</td>
          </tr>
          <tr class="bgorange">
            <td colSpan="8">Kitchen Garden Iniative</td>
          </tr>
          <tr class="bgwhite">
            <td class="indication">
              No. of Schools With Operational Kitchen Garden
            </td>
            <td class="tdTotal">680</td>
            <td colSpan="6" rowSpan="2" class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed metus
              nunc, accumsan ultricies orci eget, maximus tristique leo. Aliquam
              a lacus eget urna auctor placerat sit amet vitae neque. Donec nec
              laoreet augue, ut aliquam orci. Nullam leo enim, rutrum eget sem
              vitae, blandit sagittis libero. Morbi eleifend libero vel eros
              efficitur consequat. In quis
            </td>
          </tr>
          <tr class="bgwhite">
            <td class="indication">No. of Pupils/Students Benefiting</td>
            <td class="tdTotal">680</td>
          </tr>
          <tr class="bgorange">
            <td colSpan="8">Shadehouse</td>
          </tr>
          <tr class="bgwhite">
            <td class="indication">
              No. of Schools With Operational Shadehouse
            </td>
            <td class="tdTotal">680</td>
            <td colSpan="6" rowSpan="2" class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed metus
              nunc, accumsan ultricies orci eget, maximus tristique leo. Aliquam
              a lacus eget urna auctor placerat sit amet vitae neque. Donec nec
              laoreet augue, ut aliquam orci. Nullam leo enim, rutrum eget sem
              vitae, blandit sagittis libero. Morbi eleifend libero vel eros
              efficitur consequat. In quis
            </td>
          </tr>
          <tr class="bgwhite">
            <td class="indication">No. of Students Benefiting</td>
            <td class="tdTotal">680</td>
          </tr>
          <tr class="bgorange">
            <td colSpan="8">Poultry Rearing</td>
          </tr>
          <tr class="bgwhite">
            <td class="indication">No. of Schools With Operational Pens</td>
            <td class="tdTotal">680</td>
            <td colSpan="6" rowSpan="2" class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed metus
              nunc, accumsan ultricies orci eget, maximus tristique leo. Aliquam
              a lacus eget urna auctor placerat sit amet vitae neque. Donec nec
              laoreet augue, ut aliquam orci. Nullam leo enim, rutrum eget sem
              vitae, blandit sagittis libero. Morbi eleifend libero vel eros
              efficitur consequat. In quis
            </td>
          </tr>
          <tr class="bgwhite">
            <td class="indication">No. of Students Benefiting</td>
            <td class="tdTotal">680</td>
          </tr>
          <tr class="bgorange">
            <td colSpan="8">Lectures & Prcatical Sessions</td>
          </tr>
          <tr class="bgwhite">
            <td class="indication">No. of Sessions Conducted</td>
            <td class="tdTotal">680</td>
            <td colSpan="6" rowSpan="3" class="tpara">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed metus
              nunc, accumsan ultricies orci eget, maximus tristique leo. Aliquam
              a lacus eget urna auctor placerat sit amet vitae neque. Donec nec
              laoreet augue, ut aliquam orci. Nullam leo enim, rutrum eget sem
              vitae, blandit sagittis libero. Morbi eleifend libero vel eros
              efficitur consequat. In quis
            </td>
          </tr>
          <tr class="bgwhite">
            <td class="indication">No. of Schools Benefiting</td>
            <td class="tdTotal">680</td>
          </tr>
          <tr class="bgwhite">
            <td class="indication">No. of Pupils & Students Benefiting</td>
            <td class="tdTotal">680</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default ReportPage;
