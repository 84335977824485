import { configureStore } from "@reduxjs/toolkit";
import updateZoneSlice from "./slice/counterSlice";
import updateCarousalPosition from "./slice/carousalSlice";

export const store = configureStore({
  reducer: {
    updateZone: updateZoneSlice,
    updateCarousal: updateCarousalPosition,
  },
});

/// for example use this link ==> https://redux-toolkit.js.org/tutorials/quick-start
