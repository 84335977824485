import  React from "react";
import cardBoxImage from "./../assets/images/cardboxbg.png";
import editbtn from "./../assets/images/mngmntEdit.png";
import PassVis from "./../assets/images/tablepasviss.png";
import {useNavigate} from 'react-router-dom';

const UserManagement = () => {
  
  const navigate = useNavigate();    
  const goBack = () => {
    navigate(-1);    
}
  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader">
          <h3>User Management</h3>
          <button onClick={goBack}>&larr; BACK</button>
        </div>
        <div className="cardbg">
          <img src={cardBoxImage} alt="card bg" />
        </div>
      </div>{" "}
      <form>
        <div className="politryStockForm">
          <div className="politryStock addincomeForm">
          <div className="row">
                          <div className="col-md-3">
                          <div className="incomeRow">
                        <label> Zone</label>                     
                      <select>
                        <option>Select Zone</option>
                      </select>
                      </div>
                      </div>  
                      <div className="col-md-3">
                      <div className="incomeRow">
                        <label> Search</label>                     
                        <input  type="text" placeholder="Search by user id"/>
                      </div>
                      </div>  
                      <div className="col-md-2">            
                           <div className="bottomButtons userMngmntBtn">         
            <button type="submit" className="addprdt mngmntApplybtn active" id="addprdt">
              APPLY
            </button>
                          </div>
                    </div>
             </div>     
             <div className="usermngntPageTable">
                <table className="usermngmntTable">
                    <tr>
                        <th>USER ID</th>
                        <th>PASSWORD</th>
                        <th>ROLL</th>
                        <th>ACCESS</th>
                        <th>ZONE</th>
                        <th>STATUS</th>
                        <th>ACTION</th>
                    </tr>
                    <tr>
                        <td>nagaart@gmail.com</td>
                        <td className="mngTblPass">Red@yellowCad <button><img className="tablepassIcon" src={PassVis} alt=""/></button></td>
                        <td>Admin USER</td>
                        <td>Read, Write</td>
                        <td>Linden/Moblissa</td>
                        <td className="tableSwitchbtn">
                            <label class="switch">
                            <input type="checkbox"  />
                                <span class="slider round"></span>
                               </label>Active</td>
                        <td><img  src={editbtn} alt=""/></td>
                    </tr>
                    <tr>
                        <td>nagaart@gmail.com</td>
                        <td className="mngTblPass">Red@yellowCad <button><img className="tablepassIcon" src={PassVis} alt=""/></button></td>
                        <td>Admin USER</td>
                        <td>Read, Write</td>
                        <td>Linden/Moblissa</td>
                        <td className="tableSwitchbtn">
                            <label class="switch">
                            <input type="checkbox"  />
                                <span class="slider round"></span>
                               </label>Active</td>
                        <td><img  src={editbtn} alt=""/></td>
                    </tr>
                    <tr>
                        <td>nagaart@gmail.com</td>
                        <td className="mngTblPass">Red@yellowCad <button><img className="tablepassIcon" src={PassVis} alt=""/></button></td>
                        <td>Admin USER</td>
                        <td>Read, Write</td>
                        <td>Linden/Moblissa</td>
                        <td className="tableSwitchbtn">
                            <label class="switch">
                            <input type="checkbox"  />
                                <span class="slider round"></span>
                               </label>Active</td>
                        <td><img  src={editbtn} alt=""/></td>
                    </tr>
                    <tr>
                        <td>nagaart@gmail.com</td>
                        <td className="mngTblPass">Red@yellowCad <button><img className="tablepassIcon" src={PassVis} alt=""/></button></td>
                        <td>Admin USER</td>
                        <td>Read, Write</td>
                        <td>Linden/Moblissa</td>
                        <td className="tableSwitchbtn">
                            <label class="switch">
                            <input type="checkbox"  />
                                <span class="slider round"></span>
                               </label>Active</td>
                        <td><img  src={editbtn} alt=""/></td>
                    </tr>
                    <tr>
                        <td>nagaart@gmail.com</td>
                        <td className="mngTblPass">Red@yellowCad <button><img className="tablepassIcon" src={PassVis} alt=""/></button></td>
                        <td>Admin USER</td>
                        <td>Read, Write</td>
                        <td>Linden/Moblissa</td>
                        <td className="tableSwitchbtn">
                            <label class="switch">
                            <input type="checkbox"  />
                                <span class="slider round"></span>
                               </label>Active</td>
                        <td><img  src={editbtn} alt=""/></td>
                    </tr>
                    <tr>
                        <td>nagaart@gmail.com</td>
                        <td className="mngTblPass">Red@yellowCad <button><img className="tablepassIcon" src={PassVis} alt=""/></button></td>
                        <td>Admin USER</td>
                        <td>Read, Write</td>
                        <td>Linden/Moblissa</td>
                        <td className="tableSwitchbtn">
                            <label class="switch">
                            <input type="checkbox"  />
                                <span class="slider round"></span>
                               </label>Active</td>
                        <td><img  src={editbtn} alt=""/></td>
                    </tr>
                    <tr>
                        <td>nagaart@gmail.com</td>
                        <td className="mngTblPass">Red@yellowCad <button><img className="tablepassIcon" src={PassVis} alt=""/></button></td>
                        <td>Admin USER</td>
                        <td>Read, Write</td>
                        <td>Linden/Moblissa</td>
                        <td className="tableSwitchbtn">
                            <label class="switch">
                            <input type="checkbox"  />
                                <span class="slider round"></span>
                               </label>Inactive</td>
                        <td><img  src={editbtn} alt=""/></td>
                    </tr>
                    <tr>
                        <td>nagaart@gmail.com</td>
                        <td className="mngTblPass">Red@yellowCad <button><img className="tablepassIcon" src={PassVis} alt=""/></button></td>
                        <td>Admin USER</td>
                        <td>Read, Write</td>
                        <td>Linden/Moblissa</td>
                        <td className="tableSwitchbtn">
                            <label class="switch">
                            <input type="checkbox"  />
                                <span class="slider round"></span>
                               </label>Active</td>
                        <td><img  src={editbtn} alt=""/></td>
                    </tr>
                    <tr>
                        <td>nagaart@gmail.com</td>
                        <td className="mngTblPass">Red@yellowCad <button><img className="tablepassIcon" src={PassVis} alt=""/></button></td>
                        <td>Admin USER</td>
                        <td>Read, Write</td>
                        <td>Linden/Moblissa</td>
                        <td className="tableSwitchbtn">
                            <label class="switch">
                            <input type="checkbox"  />
                                <span class="slider round"></span>
                               </label>Active</td>
                        <td><img  src={editbtn} alt=""/></td>
                    </tr>
                    <tr>
                        <td>nagaart@gmail.com</td>
                        <td className="mngTblPass" type="password">Red@yellowCad <button><img className="tablepassIcon" src={PassVis} alt=""/></button></td>
                        <td>Admin USER</td>
                        <td>Read, Write</td>
                        <td>Linden/Moblissa</td>
                        <td className="tableSwitchbtn">
                            <label class="switch">
                            <input type="checkbox"  />
                                <span class="slider round"></span>
                               </label>Active</td>
                        <td><img  src={editbtn} alt=""/></td>
                    </tr>
                    <tr>
                        <td>nagaart@gmail.com</td>
                        <td className="mngTblPass">Red@yellowCad <button><img className="tablepassIcon" src={PassVis} alt=""/></button></td>
                        <td>Admin USER</td>
                        <td>Read, Write</td>
                        <td>Linden/Moblissa</td>
                        <td className="tableSwitchbtn">
                            <label class="switch">
                            <input type="checkbox"  />
                                <span class="slider round"></span>
                               </label>Active</td>
                        <td><img  src={editbtn} alt=""/></td>
                    </tr>
                  
                </table>
                
                </div>    
          </div>

          
        </div>
      </form>
    </div>
  );
};

export default UserManagement;
