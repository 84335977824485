import { useState, React, useEffect } from "react";
import cardBoxImage from "../../assets/images/cardboxbg.png";
import Back from "../../assets/images/backbtn.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  createFoodSecurity,
  fetchSubIndicatory,
} from "../../services/foodSecurity";
import AddFoodSecurityForm from "../../components/foodSecurity/foodSecurityForm";
import showNotification from "../../components/notification/notification";
import { Button, Modal } from "react-bootstrap";

const AddFoodSecurity = () => {
  const navigate = useNavigate();
  const [menubuttons, setMenubuttons] = useState([]);
  const [selectedMenuButtons, setSelectedMenuButtons] = useState([]);
  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(1);

  const addFoodSecurity = useFormik({
    initialValues: {
      data: [],
    },
    onSubmit: async (values) => {
      let data = {};
      data = Object.assign({}, values);
      data.data.map((value, index) => {
        let ds = value.reportDetails.filter((dd) => {
          return dd.selected;
        });
        data.data[index].reportDetails = ds;
      });

      data.data.map((value, findIndex) => {
        value.reportDetails.map((da, secIn) => {
          let ds = da.categoryDetails?.filter((value) => {
            return value.selected;
          });
          delete data.data[findIndex].reportDetails[secIn].categories;
          data.data[findIndex].reportDetails[secIn].categoryDetails = ds;
        });
      });

      data.data.map((value, index) => {
        let newData = value.reportDetails.map((data) => {
          return { subIndicatorId: data._id, ...data };
        });
        data.data[index].reportDetails = newData;
      });

      data.data.map((value, index) => {
        value?.reportDetails?.map((reportData, subIndex) => {
          if (reportData.categoryDetails) {
            let newData = reportData?.categoryDetails?.map((subData) => {
              return { subIndicatorCategoryId: subData._id, ...subData };
            });
            console.log(
              newData,
              data.data[index].reportDetails[subIndex].categoryDetails
            );
            data.data[index].reportDetails[subIndex].categoryDetails = newData;
          }
        });
      });
      if (values.data.length) {
        const res = await createFoodSecurity(data);
        if (res.status) {
          // alert("Log created successfully");
          navigate("/");
          showNotification(
            "Food security data added successfully!",
            " success"
          );
        }
      } else {
        showNotification("Please select animal", "danger");
      }
    },
  });

  const getCropSubCategories = async () => {
    const res = await fetchSubIndicatory();
    if (res.status) {
      console.log(res.data);
      setMenubuttons(res.data);
    }
  };

  useEffect(() => {
    getCropSubCategories();
  }, []);

  const onAnimalSelect = (button) => {
    console.log(button);
    const checkExist = selectedMenuButtons.some((x) => x === button._id);
    if (checkExist) {
      setSelectedMenuButtons(
        selectedMenuButtons.filter((value) => {
          return value !== button._id;
        })
      );
    } else {
      setSelectedMenuButtons([...selectedMenuButtons, button._id]);
    }

    const regionId = JSON.parse(localStorage.getItem("data")).regionDetails[0]
      ._id;

    let checkArrayExist = addFoodSecurity.values.data.some(
      (details) => details.indicatorId === button._id
    );
    if (checkArrayExist) {
      addFoodSecurity.setFieldValue(
        "data",
        addFoodSecurity.values.data.filter((value) => {
          return value.indicatorId !== button._id;
        })
      );
    } else {
      addFoodSecurity.setFieldValue("data", [
        ...addFoodSecurity.values.data,
        {
          regionId: regionId,
          indicatorId: button._id,
          reportDetails: button.subIndicators.map((value) => {
            if (value.categories) {
              return {
                ...value,
                selected: false,
                categoryDetails: value.categories.map((data) => {
                  return { ...data, count: "", comment: "", selected: false };
                }),
              };
            } else {
              return { ...value, count: "", comment: "", selected: false };
            }
          }),
        },
      ]);
    }
  };

  const reset = () => {
    let tempArray = [...addFoodSecurity.values.data];
    setSelectedMenuButtons([]);
    addFoodSecurity.setFieldValue("data", []);
  };

  const changeFormValue = (index, reportIndex, value, changeKey) => {
    let tempArray = [...addFoodSecurity.values.data];
    tempArray[index].reportDetails[reportIndex][`${changeKey}`] = value;
    addFoodSecurity.setFieldValue("data", tempArray);
  };

  const changeCategoryFormValue = (
    index,
    reportIndex,
    categoryIndex,
    value,
    changeKey
  ) => {
    let tempArray = [...addFoodSecurity.values.data];
    tempArray[index].reportDetails[reportIndex].categoryDetails[categoryIndex][
      `${changeKey}`
    ] = value;
    addFoodSecurity.setFieldValue("data", tempArray);
  };

  const goBack = () => {
    navigate(-1);
  };
  const addUserReset = () => {
    reset();
    setShow(false);
    setOpacity(0);
  };
  const handleClose = () => {
    setShow(false);
    setOpacity(0);
  };
  const handleShow = () => {
    setShow(true);
    setOpacity(1);
  };
  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader">
          <h3>Add Food Security Programs</h3>
          <button onClick={goBack}>
            {" "}
            <img
              src={Back}
              alt=""
            />{" "}
            Back
          </button>
        </div>
        <div className="cardbg">
          <img
            src={cardBoxImage}
            alt="card bg"
          />
        </div>
      </div>
      {/* -------------------Form---------------------- */}
      <form onSubmit={addFoodSecurity.handleSubmit}>
        <div className="politryStockForm">
          <div className="politryStock">
            <div className="AddprodRow1Egg">
              <div className="eggLogFarmer">
                <div className="farmersBox">
                  <label className="indicatorType"> Indicator Type</label>
                  <div className="buttons AddPlantHealthbtn">
                    {menubuttons &&
                      menubuttons?.map((button) => {
                        return (
                          <button
                            key={button._id}
                            type="button"
                            className={`${
                              selectedMenuButtons?.some((x) => x === button._id)
                                ? "active"
                                : ""
                            }`}
                            onClick={() => onAnimalSelect(button)}>
                            {button.name}
                          </button>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            {addFoodSecurity?.values?.data.map((value, index) => {
              return (
                <AddFoodSecurityForm
                  index={index}
                  formData={value}
                  changeFormValue={changeFormValue}
                  changeCategoryFormValue={changeCategoryFormValue}
                />
              );
            })}
          </div>
          {addFoodSecurity.values.data.length ? (
            <div className="bottomButtons">
              <button
                type="submit"
                className="addprdt active"
                id="addprdt">
                Add Food Security Programs
              </button>
              <button
                type="reset"
                className="reset"
                id="rst"
                onClick={handleShow}>
                {" "}
                Reset
              </button>
            </div>
          ) : (
            <div className="select-animal-type">
              {" "}
              <p className="pleaseSelectindic">Please Select Indicator</p>
            </div>
          )}
        </div>
      </form>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ opacity: opacity }}>
        <Modal.Header closeButton>
          <Modal.Title> Reset?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}>
            No
          </Button>
          <Button
            variant="primary"
            onClick={addUserReset}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddFoodSecurity;
