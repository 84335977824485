import { requestWithToken } from "../helpers/helpers";

const fetchSubIndicatory = async () => {
  try {
    const response = await requestWithToken("GET", "/admin/subIndicators");
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createFoodSecurity = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      "/report/foodSecurity",
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchFoodSecurityIndicator = async (regionId, startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/report/foodSecurityList?regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/report/foodSecurityList?regionId=${regionId}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const fetchFoodSecurityCategery = async (regionId, startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/report/foodSecurityCategoryList?regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/report/foodSecurityCategoryList?regionId=${regionId}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  fetchSubIndicatory,
  createFoodSecurity,
  fetchFoodSecurityIndicator,
  fetchFoodSecurityCategery,
};
