import React, { useEffect, useState } from "react";
import { Box, Pagination } from "@mui/material";
import moment from "moment";
import { fetchSchoolProgramList } from "../../../services/production";
import { useNavigate } from "react-router-dom";
import cardboxbg from "../../../assets/images/cardboxbg.png";
import { useSelector } from "react-redux";
import Shimmer from "../../shimmer";
import Notfound from "../../data-not-found";
import cardBoxImage from "../../../assets/images/cardboxbg.png";
import Back from "../../../assets/images/backbtn.svg";
import Papa from "papaparse";
import { downloadCsv } from "../../../services/user";
import CSV from "../../../assets/images/csv.svg";
import Pdf from "../../../assets/images/pdf.svg";
var fileDownload = require("js-file-download");

const SchoolProgramTable = () => {
  const zoneRefresh = useSelector((state) => state.updateZone.value);
  const [loading, setLoading] = useState(true);
  const [schoolProgramList, setSchoolProgramList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [csvData, setCsvData] = useState([]);

  const getProductionList = async (page) => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const res = await fetchSchoolProgramList(
      page,
      pageSize,
      regionId,
      startDate,
      endDate
    );
    if (res.status) {
      setLoading(false);
      setSchoolProgramList([
        {
          header: res.header,
          row: res.row,
        },
      ]);
      setTotalData(res.count);
    }
  };

  const convertToCsv = async (downloadType) => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    downloadCsv(18, downloadType, regionId, startDate, endDate).then((res) => {
      if (res) {
        if (downloadType == 1) {
          createCsv(res);
        } else {
          fileDownload(res, "file.pdf");
        }
      }
    });
  };
  const createCsv = (res, downloadType) => {
    const blob = new Blob([res], { type: "text/csv;charset=utf-8;" });
    // Create a temporary URL for the Blob
    const csvUrl = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const downloadLink = document.createElement("a");
    downloadLink.href = csvUrl;
    downloadLink.download = "file.csv";
    // Simulate a click on the link to trigger the download
    downloadLink.click();

    // Clean up by revoking the URL
    window.URL.revokeObjectURL(csvUrl);
  };

  useEffect(() => {
    getProductionList(page);
  }, [page, pageSize, zoneRefresh]);
  const handleChangePagination = async (event, value) => {
    setPage(value);
  };
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="theme-container">
      <div className="contentCard2 ">
        <div className="contentCardHeader addProdctHeader tablePageHeader">
          <h3>School Agriculture Support Program</h3>
          <div className="tablePageButtons">
            <button onClick={goBack}>
              {" "}
              <img
                src={Back}
                alt=""
              />{" "}
              Back
            </button>
            <div className="downloadBtns">
              <button
                className={
                  schoolProgramList[0]?.row?.length === 0
                    ? "fadeDownload downloadtblbtn"
                    : "downloadtblbtn"
                }
                onClick={() => {
                  convertToCsv(1);
                }}
                type="button">
                Download CSV
                <img
                  className="dwlnldIcon"
                  src={CSV}
                  alt=""
                />
              </button>
              <button
                className={
                  schoolProgramList[0]?.row?.length === 0
                    ? "fadeDownload downloadtblbtn"
                    : "downloadtblbtn"
                }
                onClick={() => {
                  convertToCsv(2);
                }}
                type="button">
                Download PDF
                <img
                  className="dwlnldIcon"
                  src={Pdf}
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
        <div className="cardbg">
          <img
            src={cardboxbg}
            alt="card bg"
          />
        </div>
      </div>
      <div className="politryStockForm politryStockFormtbl">
        <section className="bottom tablePagesection">
          <div className="themeCard tableThemeCard">
            <div className="CardContainer">
              <div className="tableBlockPage">
                {loading ? (
                  <Shimmer />
                ) : (
                  <table className="table-bordered home-table">
                    <thead>
                      <tr>
                        <th
                          className="incomereportTableHead"
                          colSpan="9">
                          Farming Districts
                        </th>
                      </tr>
                      <tr>
                        {schoolProgramList[0]?.header?.map((value, index) => {
                          if (index <= 1) {
                            return <th></th>;
                          } else if (index > 1 && index < 3) {
                            return <th colSpan={2}>Kitchen Garden</th>;
                          } else if (index > 4 && index < 6) {
                            return <th colSpan={2}>Shadehouse</th>;
                          }
                          if (index > 6) {
                            return <th colSpan={2}>Poultry Rearing</th>;
                          }
                        })}
                      </tr>
                      <tr>
                        {schoolProgramList &&
                          schoolProgramList[0]?.header?.map((value, index) => {
                            return (
                              <th
                                rowSpan="2"
                                className={index < 4 ? "meatinspheadLeft" : ""}>
                                {" "}
                                <strong>{value}</strong>
                              </th>
                            );
                          })}
                      </tr>
                    </thead>
                    <tbody>
                      {schoolProgramList &&
                      schoolProgramList[0]?.row?.length > 0 ? (
                        <>
                          {schoolProgramList &&
                            schoolProgramList[0]?.row?.map((value) => {
                              return (
                                <tr>
                                  <td className="employeeName">
                                    {value.userName}
                                  </td>
                                  <td className="dates">
                                    {moment(value.date).format("DD-MMM-yyyy")}
                                  </td>
                                  <td className="alignmd">
                                    {value.crop === 0 ? "-" : value.crop}
                                  </td>
                                  <td className="alignmd">{value.unit}</td>
                                  <td className="alignmd">
                                    {value.shadehouseCrop}
                                  </td>
                                  <td className="alignmd">
                                    {value.shadehouseUnit}
                                  </td>
                                  <td className="alignmd">
                                    {" "}
                                    {value.liveWeight}
                                  </td>
                                  {/* === 0 || value.liveWeight === ""? "-" : value.liveWeight */}
                                  <td className="alignmd">
                                    {value.slaughterWeight === 0 ||
                                    value.slaughterWeight === ""
                                      ? "-"
                                      : value.slaughterWeight}
                                  </td>
                                </tr>
                              );
                            })}
                        </>
                      ) : (
                        <div className="tablenotfound">
                          <Notfound />
                        </div>
                      )}
                    </tbody>
                  </table>
                )}
                {totalData > 10 ? (
                  <div className="paginantionBox">
                    <div>
                      <Pagination
                        page={page}
                        count={Math.ceil(totalData / pageSize)}
                        variant="outlined"
                        color="primary"
                        onChange={handleChangePagination}
                        showFirstButton
                        showLastButton
                      />
                    </div>
                    <div className="perPageBox">
                      <select
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(Number(e.target.value));
                          setPage(1);
                        }}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                      </select>
                    </div>
                  </div>
                ) : (
                  " "
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SchoolProgramTable;
