import { requestWithToken } from "../helpers/helpers";

const createPoultryLog = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/poultryLog`, payload);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchPoultryLogCard = async (regionId, startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/poultryLog/poultryLogCard?regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/poultryLog/poultryLogCard?regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchPoultryLogList = async (
  pageNo,
  pageSize,
  regionId,
  startDate,
  endDate
) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/poultryLog/poultryLogList?pageNo=${pageNo}&pageSize=${pageSize}&regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/poultryLog/poultryLogList?pageNo=${pageNo}&pageSize=${pageSize}&regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchColors = async () => {
  try {
    const response = await requestWithToken("GET", `/admin/colors`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchSizes = async () => {
  try {
    const response = await requestWithToken("GET", `/admin/sizes`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchLivestockCount = async (regionId, startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/livestockSlaughter/livestockCount?regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/livestockSlaughter/livestockCount?regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchEggCount = async (regionId, startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/eggLog/eggCounts?regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/eggLog/eggCounts?regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  fetchPoultryLogCard,
  fetchPoultryLogList,
  createPoultryLog,
  fetchColors,
  fetchSizes,
  fetchLivestockCount,
  fetchEggCount,
};
