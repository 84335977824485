import { useState, React, useEffect } from "react";
import cardBoxImage from "../../assets/images/cardboxbg.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { createLivestock, fetchMeatCategory } from "../../services/livestock";
import AddDippingForm from "../../components/dippingAndVaccination/AddDippingForm";
import { createDipping } from "../../services/dipping";
import Back from "../../assets/images/backbtn.svg";

import {
  editUser,
  fetchRegions,
  fetchRoles,
  fetchUserDetails,
} from "../../services/user";
import moment from "moment";
import { USER_ROLE } from "../../helpers/userRoleEnum";
import passvissIcon from "../../assets/images/passvissIcon.png";
import eyeslash from "../../assets/images/eyeslash.svg";
import showNotification from "../../components/notification/notification";

const EditUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [disableRead, setDisableReact] = useState(false);
  const [zoneList, setZoneList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const addUserFormik = useFormik({
    initialValues: {
      regionId: [],
      firstName: "",
      lastName: "",
      permissionType: [],
      role: "",
      status: 1,
    },
    onSubmit: async (values) => {
      const res = await editUser(id, values);
      if (res.status) {
        showNotification(" User data updated successfully", "success");

        navigate("/users");
      } else {
        showNotification(`${res.message}`, "danger");
      }
    },
    onReset: (value) => {
      value.firstName = "";
    },
  });

  const getRegions = async () => {
    const res = await fetchRegions();
    if (res.status) {
      setZoneList(res.data);
    }
  };

  const getRoles = async () => {
    const res = await fetchRoles();
    if (res.status) {
      const role = res.data.filter((value) => {
        return value.shortCode !== "SUPERADMIN";
      });
      setRoleList(role);
    }
  };

  const getUserDetail = async () => {
    const res = await fetchUserDetails(id);
    if (res.status) {
      // addUserFormik.setFieldValue("email", res.data[0].email);
      addUserFormik.setFieldValue("firstName", res.data[0].firstName);
      addUserFormik.setFieldValue("lastName", res.data[0].lastName);
      addUserFormik.setFieldValue("role", res.data[0].role);
      addUserFormik.setFieldValue(
        "regionId",
        res.data[0].regionDetails.map((value) => {
          return value._id;
        })
      );
      addUserFormik.setFieldValue("permissionType", res.data[0].permissionType);
    }
  };

  useEffect(() => {
    getRegions();
    getRoles();
    getUserDetail();
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader">
          <h3>Edit User</h3>
          <button onClick={goBack}>
            <img src={Back} alt="" /> BACK
          </button>
        </div>
        <div className="cardbg">
          <img src={cardBoxImage} alt="card bg" />
        </div>
      </div>
      {/* -------------------Form---------------------- */}
      <form
        onSubmit={addUserFormik.handleSubmit}
        onReset={addUserFormik.handleReset}
      >
        <div className="politryStockForm">
          <div className="politryStock addForm">
            <div className="addFormPage">
              <div className="row1">
                <div className="row">
                  <div className="col-md-3">
                    <div className="addFormRow">
                      <label> Zone</label>
                      <select
                        required
                        value={addUserFormik.values.regionId[0]}
                        onChange={(e) => {
                          addUserFormik.setFieldValue("regionId", [
                            e.target.value,
                          ]);
                        }}
                      >
                        <option value="">Select Zone</option>
                        {zoneList.map((value) => {
                          return (
                            <option value={value._id}>{value.name}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="addFormRow">
                      <label> Role</label>
                      <div className="addUserCheckbox">
                        {roleList.map((role) => {
                          return (
                            <div className="addUserCheck custom_radio">
                              <input
                                type="radio"
                                id={role.name}
                                name={role.name}
                                checked={role._id === addUserFormik.values.role}
                                onClick={() => {
                                  addUserFormik.setFieldValue("role", role._id);
                                  if (
                                    role.shortCode === USER_ROLE.FIELDOFFICER
                                  ) {
                                    setDisableReact(true);
                                    addUserFormik.setFieldValue(
                                      "permissionType",
                                      addUserFormik.values.permissionType.filter(
                                        (value) => {
                                          return value !== 1;
                                        }
                                      )
                                    );
                                    addUserFormik.setFieldValue(
                                      "permissionType",
                                      [2]
                                    );
                                  } else {
                                    setDisableReact(false);
                                  }
                                  if (
                                    role.shortCode == USER_ROLE.ADMINOFFICER
                                  ) {
                                    addUserFormik.setFieldValue(
                                      "permissionType",
                                      [1]
                                    );
                                  }
                                }}
                              />{" "}
                              <label htmlFor={role.name}>{role.name}</label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="addFormRow">
                      <label>Access</label>
                      <div className="adduserbtn buttons">
                        <button
                          type="button"
                          disabled={disableRead}
                          className={`${
                            addUserFormik.values.permissionType.some(
                              (value) => value === 1
                            )
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            const check =
                              addUserFormik.values.permissionType.some(
                                (value) => value === 1
                              );
                            if (check) {
                              addUserFormik.setFieldValue(
                                "permissionType",
                                addUserFormik.values.permissionType.filter(
                                  (value) => {
                                    return value !== 1;
                                  }
                                )
                              );
                            } else {
                              addUserFormik.setFieldValue("permissionType", [
                                ...addUserFormik.values.permissionType,
                                1,
                              ]);
                            }
                          }}
                        >
                          Read
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            const check =
                              addUserFormik.values.permissionType.some(
                                (value) => value === 2
                              );
                            if (check) {
                              addUserFormik.setFieldValue(
                                "permissionType",
                                addUserFormik.values.permissionType.filter(
                                  (value) => {
                                    return value !== 2;
                                  }
                                )
                              );
                            } else {
                              addUserFormik.setFieldValue("permissionType", [
                                ...addUserFormik.values.permissionType,
                                2,
                              ]);
                            }
                          }}
                          className={`${
                            addUserFormik.values.permissionType.some(
                              (value) => value === 2
                            )
                              ? "active"
                              : ""
                          }`}
                        >
                          Write
                        </button>
                        {/* <button>Full Access</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row2">
                <div className="row">
                  <div className="col-md-3">
                    <div className="addFormRow">
                      <label> First Name </label>
                      <input
                        required
                        name="firstName"
                        type="text"
                        placeholder="Enter first name"
                        value={addUserFormik.values.firstName}
                        onChange={addUserFormik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="addFormRow">
                      <label> Last Name </label>
                      <input
                        required
                        name="lastName"
                        type="text"
                        placeholder="Enter last name"
                        value={addUserFormik.values.lastName}
                        onChange={addUserFormik.handleChange}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                    <div className="addFormRow">
                      <label> Email </label>
                      <input
                        required
                        name="email"
                        type="email"
                        placeholder="Enter email"
                        value={addUserFormik.values.email}
                        onChange={addUserFormik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label className="adduser-password">Password</label>
                    <div class="add-user-password">
                      <input
                        required
                        type={showPassword ? "text" : "Password"}
                        name="password"
                        placeholder="Enter Password"
                        value={addUserFormik.values.password}
                        onChange={addUserFormik.handleChange}
                        maxLength={20}
                      />
                      <div
                        class="vissIcon"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        <img
                          src={showPassword ? eyeslash : passvissIcon}
                          alt=""
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-3">
                    <div className="addFormRow">
                      <label> Status</label>
                      <div className="addUserCheckbox">
                        <div className="addUserCheck custom_radio">
                          <input
                            type="radio"
                            id="active"
                            name="active"
                            checked={addUserFormik.values.status === 1}
                            onClick={() => {
                              addUserFormik.setFieldValue("status", 1);
                            }}
                          />{" "}
                          <label htmlFor="active">Active</label>
                        </div>
                        <div className="addUserCheck custom_radio">
                          <input
                            type="radio"
                            id="inactive"
                            name="inactive"
                            checked={addUserFormik.values.status === 2}
                            onClick={() => {
                              addUserFormik.setFieldValue("status", 2);
                            }}
                          />{" "}
                          <label htmlFor="inactive">Inactive</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottomButtons">
            <button type="submit" className="addprdt active" id="addprdt">
              Save User
            </button>
            <button type="reset" className="reset">
              {" "}
              Reset
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditUser;
