import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation } from "react-router-dom";

function AddMoreDropdown() {
  const [activeTab, setActiveTab] = useState(0);

  const location = useLocation();
  const activeSelectedLocation = () => {
    switch (location.pathname) {
      case "/add-vaccination":
        setActiveTab(1);
        break;
      case "/add-livestock-health":
        setActiveTab(2);
        break;
      case "/add-plant":
        setActiveTab(3);
        break;
      case "/add-livestock-service":
        setActiveTab(4);
        break;
      case "/add-livestock":
        setActiveTab(5);
        break;
      case "/add-meat-inspection":
        setActiveTab(6);
        break;
      case "/add-poultryLog":
        setActiveTab(7);
        break;
      case "/add-egg":
        setActiveTab(8);
        break;
      case "/add-income":
        setActiveTab(9);
        break;
      case "/add-expanse":
        setActiveTab(10);
        break;
      case "/add-production":
        setActiveTab(11);
        break;
      default:
        setActiveTab(0);
        break;
    }
  };
  useEffect(() => {
    activeSelectedLocation();
  }, [location.pathname]);

  // const activeDipping = () => {
  //   setDipping(!dipping);
  //   setHealth(false);
  //   setPlant(false);
  //   setService(false);
  //   setSlaughter(false);
  //   setMeat(false);
  //   setPoultry(false);
  //   setIncome(false);
  //   setExpense(false);
  //   setProduction(false);
  //   setEgg(false);
  // };
  // const activeHealth = () => {
  //   setDipping(false);
  //   setHealth(!health);
  //   setPlant(false);
  //   setService(false);
  //   setSlaughter(false);
  //   setMeat(false);
  //   setPoultry(false);
  //   setIncome(false);
  //   setExpense(false);
  //   setProduction(false);
  //   setEgg(false);
  // };
  // const activePlant = () => {
  //   setDipping(false);
  //   setHealth(false);
  //   setPlant(!plant);
  //   setService(false);
  //   setSlaughter(false);
  //   setMeat(false);
  //   setPoultry(false);
  //   setIncome(false);
  //   setExpense(false);
  //   setProduction(false);
  //   setEgg(false);
  // };
  // const activeService = () => {
  //   setDipping(false);
  //   setHealth(false);
  //   setPlant(false);
  //   setService(!service);
  //   setSlaughter(false);
  //   setMeat(false);
  //   setPoultry(false);
  //   setIncome(false);
  //   setExpense(false);
  //   setProduction(false);
  //   setEgg(false);
  // };
  // const activeSlaughter = () => {
  //   setDipping(false);
  //   setHealth(false);
  //   setPlant(false);
  //   setService(false);
  //   setSlaughter(!slaughter);
  //   setMeat(false);
  //   setPoultry(false);
  //   setIncome(false);
  //   setExpense(false);
  //   setProduction(false);
  //   setEgg(false);
  // };
  // const activeMeat = () => {
  //   setDipping(false);
  //   setHealth(false);
  //   setPlant(false);
  //   setService(false);
  //   setSlaughter(false);
  //   setMeat(!meat);
  //   setPoultry(false);
  //   setIncome(false);
  //   setExpense(false);
  //   setProduction(false);
  //   setEgg(false);
  // };
  // const activeExpense = () => {
  //   setDipping(false);
  //   setHealth(false);
  //   setPlant(false);
  //   setService(false);
  //   setSlaughter(false);
  //   setMeat(false);
  //   setPoultry(false);
  //   setIncome(false);
  //   setExpense(!expense);
  //   setProduction(false);
  //   setEgg(false);
  // };
  // const activeIncome = () => {
  //   setDipping(false);
  //   setHealth(false);
  //   setPlant(false);
  //   setService(false);
  //   setSlaughter(false);
  //   setMeat(false);
  //   setPoultry(false);
  //   setIncome(!income);
  //   setExpense(false);
  //   setProduction(false);
  //   setEgg(false);
  // };
  // const activeProduction = () => {
  //   setDipping(false);
  //   setHealth(false);
  //   setPlant(false);
  //   setService(false);
  //   setSlaughter(false);
  //   setMeat(false);
  //   setPoultry(false);
  //   setIncome(false);
  //   setExpense(false);
  //   setProduction(!production);
  //   setEgg(false);
  // };
  // const activePoultry = () => {
  //   setDipping(false);
  //   setHealth(false);
  //   setPlant(false);
  //   setService(false);
  //   setSlaughter(false);
  //   setMeat(false);
  //   setPoultry(!poultry);
  //   setIncome(false);
  //   setExpense(false);
  //   setProduction(false);
  //   setEgg(false);
  // };
  // const activeEgg = () => {
  //   setDipping(false);
  //   setHealth(false);
  //   setPlant(false);
  //   setService(false);
  //   setSlaughter(false);
  //   setMeat(false);
  //   setPoultry(false);
  //   setIncome(false);
  //   setExpense(false);
  //   setProduction(false);
  //   setEgg(!egg);
  // };
  return (
    <Dropdown>
      <Dropdown.Toggle
        id="dropdown-basic"
        className="toprightbtn">
        + ADD NEW
      </Dropdown.Toggle>

      <Dropdown.Menu className="addMore">
        <Dropdown.Item className={activeTab === 1 ? "active" : ""}>
          <Link to="/add-vaccination">Dipping, Spraying & Vaccination</Link>
        </Dropdown.Item>
        <Dropdown.Item className={activeTab === 2 ? "active" : ""}>
          <Link to="/add-livestock-health">Livestock Health Medication</Link>
        </Dropdown.Item>
        <Dropdown.Item className={activeTab === 3 ? "active" : ""}>
          <Link to="/add-plant">Plant & Health Control</Link>
        </Dropdown.Item>
        <Dropdown.Item className={activeTab === 4 ? "active" : ""}>
          <Link to="/add-livestock-service">LiveStock Service</Link>
        </Dropdown.Item>
        <Dropdown.Item className={activeTab === 5 ? "active" : ""}>
          <Link to="/add-livestock">LiveStock Slaughtered</Link>
        </Dropdown.Item>
        <Dropdown.Item className={activeTab === 6 ? "active" : ""}>
          <Link to="/add-meat-inspection">Meat Inspection</Link>
        </Dropdown.Item>
        <Dropdown.Item className={activeTab === 7 ? "active" : ""}>
          <Link to="/add-poultryLog"> Poultry Log</Link>
        </Dropdown.Item>
        <Dropdown.Item className={activeTab === 8 ? "active" : ""}>
          <Link to="/add-egg">Egg Log</Link>
        </Dropdown.Item>
        <Dropdown.Item className={activeTab === 9 ? "active" : ""}>
          <Link to="/add-income">Income</Link>
        </Dropdown.Item>
        <Dropdown.Item className={activeTab === 10 ? "active" : ""}>
          <Link to="/add-expanse">Expense</Link>
        </Dropdown.Item>
        <Dropdown.Item className={activeTab === 11 ? "active" : ""}>
          <Link to="/add-production">Add Production</Link>
        </Dropdown.Item>
        {/* 
        <Dropdown.Item>
          <a href="#">Farmer</a>
        </Dropdown.Item> */}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default AddMoreDropdown;
