import { requestWithToken } from "../helpers/helpers";
const fetchMeatCategory = async () => {
  try {
    const response = await requestWithToken(
      "GET",
      "/productType/meatCategories"
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createLivestock = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      "/livestockSlaughter",
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchLivestock = async (
  pageNo,
  pageSize,
  regionId,
  startDate,
  endDate
) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/livestockSlaughter/SlaughterList?pageNo=${pageNo}&pageSize=${pageSize}&regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/livestockSlaughter/SlaughterList?pageNo=${pageNo}&pageSize=${pageSize}&regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchLivestockSlaughterCard = async (regionId, startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/livestockSlaughter/livestockSlaughterCard?regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/livestockSlaughter/livestockSlaughterCard?regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  fetchMeatCategory,
  createLivestock,
  fetchLivestock,
  fetchLivestockSlaughterCard,
};
