import { useState, React, useEffect } from "react";
import cardBoxImage from "../../assets/images/cardboxbg.png";
import Back from "../../assets/images/backbtn.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { fetchIndicators } from "../../services/report";
import AddReportForm from "../../pages/addForm/reportForm";
import { crateClimateResillence } from "../../services/report";
import showNotification from "../notification/notification";
import { Button, Modal } from "react-bootstrap";

const AddClimateResillencePractice = () => {
  const navigate = useNavigate();
  const [menubuttons, setMenubuttons] = useState([]);
  const [selectedMenuButtons, setSelectedMenuButtons] = useState([]);
  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(1);

  const addReportFormik = useFormik({
    initialValues: {
      data: [],
    },
    onSubmit: async (values) => {
      if (values.data.length) {
        const res = await crateClimateResillence(values);
        if (res.status) {
          showNotification(
            "Climate Resilience data added successfully!",
            "success"
          );
          navigate("/");
        }
      } else {
        showNotification("Please select Indicator", "danger");
      }
    },
  });

  const getIndicators = async () => {
    const type = 5;
    const res = await fetchIndicators(type);
    if (res.status) {
      console.log("called");
      setMenubuttons(res.data);
    }
  };

  useEffect(() => {
    getIndicators();
  }, []);

  const onIndicatorSelect = (button) => {
    console.log(button);
    const checkExist = selectedMenuButtons.some((x) => x === button._id);
    if (checkExist) {
      setSelectedMenuButtons(
        selectedMenuButtons.filter((value) => {
          return value !== button._id;
        })
      );
    } else {
      setSelectedMenuButtons([...selectedMenuButtons, button._id]);
    }
    const regionId = JSON.parse(localStorage.getItem("data")).regionDetails[0]
      ._id;
    let checkArrayExist = addReportFormik.values.data.some(
      (details) => details.indicatorId === button._id
    );
    if (checkArrayExist) {
      addReportFormik.setFieldValue(
        "data",
        addReportFormik.values.data.filter((value) => {
          return value.indicatorId !== button._id;
        })
      );
    } else {
      addReportFormik.setFieldValue("data", [
        ...addReportFormik.values.data,
        {
          regionId: regionId,
          indicatorId: button._id,
          name: button.name,
          count: "",
          comments: "",
        },
      ]);
    }
  };

  const reset = () => {
    const confirmed = window.confirm(" Are you sure?");
    if (confirmed) {
      let tempArray = [...addReportFormik.values.data];
      setSelectedMenuButtons([]);
      tempArray.forEach((element) => {
        element.count = "";
        element.comments = "";
      });
      addReportFormik.setFieldValue("data", []);
    }
  };

  const changeFormValue = (index, value, changeKey) => {
    let tempArray = [...addReportFormik.values.data];
    tempArray[index][`${changeKey}`] = value;
    addReportFormik.setFieldValue("data", tempArray);
  };
  const goBack = () => {
    navigate(-1);
  };
  const addUserReset = () => {
    reset();
    setShow(false);
    setOpacity(0);
  };
  const handleClose = () => {
    setShow(false);
    setOpacity(0);
  };
  const handleShow = () => {
    setShow(true);
    setOpacity(1);
  };
  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader">
          <h3>Add Climate Resilience Practice</h3>
          <button onClick={goBack}>
            {" "}
            <img
              src={Back}
              alt=""
            />{" "}
            Back
          </button>
        </div>
        <div className="cardbg">
          <img
            src={cardBoxImage}
            alt="card bg"
          />
        </div>
      </div>
      {/* -------------------Form---------------------- */}
      <form onSubmit={addReportFormik.handleSubmit}>
        <div className="politryStockForm">
          <div className="politryStock">
            <div className="AddprodRow1Egg">
              <div className="eggLogFarmer">
                <div className="farmersBox">
                  <label>Indicator</label>
                  <div className="buttons">
                    {menubuttons &&
                      menubuttons.map((button, index) => {
                        return (
                          <button
                            key={index}
                            type="button"
                            className={`${
                              selectedMenuButtons?.some((x) => x === button._id)
                                ? "active"
                                : ""
                            }`}
                            onClick={() => onIndicatorSelect(button)}>
                            {button.name}
                          </button>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            {addReportFormik?.values?.data.map((value, index) => {
              return (
                <AddReportForm
                  index={index}
                  formData={value}
                  changeFormValue={changeFormValue}
                />
              );
            })}
          </div>
          {addReportFormik.values.data.length ? (
            <div className="bottomButtons">
              <button
                type="submit"
                className="addprdt active"
                id="addprdt">
                Add Climate Resilience Practice
              </button>
              <button
                type="reset"
                className="reset"
                id="rst"
                onClick={handleShow}>
                {" "}
                Reset
              </button>
            </div>
          ) : (
            <div className="select-animal-type">
              {" "}
              <p>Please Select Indicator </p>
            </div>
          )}
        </div>
      </form>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ opacity: opacity }}>
        <Modal.Header closeButton>
          <Modal.Title> Reset?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}>
            No
          </Button>
          <Button
            variant="primary"
            onClick={addUserReset}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddClimateResillencePractice;
