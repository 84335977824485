import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cardCarousalPosition: 0,
  latestUpdateCarousalPosition: 0,
};

export const updateCarousalPosition = createSlice({
  name: "updatePosition",
  initialState,
  reducers: {
    updateCardCarousalPosition: (state, value) => {
      state.cardCarousalPosition = value.payload;
    },
    updateLatestUpdateCarousalPosition: (state, value) => {
      state.latestUpdateCarousalPosition = value.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateCardCarousalPosition,
  updateLatestUpdateCarousalPosition,
} = updateCarousalPosition.actions;

export default updateCarousalPosition.reducer;
