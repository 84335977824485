import { requestWithToken } from "../helpers/helpers";

const createLivestockHealth = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      "/livestockHealthMedication",
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchLivestockHealth = async (
  pageNo,
  pageSize,
  regionId,
  startDate,
  endDate
) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/livestockHealthMedication/livestockHealthMedicationList?pageNo=${pageNo}&pageSize=${pageSize}&regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/livestockHealthMedication/livestockHealthMedicationList?pageNo=${pageNo}&pageSize=${pageSize}&regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchLivestockHealthCard = async (regionId, startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/livestockHealthMedication/livestockHealthMedicationCard?regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/livestockHealthMedication/livestockHealthMedicationCard?regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  createLivestockHealth,
  fetchLivestockHealth,
  fetchLivestockHealthCard,
};
