import React, { useEffect, useRef, useState } from "react";
import cardboxbg from "../assets/images/cardboxbg.png";
import Back from "../assets/images/backbtn.svg";
import download from "../assets/images/dwnldbtn.svg";
import CSV from "../assets/images/csv.svg";
import Pdf from "../assets/images/pdf.svg";

import page from "../assets/images/page.PNG";
import { Box, Pagination } from "@mui/material";
import { fetchLivestockCount, fetchEggCount } from "../services/poultryLog";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import RevenueChart from "../components/home/revenueChart";
import PoultryLogTable from "../components/home/tableComponent/poultryLogTable";
import Notfound from "../components/data-not-found";
import LivetsockEggChart from "../components/home/livestockEggChart";
import { getImageFullUrl, numberFormat } from "../helpers/helpers";
import Shimmer from "../components/shimmer";
import { downloadCsv } from "../services/user";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";
var fileDownload = require("js-file-download");

const StockPage = () => {
  const chartRef = useRef(null);
  const chartRef5 = useRef(null);
  const zoneRefresh = useSelector((state) => state.updateZone.value);

  const [livestockList, setLivestockList] = useState([]);
  const [eggCount, setEggCount] = useState([]);
  const [liveLoader, setLiveLoaderr] = useState(true);
  const [eggLoader, setEggLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [noOfPages, setNoOfPages] = useState(1);

  const getLivestock = async () => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const res = await fetchLivestockCount(regionId, startDate, endDate);
    setLiveLoaderr(false);
    if (res.status) {
      setLivestockList(res);
    }
  };

  const getEggCount = async () => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const res = await fetchEggCount(regionId, startDate, endDate);
    setEggLoader(false);
    if (res.status) {
      setEggCount([
        {
          colorDetails: res.colorCount,
          sizeDetails: res.sizeCount,
        },
      ]);
      setTotal(res.totalCount);
    }
  };

  const convertToCsv = async (downloadType) => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    downloadCsv(5, downloadType, regionId, startDate, endDate).then((res) => {
      if (res) {
        if (downloadType == 1) {
          createCsv(res);
        } else {
          fileDownload(res, "file.pdf");
        }
      }
    });
  };
  const createCsv = (res, downloadType) => {
    const blob = new Blob([res], { type: "text/csv;charset=utf-8;" });
    // Create a temporary URL for the Blob
    const csvUrl = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const downloadLink = document.createElement("a");
    downloadLink.href = csvUrl;
    downloadLink.download = "file.csv";
    // Simulate a click on the link to trigger the download
    downloadLink.click();

    // Clean up by revoking the URL
    window.URL.revokeObjectURL(csvUrl);
  };

  useEffect(() => {
    getLivestock();
    getEggCount();
  }, [page, zoneRefresh]);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const saveAsImage = (ref) => {
    if (ref.current) {
      html2canvas(ref.current).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "chart.png";
        link.click();
      });
    }
  };

  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader tablePageHeader ">
          <h3>Poultry Stock</h3>

          <div className="tablePageButtons stockpagebtns">
            <button onClick={goBack}>
              {" "}
              <img src={Back} alt="" /> Back
            </button>
            <div className="downloadBtns">
              <button
                className={
                  total === 0 ? "fadeDownload downloadtblbtn" : "downloadtblbtn"
                }
                onClick={() => {
                  convertToCsv(1);
                }}
                type="button"
              >
                Download CSV
                <img className="dwlnldIcon" src={CSV} alt="" />
              </button>
              <button
                className={
                  total === 0 ? "fadeDownload downloadtblbtn" : "downloadtblbtn"
                }
                onClick={() => {
                  convertToCsv(2);
                }}
                type="button"
              >
                Download PDF
                <img className="dwlnldIcon" src={Pdf} alt="" />
              </button>
            </div>
          </div>
        </div>
        <div className="cardbg">
          <img src={cardboxbg} alt="card bg" />
        </div>
      </div>
      <div className="politryStockForm">
        <div className="politryStock1">
          <div className="politryStockCard1">
            <div className="poltryTop">
              <div className="TotalLiv">Total Livestock</div>
              <div className="TotalLivCount">
                {livestockList.total ? numberFormat(livestockList.total) : ""}
              </div>
            </div>
            {liveLoader ? (
              <Shimmer />
            ) : (
              <div className="politryStockCardContent" id="petCards">
                {livestockList && livestockList?.data?.length > 0 ? (
                  <>
                    {livestockList &&
                      livestockList?.data?.map((value) => {
                        return (
                          <div className="petCard">
                            <img
                              src={getImageFullUrl(value?.animalDetails?.image)}
                              alt=""
                            />
                            <div className="aboutPet">
                              <p className="petName">
                                {value?.animalDetails?.name}
                              </p>
                              <h3 className="petCount">
                                {numberFormat(value?.totalCount)}
                              </h3>
                            </div>
                          </div>
                        );
                      })}
                  </>
                ) : (
                  <Notfound />
                )}
              </div>
            )}
          </div>
          <div className="politryStockCard2">
            <div className="poltryTop">
              <div className="TotalEgg">Total Eggs</div>
              {eggCount.length > 0 ? (
                <div className="TotalEggCount">{numberFormat(total)}</div>
              ) : (
                ""
              )}
            </div>
            {eggLoader ? (
              <Shimmer />
            ) : (
              <div className="poultryLogEggCount">
                <div className="colourCard">
                  {eggCount[0]?.colorDetails.map((value, index) => {
                    return (
                      <div className="petCard">
                        <img
                          src={getImageFullUrl(value?.colorDetails?.image)}
                          alt=""
                        />
                        <div className="aboutPet">
                          <p className="petName">
                            {value?.colorDetails?.name} Eggs
                          </p>
                          <h3 className="petCount">
                            {numberFormat(value?.count)}
                          </h3>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="politryStockCardContent" id="topcard2">
                  {eggCount.length > 0 ? (
                    <>
                      {eggCount[0]?.sizeDetails.map((value) => {
                        return (
                          <div className="petCard">
                            <img
                              src={getImageFullUrl(value?.sizeDetails?.image)}
                              alt=""
                            />
                            <div className="aboutPet">
                              <p className="petName">
                                {value?.sizeDetails?.name + " Eggs"}
                              </p>
                              <h3 className="petCount">
                                {numberFormat(value?.count)}
                              </h3>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <Notfound />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <section className="middle">
        <div className="cardRow">
          <div className="themeCard card1">
            <img
              className="chartDownloadbtn"
              src={download}
              onClick={() => {
                saveAsImage(chartRef);
              }}
              alt=""
            />
            <div className="CardContainer" ref={chartRef}>
              <header className="cardHeader">
                <h5 className="cardTitle">Revenue</h5>
              </header>
              <div className="card-body revanues">
                <RevenueChart />
              </div>
            </div>
          </div>

          <div className="themeCard card2">
            <img
              className="chartDownloadbtn"
              src={download}
              onClick={() => {
                saveAsImage(chartRef5);
              }}
              alt=""
            />
            <div className="CardContainer" ref={chartRef5}>
              <header className="cardHeader">
                <h5 className="cardTitle">Performance</h5>
              </header>
              <div className="card-body perform">
                <LivetsockEggChart />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bottom">
        <div className="themeCard ">
          <div className="CardContainer">
            <div className="tableBlock">
              <PoultryLogTable />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
  // : (
  //   <div>Loading...</div>
  // );
};

export default StockPage;
