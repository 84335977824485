import { useState, React, useEffect } from "react";
import cardBoxImage from "../../assets/images/cardboxbg.png";
import Back from "../../assets/images/backbtn.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { crateProjectMonitoring, fetchIndicators } from "../../services/report";
import showNotification from "../../components/notification/notification";
import { handleKeyDown } from "../../helpers/helpers";
import { Button, Modal } from "react-bootstrap";

const AddProjectMonitoring = () => {
  const [indicator, setIndicator] = useState([]);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(1);

  const addProjectMonitoringFormik = useFormik({
    initialValues: {
      indicatorId: "",
      count: "",
      comments: "",
      regionId: JSON.parse(localStorage.getItem("data"))?.regionDetails[0]?._id,
    },
    onSubmit: async (values) => {
      const res = await crateProjectMonitoring(values);
      if (res.status) {
        // alert("Data Added successfully");
        navigate("/");
        showNotification(
          "Project Monitoring data added successfully!",
          " success"
        );
      } else {
        showNotification(`${res.message}`, "danger");
      }
    },
  });

  const getIndicators = async () => {
    const type = 6;
    const res = await fetchIndicators(type);
    if (res.status) {
      setIndicator(res.data);
    }
  };

  useEffect(() => {
    getIndicators();
  }, []);

  const goBack = () => {
    navigate(-1);
  };
  const addUserReset = () => {
    addProjectMonitoringFormik.handleReset();
    setShow(false);
    setOpacity(0);
  };
  const handleClose = () => {
    setShow(false);
    setOpacity(0);
  };
  const handleShow = () => {
    setShow(true);
    setOpacity(1);
  };
  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader">
          <h3>Add Project Monitoring</h3>
          <button onClick={goBack}>
            {" "}
            <img src={Back} alt="" /> Back
          </button>
        </div>
        <div className="cardbg">
          <img src={cardBoxImage} alt="card bg" />
        </div>
      </div>
      {/* -------------------Form---------------------- */}
      <form onSubmit={addProjectMonitoringFormik.handleSubmit}>
        <div className="politryStockForm">
          <div className="politryStock addForm">
            <div className="addFormPage">
              <div className="row1">
                <div className="row">
                  <div className="col-md-6">
                    <div className=" addFormRow  ">
                      <label>Indicator</label>
                      <select
                        name="indicatorId"
                        value={addProjectMonitoringFormik.values.indicatorId}
                        onChange={addProjectMonitoringFormik.handleChange}
                      >
                        <option value="">Select Indicator</option>
                        {indicator &&
                          indicator?.map((value) => {
                            return (
                              <option value={value._id}>{value.name}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="addFormRow">
                      <label>Count</label>
                      <div className="schoolUnit">
                        <input
                          required
                          name="count"
                          type="number"
                          placeholder="Enter Count"
                          value={addProjectMonitoringFormik.values.count}
                          onChange={addProjectMonitoringFormik.handleChange}
                          onKeyDown={handleKeyDown}
                          onWheel={(event) => event.currentTarget.blur()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row3">
                <div className="row">
                  <div className="col-md-6">
                    <div className="addFormRow">
                      <label> Comments </label>
                      <div className="schoolUnit">
                        <input
                          required
                          name="comments"
                          type="text"
                          placeholder="Enter Comment"
                          value={addProjectMonitoringFormik.values.comments}
                          onChange={addProjectMonitoringFormik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottomButtons">
          <button type="submit" className="addprdt active" id="addprdt">
            Add Project Monitoring
          </button>
          <button type="reset" className="reset" id="rst" onClick={handleShow}>
            {" "}
            Reset
          </button>
        </div>
      </form>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ opacity: opacity }}
      >
        <Modal.Header closeButton>
          <Modal.Title> Reset?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={addUserReset}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddProjectMonitoring;
