import { useState, React, useEffect } from "react";
import cardBoxImage from "../../assets/images/cardboxbg.png";
import Back from "../../assets/images/backbtn.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { crateSchoolProgram } from "../../services/production";
import showNotification from "../../components/notification/notification";
import { Button, Modal } from "react-bootstrap";

const AddSchoolProgram = () => {
  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const navigate = useNavigate();
  const addSchoolProgramFormik = useFormik({
    initialValues: {
      kitchenGarden: {
        crop: "",
        unit: "",
      },
      shadehouse: {
        crop: "",
        unit: "",
      },
      poultryRearing: {
        liveWeight: "",
        slaughterWeight: "",
      },
      regionId: JSON.parse(localStorage.getItem("data"))?.regionDetails[0]?._id,
    },
    onSubmit: async (values) => {
      const res = await crateSchoolProgram(values);
      if (res.status) {
        navigate("/");
        showNotification(
          " School Support Program data added successfully! ",
          "success"
        );
      } else {
        showNotification(`${res.message}`, "danger");
      }
    },
  });

  const goBack = () => {
    navigate(-1);
  };
  const addUserReset = () => {
    addSchoolProgramFormik.handleReset();
    setShow(false);
    setOpacity(0);
  };
  const handleClose = () => {
    setShow(false);
    setOpacity(0);
  };
  const handleShow = () => {
    setShow(true);
    setOpacity(1);
  };
  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader">
          <h3>Add School Agriculture Support Program</h3>
          <button onClick={goBack}>
            {" "}
            <img src={Back} alt="" /> Back
          </button>
        </div>
        <div className="cardbg">
          <img src={cardBoxImage} alt="card bg" />
        </div>
      </div>
      {/* -------------------Form---------------------- */}
      <form onSubmit={addSchoolProgramFormik.handleSubmit}>
        <div className="politryStockForm">
          <div className="politryStock addForm">
            <div className="addFormPage">
              <div className="row1 ">
                <div className="row">
                  <div className="col-md-3">
                    <div className="schoolLabel">
                      <label className="addschoolLabel">Kitchen Garden</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="inputAdd">
                      <label className="inputAddLabel">Crop</label>
                      <input
                        required
                        name="kitchenGarden.crop"
                        type="number"
                        placeholder="Enter  Quantity"
                        value={addSchoolProgramFormik.values.kitchenGarden.crop}
                        onChange={addSchoolProgramFormik.handleChange}
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="inputAdd">
                      <label className="inputAddLabel">Unit</label>
                      <input
                        required
                        name="kitchenGarden.unit"
                        type="number"
                        placeholder="Enter Unit"
                        value={addSchoolProgramFormik.values.kitchenGarden.unit}
                        onChange={addSchoolProgramFormik.handleChange}
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row2 addschoolRow">
                <div className="row">
                  <div className="col-md-3">
                    <div className="schoolLabel">
                      <label className="addschoolLabel"> Shadehouse </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="inputAdd">
                      <label className="inputAddLabel">Crop</label>
                      <input
                        required
                        name="shadehouse.crop"
                        type="number"
                        placeholder="Enter  Quantity"
                        value={addSchoolProgramFormik.values.shadehouse.crop}
                        onChange={addSchoolProgramFormik.handleChange}
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="inputAdd">
                      <label className="inputAddLabel">unit</label>
                      <input
                        required
                        name="shadehouse.unit"
                        type="number"
                        placeholder="Enter Unit"
                        value={addSchoolProgramFormik.values.shadehouse.unit}
                        onChange={addSchoolProgramFormik.handleChange}
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row3 addschoolRow">
                <div className="row">
                  <div className="col-md-3">
                    <div className="schoolLabel">
                      <label className="addschoolLabel">
                        {" "}
                        Poultry Rearing{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="inputAdd">
                      <label className="inputAddLabel">Live Weight</label>
                      <input
                        required
                        name="poultryRearing.liveWeight"
                        type="number"
                        placeholder="Enter Weight"
                        value={
                          addSchoolProgramFormik.values.poultryRearing
                            .liveWeight
                        }
                        onChange={addSchoolProgramFormik.handleChange}
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="inputAdd">
                      <label className="inputAddLabel">Slaughter Weight</label>
                      <input
                        required
                        name="poultryRearing.slaughterWeight"
                        type="number"
                        placeholder="Enter Weight"
                        value={
                          addSchoolProgramFormik.values.poultryRearing
                            .slaughterWeight
                        }
                        onChange={addSchoolProgramFormik.handleChange}
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottomButtons">
          <button type="submit" className="addprdt active" id="addprdt">
            Add School Agriculture Support Program
          </button>
          <button type="reset" className="reset" id="rst" onClick={handleShow}>
            {" "}
            Reset
          </button>
        </div>
      </form>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ opacity: opacity }}
      >
        <Modal.Header closeButton>
          <Modal.Title> Reset?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={addUserReset}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddSchoolProgram;
