import { requestWithToken } from "../helpers/helpers";
const fetchAnimals = async () => {
  try {
    const response = await requestWithToken("GET", "/animal/animals");

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createEggLog = async (payload) => {
  try {
    const response = await requestWithToken("POST", "/eggLog/eggLog", payload);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const eggLogPage = async (pageNo, pageSize, regionId, startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/eggLog/eggLog?pageNo=${pageNo}&pageSize=${pageSize}&regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/eggLog/eggLog?pageNo=${pageNo}&pageSize=${pageSize}&regionId=${regionId}&startDate=`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchEggLogCard = async (regionId, startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/eggLog/eggLogCard?regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/eggLog/eggLogCard?regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export { fetchAnimals, createEggLog, eggLogPage, fetchEggLogCard };
