import { useState, React, useEffect } from "react";
import cardBoxImage from "../../assets/images/cardboxbg.png";
import Back from "../../assets/images/backbtn.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { createLivestock, fetchMeatCategory } from "../../services/livestock";
import AddLiveStockForm from "../../components/liveStock/addLiveStockForm";
import showNotification from "../../components/notification/notification";
import { Button, Modal } from "react-bootstrap";

const AddLiveStockPage = () => {
  const navigate = useNavigate();
  const [menubuttons, setMenubuttons] = useState([]);
  const [selectedMenuButtons, setSelectedMenuButtons] = useState([]);
  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(1);

  const addLiveStockFormik = useFormik({
    initialValues: {
      livestockDetails: [],
    },
    onSubmit: async (values) => {
      if (values.livestockDetails.length) {
        const res = await createLivestock(values);
        if (res.status) {
          navigate("/");
          showNotification(
            "Livestock Slaughter data added successfully!",
            " success"
          );
        }
      } else {
        showNotification("Please select animal", "danger");
      }
    },
  });

  const regionId = JSON.parse(localStorage.getItem("data")).regionDetails[0]
    ._id;

  const getMeatCategory = async () => {
    const res = await fetchMeatCategory();
    if (res.status) {
      setMenubuttons(res.data);
    }
  };

  useEffect(() => {
    getMeatCategory();
  }, []);

  const onAnimalSelect = (button) => {
    console.log(button);
    const checkExist = selectedMenuButtons.some((x) => x === button._id);
    if (checkExist) {
      setSelectedMenuButtons(
        selectedMenuButtons.filter((value) => {
          return value !== button._id;
        })
      );
    } else {
      setSelectedMenuButtons([...selectedMenuButtons, button._id]);
    }

    let checkArrayExist = addLiveStockFormik.values.livestockDetails.some(
      (details) => details.liveStockId === button._id
    );
    if (checkArrayExist) {
      addLiveStockFormik.setFieldValue(
        "livestockDetails",
        addLiveStockFormik.values.livestockDetails.filter((value) => {
          return value.liveStockId !== button._id;
        })
      );
    } else {
      addLiveStockFormik.setFieldValue("livestockDetails", [
        ...addLiveStockFormik.values.livestockDetails,
        {
          regionId: regionId,
          liveStockId: button._id,
          animalTypeName: button.name,
          sLaughterCount: "",
          liveWeight: "",
          deathWeight: "",
          avgGatePrice: "",
          avgRetailPrice: "",
        },
      ]);
    }
  };

  const reset = () => {
    let tempArray = [...addLiveStockFormik.values.livestockDetails];
    setSelectedMenuButtons([]);
    tempArray.forEach((element) => {
      element.sLaughterCount = "";
      element.liveWeight = "";
      element.deathWeight = "";
      element.avgGatePrice = "";
      element.avgRetailPrice = "";
    });
    addLiveStockFormik.setFieldValue("livestockDetails", []);
  };

  const changeFormValue = (index, value, changeKey) => {
    let tempArray = [...addLiveStockFormik.values.livestockDetails];
    tempArray[index][`${changeKey}`] = value;
    addLiveStockFormik.setFieldValue("livestockDetails", tempArray);
  };
  const goBack = () => {
    navigate(-1);
  };
  const addUserReset = () => {
    reset();
    setShow(false);
    setOpacity(0);
  };
  const handleClose = () => {
    setShow(false);
    setOpacity(0);
  };
  const handleShow = () => {
    setShow(true);
    setOpacity(1);
  };
  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader">
          <h3>Add Livestock Slaughter</h3>
          <button onClick={goBack}>
            <img
              src={Back}
              alt=""
            />{" "}
            Back
          </button>
        </div>
        <div className="cardbg">
          <img
            src={cardBoxImage}
            alt="card bg"
          />
        </div>
      </div>
      {/* -------------------Form---------------------- */}
      <form onSubmit={addLiveStockFormik.handleSubmit}>
        <div className="politryStockForm">
          <div className="politryStock">
            <div className="AddprodRow1Egg">
              <div className="eggLogFarmer">
                <div className="farmersBox">
                  Animal Type
                  <div className="buttons">
                    {menubuttons &&
                      menubuttons[0]?.productCategories?.map(
                        (button, index) => {
                          return (
                            <button
                              key={index}
                              type="button"
                              className={`${
                                selectedMenuButtons?.some(
                                  (x) => x === button._id
                                )
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => onAnimalSelect(button)}>
                              {button.name}
                            </button>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>
            </div>
            {addLiveStockFormik?.values?.livestockDetails.map(
              (value, index) => {
                return (
                  <AddLiveStockForm
                    index={index}
                    formData={value}
                    changeFormValue={changeFormValue}
                  />
                );
              }
            )}
          </div>
          {addLiveStockFormik.values.livestockDetails.length ? (
            <div className="bottomButtons">
              <button
                type="submit"
                className="addprdt active"
                id="addprdt">
                Add Livestock Slaughter
              </button>
              <button
                type="reset"
                className="reset"
                id="rst"
                onClick={handleShow}>
                {" "}
                Reset
              </button>
            </div>
          ) : (
            <div className="select-animal-type">
              {" "}
              <p>Please Select Animal </p>
            </div>
          )}
        </div>
      </form>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ opacity: opacity }}>
        <Modal.Header closeButton>
          <Modal.Title> Reset?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}>
            No
          </Button>
          <Button
            variant="primary"
            onClick={addUserReset}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddLiveStockPage;
