import React, { useEffect, useState } from "react";
import { resetPassword } from "../../services/user";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import passvissIcon from "../../assets/images/passvissIcon.png";
import eyeslash from "../../assets/images/eyeslash.svg";
import loginlogo from "./../../assets/images/loginlogo.png";
import loginrightbg from "./../../assets/images/loginrightbg.png";
import Footer from "../footer";
import showNotification from "../notification/notification";

function ResetPassword() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  let email = localStorage.getItem("email");

  const loginFormik = useFormik({
    initialValues: {
      email: email,
      password: "",
      otp: "",
    },
    onSubmit: async (values) => {
      const res = await resetPassword(values);
      if (res.status) {
        navigate("/");
      } else {
        showNotification("Wrong OTP", "danger");
      }
    },
  });

  return (
    <form onSubmit={loginFormik.handleSubmit}>
      <div class="loginPage">
        <div class="loginPageLeft">
          <div class="loginlogo">
            <img src={loginlogo} alt="" />
          </div>
          <div class="loginleftText">
            <p>Welcome to Agro</p>
            <h2>Agro Management Application</h2>
          </div>
        </div>
        <div class="loginPageRight">
          <div class="loginRightContent">
            <h3 className="heading3">Reset Password</h3>
            {/* <div class="userName">
              <div class="loginUsrname">
                <label class="loginUsrnameLabel">Email</label>
                <input
                  required
                  name="email"
                  type="Email"
                  placeholder="Enter user email"
                  value={loginFormik.values.email}
                  onChange={loginFormik.handleChange}
                />
              </div>
            </div> */}
            <div class="loginUsrname otp">
              <label class="loginUsrnameLabel">OTP</label>
              <input
                required
                type="text"
                name="otp"
                placeholder="Enter OTP"
                value={loginFormik.values.otp}
                onChange={loginFormik.handleChange}
              />
            </div>
            <div class="loginUsrname password rstpassword">
              <label class="loginUsrnameLabel">Password</label>
              <input
                required
                type={showPassword ? "text" : "Password"}
                name="password"
                placeholder="Enter password"
                value={loginFormik.values.password}
                onChange={loginFormik.handleChange}
                edge="end"
              />

              <div
                class="vissIcon"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                edge="end"
              >
                <img src={showPassword ? eyeslash : passvissIcon} alt="" />
              </div>
            </div>

            <div class="loginbtn">
              <button className="forgotPasswordsubmitbtn" type="submit">
                Reset Password
              </button>
            </div>
            <div className="imgbg">
              <img className="loginRightbg" src={loginrightbg} alt="aa" />
            </div>
          </div>
          <Footer first="loginfooter" />
        </div>
      </div>
    </form>
  );
}

export default ResetPassword;
