import { React } from "react";
import { handleKeyDown } from "../../helpers/helpers";

const AddLiveStockForm = ({ index, formData, changeFormValue }) => {
  const onInputChange = (value, key) => {
    changeFormValue(index, value, key);
  };

  return (
    <div>
      <div className="prodctRow">
        <label className="prodctRowCol1">{`${formData.animalTypeName} Details`}</label>
        <div className="prodctRowinputBoxes">
          <div className="row">
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Slaughter Count</label>
                <input
                  required
                  type="number"
                  value={formData?.sLaughterCount}
                  name="small"
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    onInputChange(e.target.value, "sLaughterCount");
                  }}
                  placeholder="Enter Slaughter Count"
                  min={1}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Live Weight</label>
                <input
                  required
                  type="number"
                  value={formData?.liveWeight}
                  onKeyDown={handleKeyDown}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "liveWeight");
                  }}
                  placeholder="Enter Weight"
                  min={1}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Death Weight</label>
                <input
                  required
                  type="number"
                  value={formData?.deathWeight}
                  name="small"
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    onInputChange(e.target.value, "deathWeight");
                  }}
                  placeholder="Enter Weight"
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Gate Price</label>
                <input
                  required
                  type="number"
                  value={formData?.avgGatePrice}
                  name="small"
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    onInputChange(e.target.value, "avgGatePrice");
                  }}
                  placeholder="Enter Price"
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Retail Price</label>
                <input
                  required
                  type="number"
                  value={formData?.avgRetailPrice}
                  onKeyDown={handleKeyDown}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "avgRetailPrice");
                  }}
                  placeholder="Enter Price"
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLiveStockForm;
