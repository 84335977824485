import React from "react";
import success from "./../../assets/images/addSuccess.svg";

const CustomNotification = ({ message, type }) => {
  return (
    <>
      {type === "danger" ? (
        <div className=" addformsuccess check danger">
          <i className="fa-solid fa-circle-exclamation"></i> &nbsp; &nbsp;
          <span>{message}</span>
        </div>
      ) : (
        <div className="addformsuccess check">
          <img
            src={success}
            alt=" "
          />
          {message}
        </div>
      )}
    </>
  );
};

export default CustomNotification;
