import React, { useEffect, useState } from "react";
import { fetchLivestock } from "../../../services/livestock";
import { Box, Pagination } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import Shimmer from "../../shimmer";
import Notfound from "../../data-not-found";

const LivestockSloughterTable = () => {
  const zoneRefresh = useSelector((state) => state.updateZone.value);
  const [loading, setLoading] = useState(true);
  const [livestockList, setLivestockList] = useState([]);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const getLivestock = async (page) => {
    let regionId = localStorage.getItem("selectedZone");
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    const res = await fetchLivestock(
      page,
      pageSize,
      regionId,
      startDate,
      endDate
    );
    if (res.status) {
      setLoading(false);
      setTotalData(res.count);
      // console.log(res.count);
      setLivestockList([
        {
          header: res.header,
          row: res.row,
        },
      ]);
    }
  };
  useEffect(() => {
    getLivestock(page);
  }, [page, pageSize, zoneRefresh]);

  const handleChangePagination = async (event, value) => {
    setPage(value);
  };
  return loading ? (
    <Shimmer />
  ) : (
    <>
      <div className="homepgtable">
        <table className="table-bordered home-table">
          <thead>
            <tr>
              {livestockList &&
                livestockList[0]?.header?.map((value, index) => {
                  return (
                    <th
                      rowSpan="2"
                      className={index < 3 ? "meatinspheadLeft" : ""}>
                      {" "}
                      <strong>{value}</strong>
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {livestockList && livestockList[0]?.row?.length > 0 ? (
              <>
                {livestockList &&
                  livestockList[0]?.row?.map((value) => {
                    return (
                      <tr>
                        <td className="employeeName alignmd">
                          {value.userName}
                        </td>
                        <td className="dates alignmd">
                          {moment(value.Date).format("DD-MMM-yyyy")}
                        </td>
                        <td className="alignmdleft">{value.animalName}</td>
                        <td className="alignmd">{value.sLaughterCount}</td>
                        <td className="alignmd">{value.liveWeight}</td>
                        <td className="alignmd">{value.deathWeight}</td>
                        <td className="alignmd">{value.avgGatePrice}</td>
                        <td className="alignmd">{value.avgRetailPrice}</td>
                      </tr>
                    );
                  })}
              </>
            ) : (
              <div className="tablenotfound">
                <Notfound />
              </div>
            )}
          </tbody>
        </table>
      </div>
      {totalData > 10 ? (
        <div className="paginantionBox">
          <div>
            <Pagination
              page={page}
              count={Math.ceil(Number(totalData / pageSize))}
              variant="outlined"
              color="primary"
              onChange={handleChangePagination}
              showFirstButton
              showLastButton
            />
          </div>
          <div className="perPageBox">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                setPage(1);
              }}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div>
        </div>
      ) : (
        " "
      )}{" "}
    </>
  );
};

export default LivestockSloughterTable;
