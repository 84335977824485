import React, { useEffect, useState } from "react";
import { Box, Pagination } from "@mui/material";
import moment from "moment";
import { fetchPoultryLogList } from "../../../services/poultryLog";
import { useSelector } from "react-redux";
import Shimmer from "../../shimmer";
import Notfound from "../../data-not-found";

const PoultryLogTable = () => {
  const zoneRefresh = useSelector((state) => state.updateZone.value);
  const [loading, setLoading] = useState(true);
  const [poultryLogList, setPoultryLogList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const getPoultryLogList = async (page) => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const res = await fetchPoultryLogList(
      page,
      pageSize,
      regionId,
      startDate,
      endDate
    );
    if (res.status) {
      setLoading(false);
      setPoultryLogList([
        {
          header: res.header,
          row: res.row,
        },
      ]);
      setTotalData(res.count);
    }
  };

  useEffect(() => {
    getPoultryLogList(page);
  }, [page, pageSize, zoneRefresh]);

  const handleChangePagination = async (event, value) => {
    setPage(value);
  };

  return loading ? (
    <Shimmer />
  ) : (
    <>
      <div className="homepgtable poultrystockpageTable">
        <table className="table-bordered home-table">
          <thead>
            <tr>
              {poultryLogList &&
                poultryLogList[0]?.header?.map((value, index) => {
                  return (
                    <>
                      <th rowSpan="2">
                        {" "}
                        <strong>{value}</strong>
                      </th>
                    </>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {poultryLogList && poultryLogList[0]?.row?.length > 0 ? (
              <>
                {poultryLogList &&
                  poultryLogList[0]?.row?.map((value) => {
                    return (
                      <tr>
                        <td className="employeeName">{value.userName}</td>
                        <td className="employeeName">
                          {value.fullName === "" || value.fullName === " "
                            ? "-"
                            : value.fullName}
                        </td>
                        <td className="dates">
                          {moment(value.dob).format("DD-MMM-yyyy")}
                        </td>
                        <td className="animaltypes">
                          {value.breed === "" || value.breed === " "
                            ? "-"
                            : value.breed}
                        </td>
                        <td className="alignmdleft">
                          {value.gender === 1 ? "Male" : "Female"}
                        </td>
                        <td className="animaltypes">
                          {value.size === "" || value.size === " "
                            ? "-"
                            : value.size}
                        </td>
                        <td className="animaltypes">
                          {value.color === "" || value.color === " "
                            ? "-"
                            : value.color}
                        </td>
                        <td className="dates">
                          {moment(value.purchaseDate).format("DD-MMM-yyyy")}
                        </td>
                        <td className="dates">
                          {moment(value.eggLayingDate).format("DD-MMM-yyyy")}
                        </td>
                        <td className="dates">
                          {moment(value.saleDate).format("DD-MMM-yyyy")}
                        </td>
                        <td className="dates">
                          {moment(value.chickPurchaseDate).format(
                            "DD-MMM-yyyy"
                          )}
                        </td>
                        <td>
                          <div className="incomeNotes">
                            {value.notes == "" || value.notes == " "
                              ? "-"
                              : value.notes}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </>
            ) : (
              <div className="tablenotfound">
                <Notfound />
              </div>
            )}
          </tbody>
        </table>
      </div>
      {totalData > 10 ? (
        <div className="paginantionBox">
          <div>
            <Pagination
              page={page}
              count={Math.ceil(Number(totalData / pageSize))}
              variant="outlined"
              color="primary"
              onChange={handleChangePagination}
              showFirstButton
              showLastButton
            />
          </div>
          <div className="perPageBox">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                setPage(1);
              }}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div>
        </div>
      ) : (
        " "
      )}{" "}
    </>
  );
};

export default PoultryLogTable;
