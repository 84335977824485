import React, { useEffect, useState } from "react";
import cardboxbg from "../../assets/images/cardboxbg.png";
import { Box, Pagination } from "@mui/material";
import moment from "moment";
import { fetchDippingList } from "../../services/dipping";
import { useNavigate } from "react-router-dom";

const DippingAndVaccinationList = () => {
  const [livestockList, setLivestockList] = useState([]);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);

  const getDippingList = async (page) => {
    const res = await fetchDippingList(page);
    if (res.status) {
      setLivestockList([
        {
          header: res.header,
          row: res.row,
        },
      ]);
      getNoOfPage(res);
    }
  };

  useEffect(() => {
    getDippingList(page);
  }, [page]);

  const itemsPerPage = 10;

  const getNoOfPage = (data) => {
    const res = Math.ceil(data.count / itemsPerPage);

    setNoOfPages(res);
  };

  const handleChangePagination = async (event, value) => {
    setPage(value);
  };
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return livestockList.length ? (
    <div className="theme-container">
      <div className="contentCard2 tablePage-cntnt">
        <div className="contentCardHeader addProdctHeader">
          <h3>Dipping, Spraying & Vaccination</h3>
          <button onClick={goBack}>&larr; BACK</button>
        </div>
        <div className="cardbg">
          <img src={cardboxbg} alt="card bg" />
        </div>
      </div>

      <section className="bottom">
        <div className="themeCard ">
          <div className="CardContainer">
            <header className="cardHeader">
              <h5>Latest updates</h5>
              <div className="headerbtn">
                <button>view more &#8594;</button>
              </div>
            </header>
            <div>
              <table className="table-bordered home-table">
                <thead>
                  <tr>
                    {livestockList &&
                      livestockList[0]?.header?.map((value) => {
                        return (
                          <td rowSpan="2" className="thd epmlName alignmd">
                            {" "}
                            <strong>{value}</strong>
                          </td>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {livestockList &&
                    livestockList[0]?.row?.map((value) => {
                      return (
                        <tr>
                          <td className="employeeName">{value.userName}</td>
                          <td className="dates">
                            {moment(value.date).format("DD-MM-yyyy")}
                          </td>
                          <td>{value.animalName}</td>
                          <td>{value.dippedCount}</td>
                          <td>{value.medicineApplied}</td>
                          <td>{value.sprayCount}</td>
                          <td>{value.vaccinatedCount}</td>
                          <td>{value.vaccinationCount}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              <Box
                justifyContent={"center"}
                alignItems="center"
                display={"flex"}
                sx={{ margin: "20px 0px" }}
              >
                {/* <Typography>{page}</Typography> */}
                <Pagination
                  page={page}
                  count={noOfPages}
                  variant="outlined"
                  color="primary"
                  onChange={handleChangePagination}
                  showFirstButton
                  showLastButton
                />
              </Box>
            </div>
          </div>
        </div>
      </section>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default DippingAndVaccinationList;
