import React from "react";
import HeaderMenu from "./headerMenu";
import Footer from "./footer";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({Component}) => {
 
  const isAuth = localStorage.getItem("auth-token");

  return isAuth ? (
    <div>
      <HeaderMenu />
      <Component />
      <Footer />

    </div>
  ):(
    <Navigate to="/login" />
  )
};

export default ProtectedRoute;
