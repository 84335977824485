import { requestWithToken } from "../helpers/helpers";

const fetchMeatCategory = async () => {
  try {
    const response = await requestWithToken(
      "GET",
      "/productType/meatCategories"
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createLivestockService = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      "/livestockService",
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchLivestockServiceCard = async (regionId, startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/livestockService/livestockServiceCard?regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/livestockService/livestockServiceCard?regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchLivestockServiceList = async (
  pageNo,
  pageSize,
  regionId,
  startDate,
  endDate
) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/livestockService/livestockServiceList?pageNo=${pageNo}&pageSize=${pageSize}&regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/livestockService/livestockServiceList?pageNo=${pageNo}&pageSize=${pageSize}&regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  fetchMeatCategory,
  createLivestockService,
  fetchLivestockServiceCard,
  fetchLivestockServiceList,
};
