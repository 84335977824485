import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchMeatCategory } from "../../services/livestock";
import cardBoxImage from "../../assets/images/cardboxbg.png";
import Back from "../../assets/images/backbtn.svg";
import AddMeatInspectionForm from "../../components/meatInspection/meatInspectionForm";
import { createMeatInspection } from "../../services/meatInspection";
import showNotification from "../../components/notification/notification";
import { Button, Modal } from "react-bootstrap";

const AddMeatInspection = () => {
  const navigate = useNavigate();
  const [menubuttons, setMenubuttons] = useState([]);
  const [selectedMenuButtons, setSelectedMenuButtons] = useState([]);
  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(1);

  const addLiveStockHealthFormik = useFormik({
    initialValues: {
      meatInspectionDetails: [],
    },
    onSubmit: async (values) => {
      if (values.meatInspectionDetails.length) {
        const res = await createMeatInspection(values);
        if (res.status) {
          // alert("Log created successfully");
          navigate("/");
          showNotification(
            "Meat Inspection data added successfully!",
            " success"
          );
        }
      } else {
        showNotification("Please select animal", "danger");
      }
    },
  });

  const getMeatCategory = async () => {
    const res = await fetchMeatCategory();
    if (res.status) {
      setMenubuttons(res.data);
    }
  };

  useEffect(() => {
    getMeatCategory();
  }, []);

  const onAnimalSelect = (button) => {
    console.log(button);
    const checkExist = selectedMenuButtons.some((x) => x === button._id);
    if (checkExist) {
      setSelectedMenuButtons(
        selectedMenuButtons.filter((value) => {
          return value !== button._id;
        })
      );
    } else {
      setSelectedMenuButtons([...selectedMenuButtons, button._id]);
    }
    const regionId = JSON.parse(localStorage.getItem("data")).regionDetails[0]
      ._id;
    let checkArrayExist =
      addLiveStockHealthFormik.values.meatInspectionDetails.some(
        (details) => details.liveStockId === button._id
      );
    if (checkArrayExist) {
      addLiveStockHealthFormik.setFieldValue(
        "meatInspectionDetails",
        addLiveStockHealthFormik.values.meatInspectionDetails.filter(
          (value) => {
            return value.liveStockId !== button._id;
          }
        )
      );
    } else {
      addLiveStockHealthFormik.setFieldValue("meatInspectionDetails", [
        ...addLiveStockHealthFormik.values.meatInspectionDetails,
        {
          regionId: regionId,
          liveStockId: button._id,
          animalTypeName: button.name,
          slaughterPlace: "",
          animalAffected: "",
          animalAge: "",
          sex: "",
          anteMortemFindings: "",
          postMortemFindings: "",
          comments: "",
        },
      ]);
    }
  };

  const reset = () => {
    let tempArray = [...addLiveStockHealthFormik.values.meatInspectionDetails];
    setSelectedMenuButtons([]);
    tempArray.forEach((element) => {
      element.slaughterPlace = "";
      element.animalAffected = "";
      element.animalAge = "";
      element.sex = "";
      element.anteMortemFindings = "";
      element.postMortemFindings = "";
      element.comments = "";
    });
    addLiveStockHealthFormik.setFieldValue("meatInspectionDetails", []);
  };

  const changeFormValue = (index, value, changeKey) => {
    let tempArray = [...addLiveStockHealthFormik.values.meatInspectionDetails];
    tempArray[index][`${changeKey}`] = value;
    addLiveStockHealthFormik.setFieldValue("meatInspectionDetails", tempArray);
  };
  const goBack = () => {
    navigate(-1);
  };
  const addUserReset = () => {
    reset();
    setShow(false);
    setOpacity(0);
  };
  const handleClose = () => {
    setShow(false);
    setOpacity(0);
  };
  const handleShow = () => {
    setShow(true);
    setOpacity(1);
  };
  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader">
          <h3>Add Meat Inspection</h3>
          <button onClick={goBack}>
            {" "}
            <img
              src={Back}
              alt=""
            />{" "}
            Back
          </button>
        </div>
        <div className="cardbg">
          <img
            src={cardBoxImage}
            alt="card bg"
          />
        </div>
      </div>
      {/* -------------------Form---------------------- */}
      <form onSubmit={addLiveStockHealthFormik.handleSubmit}>
        <div className="politryStockForm">
          <div className="politryStock">
            <div className="AddprodRow1Egg">
              <div className="eggLogFarmer">
                <div className="farmersBox">
                  Animal Type
                  <div className="buttons">
                    {menubuttons &&
                      menubuttons[0]?.productCategories?.map(
                        (button, index) => {
                          return (
                            <button
                              key={index}
                              type="button"
                              className={`${
                                selectedMenuButtons?.some(
                                  (x) => x === button._id
                                )
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => onAnimalSelect(button)}>
                              {button.name}
                            </button>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>
            </div>
            {addLiveStockHealthFormik?.values?.meatInspectionDetails.map(
              (value, index) => {
                return (
                  <AddMeatInspectionForm
                    index={index}
                    formData={value}
                    changeFormValue={changeFormValue}
                  />
                );
              }
            )}
          </div>
          {addLiveStockHealthFormik.values.meatInspectionDetails.length ? (
            <div className="bottomButtons">
              <button
                type="submit"
                className="addprdt active"
                id="addprdt">
                Add Meat Inspection
              </button>
              <button
                type="reset"
                className="reset"
                id="rst"
                onClick={handleShow}>
                {" "}
                Reset
              </button>
            </div>
          ) : (
            <div className="select-animal-type">
              {" "}
              <p>Please Select Animal </p>
            </div>
          )}
        </div>
      </form>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ opacity: opacity }}>
        <Modal.Header closeButton>
          <Modal.Title> Reset?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}>
            No
          </Button>
          <Button
            variant="primary"
            onClick={addUserReset}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddMeatInspection;
