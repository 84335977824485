import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { liveStockChart } from "../../services/Chart";
import Shimmer from "../shimmer";
import Notfound from "../data-not-found";
import { useSelector } from "react-redux";
import { res } from "react-email-validator";

const LiveStockChart = ({ chartRef3 }) => {
  const zoneRefresh = useSelector((state) => state.updateZone.value);
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [loader, setLoader] = useState(true);

  const mapSeriesData = async () => {
    const seriesTemp = [];
    const labelsTemp = [];
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const response = await liveStockChart(regionId, startDate, endDate);
    if (response.status) {
      setLoader(false);

      response?.data?.forEach((element) => {
        if (element !== null) {
          seriesTemp.push(element?.totalAmount);
        }
      });

      response?.data?.forEach((element) => {
        if (element !== null) {
          labelsTemp.push(element?.livestockDetails.name);
        }
      });
      setLabels(labelsTemp);
      setSeries(seriesTemp);
    }
  };

  useEffect(() => {
    mapSeriesData();
  }, [zoneRefresh]);

  const chartOptions = {
    series: [],
    options: {
      stroke: {
        width: 0,
      },
      colors: [
        "#03A596",
        "#03BF9E",
        "#BF665E",
        "#F17456",
        "#D5AD28",
        "#263658",
      ],
      plotOptions: {
        pie: {
          donut: {
            size: "60%",
          },
        },
      },
      labels: labels,
      legend: {
        position: "bottom",
      },
      chart: {
        type: "donut",
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            customIcons: [],
          },
          export: {
            csv: {
              filename: "LivestockChart.csv",
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: "LivestockChart.svg",
            },
            png: {
              filename: "LivestockChart.png",
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
    },
  };

  return (
    <>
      {loader ? (
        <Shimmer />
      ) : (
        <div id="chart">
          {series.length > 0 ? (
            <div>
              <ReactApexChart
                options={chartOptions.options}
                series={series}
                type="donut"
              />
            </div>
          ) : (
            <Notfound />
          )}
        </div>
      )}
    </>
  );
};
export default LiveStockChart;
