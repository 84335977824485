import { Box, Pagination } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { eggLogPage } from "../services/addEgg";
import cardboxbg from "./../assets/images/cardboxbg.png";
import Back from "./../assets/images/backbtn.svg";
import { useNavigate } from "react-router-dom";
import Shimmer from "../components/shimmer";
import { convertToCsv, numberFormat } from "../helpers/helpers";
import { useSelector } from "react-redux";
import Notfound from "../components/data-not-found";
import { downloadCsv } from "../services/user";
import CSV from "../assets/images/csv.svg";
import Pdf from "../assets/images/pdf.svg";
var fileDownload = require("js-file-download");

const EggTablepage = () => {
  const zoneRefresh = useSelector((state) => state.updateZone.value);
  const [loading, setLoading] = useState(true);
  const [record, setRecord] = useState({});
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [colorCount, setColorCount] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState(0);

  const getEggRecords = async (page) => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const res = await eggLogPage(page, pageSize, regionId, startDate, endDate);
    if (res.status) {
      setLoading(false);
      setRecord(res.data);
      setColorCount(res.data.colorCount + 1);
      setDataCount(res.data.count);
      setTotalData(res.data.count);
    }
  };

  const convertToCsv = async (downloadType) => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    downloadCsv(4, downloadType, regionId, startDate, endDate).then((res) => {
      if (res) {
        if (downloadType == 1) {
          createCsv(res);
        } else {
          fileDownload(res, "file.pdf");
        }
      }
    });
  };
  const createCsv = (res, downloadType) => {
    // Create a Blob with the CSV content
    const blob = new Blob([res], { type: "text/csv;charset=utf-8;" });
    // Create a temporary URL for the Blob
    const csvUrl = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const downloadLink = document.createElement("a");
    downloadLink.href = csvUrl;
    downloadLink.download = "file.csv";
    // Simulate a click on the link to trigger the download
    downloadLink.click();

    // Clean up by revoking the URL
    window.URL.revokeObjectURL(csvUrl);
  };

  useEffect(() => {
    getEggRecords(page);
  }, [zoneRefresh, pageSize, page]);

  const handleChangePagination = async (event, value) => {
    setPage(value);
  };
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div>
      <div className="theme-container">
        <div className="contentCard2  ">
          <div className="contentCardHeader addProdctHeader tablePageHeader">
            <h3>Egg Log</h3>
            <div className="tablePageButtons">
              <button onClick={goBack}>
                {" "}
                <img src={Back} alt="" /> Back
              </button>
              <div className="downloadBtns">
                <button
                  className={
                    totalData === 0
                      ? "fadeDownload downloadtblbtn"
                      : "downloadtblbtn"
                  }
                  onClick={() => {
                    convertToCsv(1);
                  }}
                  type="button"
                >
                  Download CSV
                  <img className="dwlnldIcon" src={CSV} alt="" />
                </button>
                <button
                  className={
                    totalData === 0
                      ? "fadeDownload downloadtblbtn"
                      : "downloadtblbtn"
                  }
                  onClick={() => {
                    convertToCsv(2);
                  }}
                  type="button"
                >
                  Download PDF
                  <img className="dwlnldIcon" src={Pdf} alt="" />
                </button>
              </div>
            </div>
          </div>
          <div className="cardbg">
            <img src={cardboxbg} alt="card bg" />
          </div>
        </div>
        <div className="politryStockForm politryStockFormtbl">
          <section className="bottom tablePagesection">
            <div className="themeCard tableThemeCard ">
              <div className="CardContainer">
                <div className="tableBlockPage">
                  {loading ? (
                    <Shimmer />
                  ) : (
                    <>
                      <table className="table-bordered home-table  ">
                        <thead>
                          <tr>
                            <th className="incomereportTableHead" colSpan="18">
                              Farming Districts
                            </th>
                          </tr>
                          <tr>
                            {record &&
                              record?.header?.map((value, index) => {
                                if (
                                  value == "Small" ||
                                  value == "Medium" ||
                                  value == "Large" ||
                                  value == "XtraLarge"
                                ) {
                                  return (
                                    <th colSpan="3" class=" thd xlLarge">
                                      <strong>{value + " Eggs"}</strong>
                                    </th>
                                  );
                                } else {
                                  if (index < 3) {
                                    return (
                                      <th rowSpan="2" class="thd alignmdleft">
                                        <strong>{value}</strong>
                                      </th>
                                    );
                                  } else if (index > 13) {
                                    return (
                                      <th rowSpan="2" class="thd alignmd">
                                        <strong>{value}</strong>
                                      </th>
                                    );
                                  }
                                }
                              })}
                          </tr>
                          <tr class="homepagetbl">
                            {record &&
                              record?.header
                                ?.slice(3, colorCount * 4 + 3)
                                ?.map((value, index) => {
                                  return <th className="eggMiddle">{value}</th>;
                                })}
                          </tr>
                        </thead>
                        <tbody>
                          {record?.eggLogDetails?.length > 0 ? (
                            <>
                              {record &&
                                record?.eggLogDetails?.map((value) => {
                                  return (
                                    <tr>
                                      <td className="employeeName">
                                        {value.userName}
                                      </td>
                                      <td className="dates">
                                        {moment(value.date).format(
                                          "DD-MMM-yyyy"
                                        )}
                                      </td>
                                      <td className="EggType">
                                        {value.animalName}
                                      </td>
                                      {value.sizeArray.map((data, index) => {
                                        return (
                                          <td
                                            className={
                                              index < 12 ? " " : "eggTotal"
                                            }
                                          >
                                            {data.count > 0
                                              ? numberFormat(data.count)
                                              : "-"}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                  );
                                })}
                            </>
                          ) : (
                            <div className="tablenotfound">
                              <Notfound />
                            </div>
                          )}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
                {totalData > 10 ? (
                  <div className="paginantionBox">
                    <div>
                      <Pagination
                        page={page}
                        count={Math.ceil(Number(totalData / pageSize))}
                        variant="outlined"
                        color="primary"
                        onChange={handleChangePagination}
                        showFirstButton
                        showLastButton
                      />
                    </div>
                    <div className="perPageBox">
                      <select
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(Number(e.target.value));
                          setPage(1);
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                      </select>
                    </div>
                  </div>
                ) : (
                  " "
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EggTablepage;
