import React, { useEffect, useState } from "react";
import { fetchLivestock } from "../services/livestock";
import { Box, Pagination } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import cardboxbg from "../assets/images/cardboxbg.png";
import Back from "../assets/images/backbtn.svg";
import CSV from "../assets/images/csv.svg";
import Pdf from "../assets/images/pdf.svg";
import { useSelector } from "react-redux";
import Shimmer from "../components/shimmer";
import Notfound from "../components/data-not-found";
import { convertToCsv } from "../helpers/helpers";
import { downloadCsv } from "../services/user";
var fileDownload = require("js-file-download");

const LivestockSloughterTable = () => {
  const zoneRefresh = useSelector((state) => state.updateZone.value);
  const [loading, setLoading] = useState(true);
  const [livestockList, setLivestockList] = useState([]);
  const [page, setPage] = useState(1);
  const [csvData, setCsvData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState(0);

  const getLivestock = async (page) => {
    let regionId = localStorage.getItem("selectedZone");
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    const res = await fetchLivestock(
      page,
      pageSize,
      regionId,
      startDate,
      endDate
    );
    if (res.status) {
      setLoading(false);
      setTotalData(res.count);
      // console.log(res.count);
      setLivestockList([
        {
          header: res.header,
          row: res.row,
        },
      ]);
    }
  };

  const convertToCsv = async (downloadType) => {
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    downloadCsv(2, downloadType, regionId, startDate, endDate).then((res) => {
      if (res) {
        if (downloadType == 1) {
          createCsv(res);
        } else {
          fileDownload(res, "file.pdf");
        }
      }
    });
  };
  const createCsv = (res, downloadType) => {
    const blob = new Blob([res], { type: "text/csv;charset=utf-8;" });
    // Create a temporary URL for the Blob
    const csvUrl = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const downloadLink = document.createElement("a");
    downloadLink.href = csvUrl;
    downloadLink.download = "file.csv";
    // Simulate a click on the link to trigger the download
    downloadLink.click();

    // Clean up by revoking the URL
    window.URL.revokeObjectURL(csvUrl);
  };

  useEffect(() => {
    getLivestock(page);
  }, [page, pageSize, zoneRefresh]);

  const handleChangePagination = async (event, value) => {
    setPage(value);
  };
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div>
      <div className="theme-container">
        <div className="contentCard2  ">
          <div className="contentCardHeader addProdctHeader tablePageHeader">
            <h3>Livestock Slaughtered</h3>

            <div className="tablePageButtons">
              <button onClick={goBack}>
                {" "}
                <img
                  src={Back}
                  alt=""
                />{" "}
                Back
              </button>
              <div className="downloadBtns">
                <button
                  className={
                    totalData === 0
                      ? "fadeDownload downloadtblbtn"
                      : "downloadtblbtn"
                  }
                  onClick={() => {
                    convertToCsv(1);
                  }}
                  type="button">
                  Download CSV
                  <img
                    className="dwlnldIcon"
                    src={CSV}
                    alt=""
                  />
                </button>
                <button
                  className={
                    totalData === 0
                      ? "fadeDownload downloadtblbtn"
                      : "downloadtblbtn"
                  }
                  onClick={() => {
                    convertToCsv(2);
                  }}
                  type="button">
                  Download PDF
                  <img
                    className="dwlnldIcon"
                    src={Pdf}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="cardbg">
            <img
              src={cardboxbg}
              alt="card bg"
            />
          </div>
        </div>
        <div className="politryStockForm politryStockFormtbl">
          <section className="bottom tablePagesection">
            <div className="themeCard tableThemeCard">
              <div className="CardContainer">
                <div className="tableBlockPage">
                  {loading ? (
                    <Shimmer />
                  ) : (
                    <table className="table-bordered home-table">
                      <thead>
                        <tr>
                          <th
                            className="incomereportTableHead"
                            colSpan="8">
                            Farming Districts
                          </th>
                        </tr>
                        <tr>
                          {livestockList &&
                            livestockList[0]?.header?.map((value, index) => {
                              return (
                                <th
                                  rowSpan="2"
                                  className={
                                    index < 3 ? "meatinspheadLeft" : ""
                                  }>
                                  {" "}
                                  <strong>{value}</strong>
                                </th>
                              );
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        {livestockList && livestockList[0]?.row?.length > 0 ? (
                          <>
                            {livestockList &&
                              livestockList[0]?.row?.map((value) => {
                                return (
                                  <tr>
                                    <td className="employeeName alignmd">
                                      {value.userName}
                                    </td>
                                    <td className="dates alignmd">
                                      {moment(value.Date).format("DD-MMM-yyyy")}
                                    </td>
                                    <td className="alignmdleft">
                                      {value.animalName}
                                    </td>
                                    <td className="alignmd">
                                      {value.sLaughterCount}
                                    </td>
                                    <td className="alignmd">
                                      {value.liveWeight}
                                    </td>
                                    <td className="alignmd">
                                      {value.deathWeight}
                                    </td>
                                    <td className="alignmd">
                                      {value.avgGatePrice}
                                    </td>
                                    <td className="alignmd">
                                      {value.avgRetailPrice}
                                    </td>
                                  </tr>
                                );
                              })}
                          </>
                        ) : (
                          <div className="tablenotfound">
                            <Notfound />
                          </div>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>

                {totalData > 10 ? (
                  <div className="paginantionBox">
                    <div>
                      <Pagination
                        page={page}
                        count={Math.ceil(Number(totalData / pageSize))}
                        variant="outlined"
                        color="primary"
                        onChange={handleChangePagination}
                        showFirstButton
                        showLastButton
                      />
                    </div>
                    <div className="perPageBox">
                      <select
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(Number(e.target.value));
                          setPage(1);
                        }}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                      </select>
                    </div>
                  </div>
                ) : (
                  " "
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default LivestockSloughterTable;
