import React, { useState } from "react";
import cardBoxImage from "../../assets/images/cardboxbg.png";
import Back from "../../assets/images/backbtn.svg";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import moment from "moment";
import { createIncome } from "../../services/income";
import showNotification from "../../components/notification/notification";
import { handleKeyDown } from "../../helpers/helpers";
import { Button, Modal } from "react-bootstrap";
const AddIncome = () => {
  const navigate = useNavigate();
  const [emailEror, setEmailEror] = useState(false);
  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(1);

  const addIncomeFormik = useFormik({
    initialValues: {
      date: "",
      amount: "",
      amountPurchased: "",
      regionId: JSON.parse(localStorage.getItem("data"))?.regionDetails[0]?._id,
      customerName: "",
      customerEmail: "",
      customerMobile: "",
      transactionType: "",
      amountDescription: "",
      notes: "",
    },
    onSubmit: async (values) => {
      let email = values.customerEmail;
      let emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      const isValidEmail = emailPattern.test(email);

      if (!isValidEmail) {
        showNotification("Please enter correct email", "danger");
        setEmailEror(true);
      } else {
        const res = await createIncome(values);
        if (res.status) {
          // alert("Income created successfully");
          navigate("/");
          showNotification("Income data added successfully!", " success");
        } else {
          showNotification(`${res.message}`, "danger");
        }
      }
    },
  });

  const goBack = () => {
    navigate(-1);
  };
  const addUserReset = () => {
    addIncomeFormik.handleReset();
    setShow(false);
    setOpacity(0);
  };
  const handleClose = () => {
    setShow(false);
    setOpacity(0);
  };
  const handleShow = () => {
    setShow(true);
    setOpacity(1);
  };

  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader">
          <h3>Add Income</h3>
          <button onClick={goBack}>
            <img src={Back} alt="" /> Back
          </button>
        </div>
        <div className="cardbg">
          <img src={cardBoxImage} alt="card bg" />
        </div>
      </div>{" "}
      <form onSubmit={addIncomeFormik.handleSubmit}>
        <div className="politryStockForm">
          <div className="politryStock addForm">
            <div className="addFormPage">
              <div className="row1">
                <div className="row">
                  <div className="col-md-3">
                    <div className=" addFormRow">
                      <label>Sale Date</label>
                      <input
                        required
                        name="date"
                        type="date"
                        placeholder="Select Date"
                        value={addIncomeFormik.values.date}
                        max={moment(new Date()).format("YYYY-MM-DD")}
                        onChange={addIncomeFormik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="addFormRow">
                      <label> Amount Received</label>
                      <input
                        required
                        name="amount"
                        type="number"
                        placeholder="Enter Amount"
                        value={addIncomeFormik.values.amount}
                        onChange={addIncomeFormik.handleChange}
                        onKeyDown={handleKeyDown}
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="addFormRow">
                      <label> Amount Sold</label>
                      <input
                        required
                        name="amountPurchased"
                        type="number"
                        placeholder="Enter Amount"
                        value={addIncomeFormik.values.amountPurchased}
                        onChange={addIncomeFormik.handleChange}
                        onKeyDown={handleKeyDown}
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="addFormRow">
                      <label> Type</label>
                      <select
                        value={addIncomeFormik.values.transactionType}
                        onChange={(e) => {
                          addIncomeFormik.setFieldValue(
                            "transactionType",
                            Number(e.target.value)
                          );
                        }}
                        required
                      >
                        <option value="">Select Type</option>
                        <option value={1}>Cash</option>
                        <option value={2}>MMG+</option>
                        <option value={3}>Net Banking</option>
                        <option value={4}>Cheque</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row2">
                <div className="row">
                  <div className="col-md-6">
                    <div className="addFormRow">
                      <label>Amount Description</label>
                      <input
                        name="amountDescription"
                        type="text"
                        placeholder="Enter Amount Description"
                        value={addIncomeFormik.values.amountDescription}
                        onChange={addIncomeFormik.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="addFormRow">
                      <label>Customer Name</label>
                      <input
                        name="customerName"
                        type="text"
                        placeholder="Enter Customer Name"
                        // value={addIncomeFormik.values.customerName}
                        onChange={addIncomeFormik.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className={
                        emailEror
                          ? "addFormRow incomeformEmailEror"
                          : " addFormRow "
                      }
                    >
                      <label>Customer Email</label>
                      <input
                        name="customerEmail"
                        type="email"
                        placeholder="Enter Customer Email"
                        value={addIncomeFormik.values.customerEmail}
                        onChange={addIncomeFormik.handleChange}
                        required
                      />{" "}
                      <p className="adduserErorText">Enter correct email</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row3">
                <div className="row">
                  <div className="col-md-3">
                    <div className=" addFormRow">
                      <label>Customer Contact</label>
                      <input
                        name="customerMobile"
                        type="text"
                        placeholder="Enter Customer Contact"
                        value={addIncomeFormik.values.customerMobile}
                        onChange={addIncomeFormik.handleChange}
                        maxLength={10}
                        minLength={10}
                        required
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="addFormRow">
                      <label>Notes</label>
                      <input
                        name="notes"
                        type="text"
                        placeholder="Enter Notes "
                        // value={addIncomeFormik.values.notes}
                        onChange={addIncomeFormik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottomButtons">
            <button type="submit" className="addprdt active" id="addprdt">
              Add INCOME
            </button>
            <button
              type="reset"
              className="reset"
              id="rst"
              onClick={handleShow}
            >
              {" "}
              Reset
            </button>
          </div>
        </div>
      </form>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ opacity: opacity }}
      >
        <Modal.Header closeButton>
          <Modal.Title> Reset?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={addUserReset}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddIncome;
