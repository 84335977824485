import { requestWithToken } from "../helpers/helpers";

const fetchIndicators = async (type) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/indicators?type=${type}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const crateProjectMonitoring = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      "/report/projectMonitoring",
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const crateMarketing = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      "/report/marketing",
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const crateDranageIrigation = async (payload) => {
  try {
    const response = await requestWithToken("POST", "/report/dranage", payload);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const crateAgroService = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      "/report/agricultureService",
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const crateClimateResillence = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      "/report/climateResillence",
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const crateFarmMarketAccess = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      "/report/marketAccess",
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const crateFoodSecurity = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      "/report/foodSecurity",
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchDranageList = async (
  page,
  pageSize,
  regionId,
  startDate,
  endDate
) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/report/dranageList?pageNo=${page}&pageSize=${pageSize}&regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/report/dranageList?pageNo=${page}&pageSize=${pageSize}&regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response;
  }
};

const fetchMarketAccessList = async (page, regionId, startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/report/marketAccessList?pageNo=${page}&pageSize=10&regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/report/marketAccessList?pageNo=${page}&pageSize=10&regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchClimateResillenceList = async (
  page,
  regionId,
  startDate,
  endDate
) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/report/climateResillenceList?pageNo=${page}&pageSize=10&regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/report/climateResillenceList?pageNo=${page}&pageSize=10&regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAgroServiceList = async (page, regionId, startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/report/agricultureServiceList?pageNo=${page}&pageSize=10&regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/report/agricultureServiceList?pageNo=${page}&pageSize=10&regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchMarketingList = async (page, regionId, startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/report/marketingList?pageNo=${page}&pageSize=10&regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/report/marketingList?pageNo=${page}&pageSize=10&regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchProjectMonitoring = async (page, regionId, startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/report/projectMonitoringList?pageNo=${page}&pageSize=10&regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/report/projectMonitoringList?pageNo=${page}&pageSize=10&regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  fetchIndicators,
  crateProjectMonitoring,
  crateMarketing,
  crateDranageIrigation,
  crateAgroService,
  crateClimateResillence,
  crateFoodSecurity,
  crateFarmMarketAccess,
  fetchDranageList,
  fetchMarketAccessList,
  fetchClimateResillenceList,
  fetchAgroServiceList,
  fetchMarketingList,
  fetchProjectMonitoring,
};
