import React, { useEffect, useState } from "react";
import { forgetPassword } from "../../services/user";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
// import loginlogo from "../assets/images/cardboxbg.png";
import loginlogo from "./../../assets/images/loginlogo.png";
import loginrightbg from "./../../assets/images/loginrightbg.png";
import Back from "./../../assets/images/backbtn.svg";
import Footer from "../footer";
import showNotification from "../notification/notification";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(1);
  const [message, setMessage] = useState("");
  const [eror, setEror] = useState(false);

  const navigate = useNavigate();

  // console.log(email);
  const loginFormik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      localStorage.setItem("email", values.email);
      const res = await forgetPassword(values);
      if (res.status) {
        navigate("/resetPassword");
      } else {
        showNotification("Please enter correct user id ", "danger");
        setEror(true);
      }
    },
  });

  return (
    <form onSubmit={loginFormik.handleSubmit}>
      <div class="loginPage">
        <div class="loginPageLeft">
          <div class="loginlogo">
            <img src={loginlogo} alt="" />
          </div>
          <div class="loginleftText">
            <p>Welcome to Agro</p>
            <h2>Agro Management Application</h2>
          </div>
        </div>
        <div class="loginPageRight">
          <div class="loginRightContent">
            <h3 className="heading3">Forgot Password</h3>

            <div class={eror ? "userName eror" : "userName"}>
              <div class="loginUsrname">
                <label class="loginUsrnameLabel">User Email</label>
                <input
                  required
                  name="email"
                  type="Email"
                  placeholder="Enter user email"
                  value={loginFormik.values.email}
                  onChange={loginFormik.handleChange}
                />
              </div>
              <p class="emaileror">Enter correct email id</p>
            </div>
            <div class="loginbtn">
              <button className="forgotPasswordsubmitbtn" type="submit">
                Submit
              </button>
            </div>
            <div className="imgbg">
              <img className="loginRightbg" src={loginrightbg} alt="aa" />
            </div>
          </div>
          <Footer first="loginfooter" />
        </div>
      </div>
    </form>
  );
}

export default ForgotPassword;
