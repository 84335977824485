import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { livetsockEggPerformanceChart } from "../../services/Chart";
import Shimmer from "../shimmer";
import Notfound from "../data-not-found";
import { useSelector } from "react-redux";
import moment from "moment";

const LivetsockEggChart = () => {
  const zoneRefresh = useSelector((state) => state.updateZone.value);
  const [eggCount, setEggCount] = useState([]);
  const [livestockCount, setLivestockCount] = useState([]);
  const [dateArray, setDateArray] = useState([]);
  const [loader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(30, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const mapSeriesData = async () => {
    let eggCountArray = [],
      livestockCountArray = [];
    let start = localStorage.getItem("startDate") ?? startDate;
    let end = localStorage.getItem("endDate") ?? endDate;
    const response = await livetsockEggPerformanceChart(start, end);
    if (response.status) {
      setLoader(false);
      setDateArray(response.dateArray);
      response?.eggCountArray.map((value) => {
        return eggCountArray.push(value.count);
      });
      response?.livestockCountArray.map((value) => {
        return livestockCountArray.push(value.count);
      });
      setEggCount(eggCountArray);
      setLivestockCount(livestockCountArray);
    }
  };
  useEffect(() => {
    mapSeriesData();
    if (localStorage.getItem("startDate") && localStorage.getItem("endDate")) {
      setStartDate(localStorage.getItem("startDate"));
      setEndDate(localStorage.getItem("endDate"));
    }
  }, [zoneRefresh]);
  // console.log(dateArray);

  const chartOptions = {
    series: [
      {
        name: "Eggs",
        data: eggCount,
      },
      {
        name: "LiveStock",
        data: livestockCount,
      },
    ],

    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            customIcons: [],
          },
          export: {
            csv: {
              filename: "PerformanceChart.csv",
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: "PerformanceChart.svg",
            },
            png: {
              filename: "PerformanceChart.png",
            },
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "20px",
        },
      },
      colors: ["#F17456", "#03BF9E"],

      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: "#fff",
          radius: 12,
          offsetX: 0,
          offsetY: 2,
          margin: 0,
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },

      xaxis: {
        categories: dateArray,
      },
      // yaxis: {
      //   title: {
      //     text: '$ (thousands)'
      //   }
      // },
      fill: {
        opacity: 1,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  };

  return (
    <>
      {" "}
      {loader ? (
        <Shimmer />
      ) : (
        <div id="chart">
          {dateArray.length > 0 ? (
            <div>
              <p className="revanuedateRangetop">
                {moment(startDate).format("DD MMM YYYY")} -{" "}
                {moment(endDate).format("DD MMM YYYY")}
              </p>
              {/* <div ref={chartRef5}> */}
              <div>
                <ReactApexChart
                  options={chartOptions.options}
                  series={chartOptions.series}
                  type="bar"
                  height={300}
                />
              </div>
            </div>
          ) : (
            <Notfound />
          )}
        </div>
      )}
    </>
  );
};
export default LivetsockEggChart;
