import { requestWithToken } from "../helpers/helpers";
const eggColorChart = async (regionId, startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/eggLog/eggColorWiseGraph?regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/eggLog/eggColorWiseGraph?regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const eggSizeChart = async (regionId, startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/eggLog/eggSizeWiseGraph?regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/eggLog/eggSizeWiseGraph?regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const liveStockChart = async (regionId, startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/livestockSlaughter/livestockGraph?regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/livestockSlaughter/livestockGraph?regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const revenueChart = async (startDate, endDate, regionId) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/cashbook/incomeExpenseGraph?startDate=${startDate}&endDate=${endDate}&regionId=${regionId}`
        : `/cashbook/incomeExpenseGraph?regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const performanceChart = async (startDate, endDate, regionId) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/cashbook/performanceGraph?startDate=${startDate}&endDate=${endDate}&regionId=${regionId}`
        : `/cashbook/performanceGraph?regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err;
  }
};

const livetsockEggPerformanceChart = async (startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/poultryLog/performanceGraph?startDate=${startDate}&endDate=${endDate}`
        : `/poultryLog/performanceGraph`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  eggColorChart,
  eggSizeChart,
  liveStockChart,
  revenueChart,
  performanceChart,
  livetsockEggPerformanceChart,
};
