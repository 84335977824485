import { requestWithToken } from "../helpers/helpers";

const createMeatInspection = async (payload) => {
  try {
    const response = await requestWithToken("POST", "/meatInspection", payload);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchMeatInspectionCard = async (regionId, startDate, endDate) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/meatInspection/meatInspectionCard?regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/meatInspection/meatInspectionCard?regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchMeatInspection = async (
  pageNo,
  pageSize,
  regionId,
  startDate,
  endDate
) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/meatInspection/meatInspectionList?pageNo=${pageNo}&pageSize=${pageSize}&regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/meatInspection/meatInspectionList?pageNo=${pageNo}&pageSize=${pageSize}&regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response;
  }
};

export { createMeatInspection, fetchMeatInspection, fetchMeatInspectionCard };
