import React from "react";
import ReactDOM from "react-dom";
import CustomNotification from "./showNotification";

const showNotification = (message, type) => {
  const container = document.createElement("div");
  document.body.appendChild(container);

  ReactDOM.render(
    <CustomNotification
      message={message}
      type={type}
    />,
    container
  );

  // Remove the notification from the DOM after a delay (e.g., 3 seconds)
  setTimeout(() => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
  }, 2000);
};

export default showNotification;
