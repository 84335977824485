import axios from "axios";
import Papa from "papaparse";

export const requestWithoutToken = async (method, url, body) => {
  let request;
  url = process.env.REACT_APP_API_ENDPOINT + url;

  switch (method) {
    case "GET":
      request = await axios.get(url);
      break;
    case "POST":
      request = await axios.post(url, body);
      break;
    default:
      break;
  }

  return request;
};

export const requestWithToken = async (method, url, body, responseType) => {
  let request;
  url = process.env.REACT_APP_API_ENDPOINT + url;

  const headers = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
    },
  };
  if (responseType) {
    headers[`responseType`] = responseType;
  }
  switch (method) {
    case "GET":
      request = await axios.get(url, headers);
      break;
    case "POST":
      request = await axios.post(url, body, headers);
      break;
    case "PUT":
      request = await axios.put(url, body, headers);
      break;
    case "DELETE":
      request = await axios.delete(url, {
        data: body,
        headers: headers.headers,
      });
      break;

    default:
      break;
  }

  return request;
};

export const getImageFullUrl = (url) => {
  return `${process.env.REACT_APP_S3_URL}${url}`;
};

export const numberFormat = (value) => {
  const formatted = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  })
    .format(value)
    .replace("₹", "");

  return formatted.replace(/\.00$/, ""); // Remove .00 at the end of the string
};

const handleKeyDown = (event) => {
  if (event.type === "keydown") {
    if (
      (event.key >= "0" && event.key <= "9") ||
      event.key === "Backspace" ||
      event.key === "Delete" ||
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight" ||
      event.key === "Home" ||
      event.key === "End" ||
      event.key === "Tab"
    ) {
      // Allow the key
    } else {
      // Prevent the key press for all other keys
      event.preventDefault();
    }
  }

  // Check if the event is a mouse scroll event
  // if (event.type === "wheel") {
  //   event.preventDefault();
  // }
};

export const convertToCsv = (csvData) => {
  const data = Papa.unparse(csvData);

  // Create a Blob with the CSV data
  const blob = new Blob([data], { type: "text/csv" });

  // Create a download link
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "data.csv";

  // Trigger a click on the link to start the download
  link.click();

  // Release the object URL
  window.URL.revokeObjectURL(url);
};

export { handleKeyDown };
