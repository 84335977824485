import React, { useEffect, useRef, useState } from "react";
import cardBoxImage from "../assets/images/cardboxbg.png";
import viewMOreArrow from "../assets/images/customCardArrow.svg";
import download from "../assets/images/dwnldbtn.svg";
import EggSizeChart from "../components/home/eggProduction-Chart ";
import EggColorChart from "../components/home/eggColorChart";
import LiveStockChart from "../components/home/liveStockChart";
import { Link } from "react-router-dom";
import EggTable from "../components/home/tableComponent/eggTable";
import LivestockSloughterTable from "../components/home/tableComponent/livestockSloughterTable";
import LivestockMedicationTable from "../components/home/tableComponent/livestockMedicationTable";
import DippingTable from "../components/home/tableComponent/dippingTable";
import LivestockServiceTable from "../components/home/tableComponent/livestockServiceTable";
import PoultryLogTable from "../components/home/tableComponent/poultryLogTable";
import moment from "moment";
import { fetchDippingCard } from "../services/dipping";
import { fetchLivestockHealthCard } from "../services/livestockHealth";
import { fetchLivestockSlaughterCard } from "../services/livestock";
import { fetchLivestockServiceCard } from "../services/livestcokService";
import MeatInspectionTable from "../components/home/tableComponent/meatInspectionTable";
import RevenueChart from "../components/home/revenueChart";
import { fetchPoultryLogCard } from "../services/poultryLog";
import { fetchMeatInspectionCard } from "../services/meatInspection";
import { fetchCropDiseaseCard } from "../services/plantHealthControl";
import { useDispatch, useSelector } from "react-redux";
import Shimmer from "../components/shimmer";
import PerformanceChart from "../components/home/performanceChart";
import CropDiseaseTable from "../components/home/tableComponent/plantHealthTable";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { numberFormat } from "../helpers/helpers";
import { fetchEggLogCard } from "../services/addEgg";
import html2canvas from "html2canvas";
import {
  updateCardCarousalPosition,
  updateLatestUpdateCarousalPosition,
} from "../redux/slice/carousalSlice";

const HomePage = () => {
  const chartRef = useRef(null);
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);
  const chartRef4 = useRef(null);
  const zoneRefresh = useSelector((state) => state.updateZone.value);
  const latestUpdateCarousalPosition = useSelector(
    (state) => state.updateCarousal.latestUpdateCarousalPosition
  );
  const cardCarousalPosition = useSelector(
    (state) => state.updateCarousal.cardCarousalPosition
  );
  const dispatch = useDispatch();

  const [activeTable, setActiveTable] = useState(1);
  const [dippingCard, setDippingCard] = useState();
  const [healthMedicationCard, setHealthMedicationCard] = useState();
  const [livestockSlaughterCard, setLivestockSlaughterCard] = useState();
  const [livestockServiceCard, setLivestockServiceCard] = useState();
  const [meatInspectionCard, setMeatInspectionCard] = useState();
  const [poultryLogCard, setPoultryLogCard] = useState();
  const [cropDiseaseCard, setCropDiseaseCard] = useState();
  const [eggLogCard, setEggLogCard] = useState();
  const [dipingloader, setDipingloader] = useState(true);
  const [medicationloader, setMedicationloader] = useState(true);
  const [politryloader, setPolitryloader] = useState(true);
  const [slaoughterloader, setSlaoughterloader] = useState(true);
  const [meatinsploader, setMeatinsploader] = useState(true);
  const [liveserviceloader, setLiveserviceloader] = useState(true);
  const [plantloader, setPlantloader] = useState(true);
  const [eggLogloader, setEggLogloader] = useState(true);

  // const [startDate, setStartDate] = useState(
  //   moment(new Date()).subtract(30, "days").format("yyyy-MM-DD")
  // );
  // const [endDate, setEndDate] = useState(
  //   moment(new Date()).format("yyyy-MM-DD")
  // );

  const handleDragged = (event) => {
    dispatch(updateLatestUpdateCarousalPosition(event.item.index));
  };

  const handleCardDragged = (event) => {
    dispatch(updateCardCarousalPosition(event.item.index));
  };

  const date = moment().format("DD MMM YYYY");

  const getDippingCard = async () => {
    setDipingloader(true);
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const res = await fetchDippingCard(startDate, endDate, regionId);
    if (res.status) {
      setDipingloader(false);
      setDippingCard(res.data);
    }
  };

  const getMedicationCard = async () => {
    setMedicationloader(true);
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const res = await fetchLivestockHealthCard(regionId, startDate, endDate);
    if (res.status) {
      setMedicationloader(false);
      setHealthMedicationCard(res.data);
    }
  };

  const getCropDiseaseCard = async () => {
    setPlantloader(true);
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const res = await fetchCropDiseaseCard(regionId, startDate, endDate);
    if (res.status) {
      setPlantloader(false);
      setCropDiseaseCard(res.data);
    }
  };

  const getSlaughterCard = async () => {
    setSlaoughterloader(true);
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const res = await fetchLivestockSlaughterCard(regionId, startDate, endDate);
    if (res.status) {
      setSlaoughterloader(false);
      setLivestockSlaughterCard(res.data);
    }
  };

  const getLivestockServiceCard = async () => {
    setLiveserviceloader(true);
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const res = await fetchLivestockServiceCard(regionId, startDate, endDate);
    if (res.status) {
      setLiveserviceloader(false);
      setLivestockServiceCard(res.data);
    }
  };

  const getMeatCard = async () => {
    setMeatinsploader(true);
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const res = await fetchMeatInspectionCard(regionId, startDate, endDate);
    if (res.status) {
      setMeatinsploader(false);
      setMeatInspectionCard(res.data);
    }
  };

  const getPoultryLogCard = async () => {
    setPolitryloader(true);
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const res = await fetchPoultryLogCard(regionId, startDate, endDate);
    if (res.status) {
      setPolitryloader(false);
      setPoultryLogCard(res.data);
    }
  };

  const getEggLogCard = async () => {
    setEggLogloader(true);
    let startDate = localStorage.getItem("startDate");
    let endDate = localStorage.getItem("endDate");
    let regionId = localStorage.getItem("selectedZone");
    const res = await fetchEggLogCard(regionId, startDate, endDate);
    if (res.status) {
      setEggLogloader(false);
      setEggLogCard(res.data);
    }
  };

  useEffect(() => {
    getDippingCard();
    getMedicationCard();
    getSlaughterCard();
    getLivestockServiceCard();
    getPoultryLogCard();
    getMeatCard();
    getCropDiseaseCard();
    getEggLogCard();
  }, [zoneRefresh]);

  const saveAsImage = (ref) => {
    if (ref.current) {
      html2canvas(ref.current).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "chart.png";
        link.click();
      });
    }
  };

  return (
    <div className="theme-container">
      <div className="contentCard">
        <div className="cardbg">
          <img src={cardBoxImage} alt="card bg" />
        </div>
        <div className="cardContentHeader">
          <p>As on date {date}</p>
          {/* <div className="cardcontentHeaderRight">
            <div className="leftArrow" id="prev">
              <img src={leftArrow} alt="" />
            </div>
            <div className="rightArrow " id="next">
              <img src={rightArrow} alt="" />
            </div>
          </div> */}
        </div>
        <div className="cards">
          <OwlCarousel
            key={Math.random() * (100 - 1)}
            className="owl-theme cardSlider"
            margin={15}
            loop={false}
            items={5}
            nav
            dots={false}
            startPosition={cardCarousalPosition}
            onTranslated={handleCardDragged}
          >
            <div className="customcard">
              <h4 className="dipping">Dipping, Spraying & Vaccination</h4>
              <p>Total</p>
              {dipingloader ? (
                <Shimmer />
              ) : (
                <div>
                  <h2 className="dipping">
                    {numberFormat(dippingCard?.total)}
                  </h2>
                  <ul className="cardTable">
                    <li>
                      Dipping<span>{numberFormat(dippingCard?.Dipping)}</span>
                    </li>

                    <li>
                      Spraying<span>{numberFormat(dippingCard?.Spraying)}</span>
                    </li>

                    <li>
                      Vaccination
                      <span>{numberFormat(dippingCard?.Vaccination)}</span>
                    </li>
                  </ul>
                </div>
              )}
              <div className="viewbtn">
                <Link to="/dippingTable-page">
                  {" "}
                  <p>view more </p>
                  <img className="viewMOreArrow" src={viewMOreArrow} alt="" />
                </Link>
              </div>
            </div>
            <div className="customcard">
              <h4 className="Healthcard">Livestock Health-Medication</h4>
              <p>Total</p>
              {medicationloader ? (
                <Shimmer />
              ) : (
                <div>
                  <h2 className="Healthcard">
                    {numberFormat(healthMedicationCard?.total)}
                  </h2>
                  <ul className="cardTable">
                    <li>
                      Type of Animal
                      <span>
                        {numberFormat(healthMedicationCard?.LivestockCount)}
                      </span>
                    </li>

                    <li>
                      Number of Animal Affected
                      <span>
                        {numberFormat(healthMedicationCard?.AnimalAffected)}
                      </span>
                    </li>

                    <li>
                      Number of Animal treated
                      <span>
                        {numberFormat(healthMedicationCard?.AnimalTreated)}
                      </span>
                    </li>
                  </ul>{" "}
                </div>
              )}

              <div className="viewbtn">
                <Link to="/medicationtable-page">
                  <p>view more </p>
                  <img className="viewMOreArrow" src={viewMOreArrow} alt="" />
                </Link>
              </div>
            </div>
            <div className="customcard">
              <h4 className="cropDisease">Plant Health & Chemical Control</h4>
              <p>Total</p>
              {plantloader ? (
                <Shimmer />
              ) : (
                <div>
                  <h2 className="cropDisease">
                    {numberFormat(cropDiseaseCard?.total)}
                  </h2>
                  <ul className="cardTable">
                    <li>
                      Pests/Disease
                      <span>{numberFormat(cropDiseaseCard?.disease)}</span>
                    </li>

                    <li>
                      Crop Affected
                      <span>{numberFormat(cropDiseaseCard?.cropAffected)}</span>
                    </li>
                    <li>
                      Villages Affected
                      <span>
                        {numberFormat(cropDiseaseCard?.villagesAffected)}
                      </span>
                    </li>
                  </ul>
                </div>
              )}
              <div className="viewbtn">
                <Link to="/cropDiseaseTablePage">
                  <p>view more </p>
                  <img className="viewMOreArrow" src={viewMOreArrow} alt="" />
                </Link>
              </div>
            </div>
            <div className="customcard">
              <h4 className="livestokservice">Livestock Service</h4>
              <p>Total</p>
              {liveserviceloader ? (
                <Shimmer />
              ) : (
                <div>
                  <h2 className="livestokservice">
                    {numberFormat(livestockServiceCard?.total)}
                  </h2>
                  <ul className="cardTable">
                    <li>
                      Type of Livestock
                      <span>
                        {numberFormat(livestockServiceCard?.typeOfLivestock)}
                      </span>
                    </li>

                    <li>
                      Trimming Hoof
                      <span>
                        {numberFormat(livestockServiceCard?.trimmingHooves)}
                      </span>
                    </li>

                    {/* <li>
                Other<span>3600</span>
              </li> */}
                  </ul>
                </div>
              )}
              <div className="viewbtn">
                <Link to="/livestockServiceTable-page">
                  <p>view more </p>
                  <img className="viewMOreArrow" src={viewMOreArrow} alt="" />
                </Link>
              </div>
            </div>
            <div className="customcard">
              <h4 className="livestockslt">Livestock Slaughtered</h4>
              <p>Total</p>
              {slaoughterloader ? (
                <Shimmer />
              ) : (
                <div>
                  <h2 className="livestockslt">
                    {numberFormat(livestockSlaughterCard?.total)}
                  </h2>
                  <ul className="cardTable">
                    <li>
                      Livestock
                      <span>
                        {numberFormat(livestockSlaughterCard?.livestockCount)}
                      </span>
                    </li>

                    <li>
                      Total No. Slaughtered
                      <span>
                        {numberFormat(livestockSlaughterCard?.sLaughtered)}
                      </span>
                    </li>

                    {/* <li>
                Others<span>3600</span>
              </li> */}
                  </ul>
                </div>
              )}
              <div className="viewbtn">
                <Link to="/livestockSlaughterTable-page">
                  <p>view more </p>
                  <img className="viewMOreArrow" src={viewMOreArrow} alt="" />
                </Link>
              </div>
            </div>
            <div className="customcard">
              <h4 className="Meatinsp">Meat Inspection</h4>
              <p>Total</p>
              {meatinsploader ? (
                <Shimmer />
              ) : (
                <div>
                  <h2 className="Meatinsp">
                    {numberFormat(meatInspectionCard?.total)}
                  </h2>
                  <ul className="cardTable">
                    <li>
                      Type of Animal
                      <span>
                        {numberFormat(meatInspectionCard?.livestockCount)}
                      </span>
                    </li>

                    <li>
                      Slaughtered/Inspection
                      <span>
                        {numberFormat(meatInspectionCard?.Inspection)}
                      </span>
                    </li>

                    {/* <li>
                  Others<span>2,000</span>
                </li> */}
                  </ul>
                </div>
              )}
              <div className="viewbtn">
                <Link to="/meatInspectionTable-page">
                  <p>view more </p>
                  <img className="viewMOreArrow" src={viewMOreArrow} alt="" />
                </Link>
              </div>
            </div>
            <div className="customcard">
              <h4 className="PoultryLog">Poultry Log</h4>
              <p>Total</p>
              {plantloader ? (
                <Shimmer />
              ) : (
                <div>
                  <h2 className="PoultryLog">
                    {numberFormat(poultryLogCard?.total)}
                  </h2>
                  <ul className="cardTable">
                    <li>
                      Breed <span>{numberFormat(poultryLogCard?.breed)}</span>
                    </li>
                  </ul>
                </div>
              )}
              <div className="viewbtn">
                <Link to="/stock">
                  <p>view more </p>
                  <img className="viewMOreArrow" src={viewMOreArrow} alt="" />
                </Link>
              </div>
            </div>
            <div className="customcard">
              <h4 className="dipping">Egg Log</h4>
              <p>Total</p>
              {eggLogloader ? (
                <Shimmer />
              ) : (
                <div>
                  <h2 className="dipping">{numberFormat(eggLogCard?.total)}</h2>
                  <ul className="cardTable">
                    <li>
                      Total White
                      <span>{numberFormat(eggLogCard?.whiteEggs)}</span>
                    </li>

                    <li>
                      Total Brown
                      <span>{numberFormat(eggLogCard?.brownEggs)}</span>
                    </li>
                  </ul>
                </div>
              )}
              <div className="viewbtn">
                <Link to="/eggLog-page">
                  {" "}
                  <p>view more </p>
                  <img className="viewMOreArrow" src={viewMOreArrow} alt="" />
                </Link>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>

      <section className="middle">
        <div className="cardRow">
          <div className="themeCard card1">
            <img
              className="chartDownloadbtn"
              src={download}
              onClick={() => {
                saveAsImage(chartRef);
              }}
              alt=""
            />
            <div className="CardContainer" ref={chartRef}>
              <header className="cardHeader">
                <h5 className="cardTitle">Revenue</h5>
              </header>
              <div className="card-body revanues">
                <RevenueChart chartRef={chartRef} />
              </div>
            </div>
          </div>

          <div className="themeCard card2">
            <img
              className="chartDownloadbtn"
              src={download}
              onClick={() => {
                saveAsImage(chartRef4);
              }}
              alt=""
            />
            <div className="CardContainer" ref={chartRef4}>
              <header className="cardHeader">
                <h5 className="cardTitle">Performance</h5>
              </header>
              <div className="card-body perform">
                <PerformanceChart />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="middle-bottom">
        <div className="row">
          <div className="col-md-4 gaprightclass">
            <div className="themeCard">
              <img
                className="chartDownloadbtn"
                src={download}
                onClick={() => {
                  saveAsImage(chartRef1);
                }}
                alt=""
              />
              <div className="CardContainer" ref={chartRef1}>
                <header className="cardHeader">
                  <h5 className="cardTitle">Egg Production Sizes</h5>
                </header>
                <div className="card-body">
                  <EggSizeChart chartRef1={chartRef1} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 gapclass">
            <div className="themeCard">
              <img
                className="chartDownloadbtn"
                src={download}
                onClick={() => {
                  saveAsImage(chartRef2);
                }}
                alt=""
              />
              <div className="CardContainer" ref={chartRef2}>
                <header className="cardHeader">
                  <h5 className="cardTitle">Egg Production Color</h5>
                </header>
                <div className="card-body">
                  {/* <img src={c2} alt="" /> */}
                  <EggColorChart chartRef2={chartRef2} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4  gapLeftclass">
            <div className="themeCard">
              <img
                className="chartDownloadbtn"
                src={download}
                onClick={() => {
                  saveAsImage(chartRef3);
                }}
                alt=""
              />
              <div className="CardContainer" ref={chartRef3}>
                <header className="cardHeader">
                  <h5 className="cardTitle">Livestock</h5>
                </header>
                <div className="card-body">
                  {/* <img src={c3} alt="" /> */}
                  <LiveStockChart chartRef3={chartRef3} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bottom">
        <div className="themeCard ">
          <div className="CardContainer">
            <header className="cardHeader">
              <h5 className="cardTitle">Latest Updates</h5>
              <div className="headerbtn">
                <Link
                  to={(() => {
                    switch (activeTable) {
                      case 1:
                        return "/dippingTable-page";
                      case 2:
                        return "/medicationtable-page";
                      case 3:
                        return "/cropDiseaseTablePage";
                      case 4:
                        return "/livestockServiceTable-page";
                      case 5:
                        return "/livestockSlaughterTable-page";
                      case 6:
                        return "/meatInspectionTable-page";
                      case 7:
                        return "/stock";
                      case 8:
                        return "/eggLog-page";
                      default:
                        return <></>;
                    }
                  })()}
                >
                  View more{" "}
                  <img className="viewMOreArrow" src={viewMOreArrow} alt="" />
                </Link>
              </div>
              {/* <div className="viewbtn">
                <Link to="/stock">view more &#8594;</Link>
              </div> */}
            </header>
            <div className="card-body">
              <div className="navTabs">
                <ul className="tabbuttons">
                  <OwlCarousel
                    key={activeTable}
                    className="owl-themelist  cardSlider bottomSlider"
                    margin={15}
                    items={5}
                    nav
                    loop={false}
                    dots={false}
                    // navText={[
                    //   `<img src=${leftArrowb} />`,
                    //   `<img src=${rightArrowb} />`,
                    // ]}
                    startPosition={latestUpdateCarousalPosition}
                    onTranslated={handleDragged}
                  >
                    <li
                      className={`tabbtn ${activeTable === 1 ? "active" : ""}`}
                      onClick={() => {
                        setActiveTable(1);
                      }}
                    >
                      <div className="tbBtn">Dipping & Vaccination</div>
                    </li>
                    <li
                      className={`tabbtn ${activeTable === 2 ? "active" : ""}`}
                    >
                      <div
                        className="tbBtn"
                        onClick={() => {
                          setActiveTable(2);
                        }}
                      >
                        Medication
                      </div>
                    </li>
                    <li
                      className={`tabbtn ${activeTable === 3 ? "active" : ""}`}
                    >
                      <div
                        className="tbBtn"
                        onClick={() => {
                          setActiveTable(3);
                        }}
                      >
                        Plant Health & Chemical Control
                      </div>
                    </li>
                    <li
                      className={`tabbtn ${activeTable === 4 ? "active" : ""}`}
                    >
                      <div
                        className="tbBtn"
                        onClick={() => {
                          setActiveTable(4);
                        }}
                      >
                        Livestock Service
                      </div>
                    </li>
                    <li
                      className={`tabbtn ${activeTable === 5 ? "active" : ""}`}
                    >
                      <div
                        className="tbBtn"
                        onClick={() => {
                          setActiveTable(5);
                        }}
                      >
                        Livestock Slaughtered
                      </div>
                    </li>
                    <li
                      className={`tabbtn ${activeTable === 6 ? "active" : ""}`}
                    >
                      <div
                        className="tbBtn"
                        onClick={() => {
                          setActiveTable(6);
                        }}
                      >
                        Meat Inspection
                      </div>
                    </li>
                    <li
                      className={`tabbtn ${activeTable === 7 ? "active" : ""}`}
                    >
                      <div
                        className="tbBtn"
                        onClick={() => {
                          setActiveTable(7);
                        }}
                      >
                        Poultry Log
                      </div>
                    </li>
                    <li
                      className={`tabbtn ${activeTable === 8 ? "active" : ""}`}
                    >
                      <div
                        className="tbBtn"
                        onClick={() => {
                          setActiveTable(8);
                        }}
                      >
                        Egg Log
                      </div>
                    </li>
                  </OwlCarousel>
                </ul>
                <div className="tableBlock">
                  {(() => {
                    switch (activeTable) {
                      case 1:
                        return <DippingTable />;
                      case 2:
                        return <LivestockMedicationTable />;
                      case 3:
                        return <CropDiseaseTable />;
                      case 4:
                        return <LivestockServiceTable />;
                      case 5:
                        return <LivestockSloughterTable />;
                      case 6:
                        return <MeatInspectionTable />;
                      case 7:
                        return <PoultryLogTable />;
                      case 8:
                        return <EggTable />;
                      default:
                        return <></>;
                    }
                  })()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
