import { requestWithToken } from "../helpers/helpers";

const createDipping = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      "/dippingSprayingVaccination",
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchDippingList = async (
  pageNo,
  pageSize,
  regionId,
  startDate,
  endDate
) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/dippingSprayingVaccination/vaccinationList?pageNo=${pageNo}&pageSize=${pageSize}&regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/dippingSprayingVaccination/vaccinationList?pageNo=${pageNo}&pageSize=${pageSize}&regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchDippingCard = async (startDate, endDate, regionId) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/dippingSprayingVaccination/dippingSprayingVaccinationCard?regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/dippingSprayingVaccination/dippingSprayingVaccinationCard?regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export { createDipping, fetchDippingList, fetchDippingCard };
