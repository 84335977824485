import { useState, React, useEffect } from "react";
import cardBoxImage from "../../assets/images/cardboxbg.png";
import Back from "../../assets/images/backbtn.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  createPoultryLog,
  fetchColors,
  fetchSizes,
} from "../../services/poultryLog";
import moment from "moment";
import showNotification from "../notification/notification";
import { Button, Modal } from "react-bootstrap";

const AddPoultryLog = () => {
  const navigate = useNavigate();
  const [colors, setColors] = useState();
  const [sizes, setSizes] = useState();
  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(1);

  const addPoultryLogFormik = useFormik({
    initialValues: {
      dob: "",
      funName: "",
      breed: "",
      regionId: JSON.parse(localStorage.getItem("data"))?.regionDetails[0]?._id,
      gender: " ",
      sizeId: "",
      purchaseDate: "",
      eggLayingDate: "",
      colorId: "",
      saleDate: "",
      chickPurchaseDate: "",
      notes: "",
    },
    onSubmit: async (values) => {
      const res = await createPoultryLog(values);
      if (res.status) {
        // alert("Log created successfully");
        navigate("/");
        showNotification("Poultry Log data added successfully!", "success");
      } else {
        showNotification(`${res.message}`, "danger");
      }
    },
  });

  const getSize = async () => {
    const res = await fetchSizes();
    if (res.status) {
      setSizes(res.data);
    }
  };

  const getColors = async () => {
    const res = await fetchColors();
    if (res.status) {
      setColors(res.data);
    }
  };

  useEffect(() => {
    getSize();
    getColors();
  }, []);

  const goBack = () => {
    navigate(-1);
  };
  const addUserReset = () => {
    addPoultryLogFormik.handleReset();
    setShow(false);
    setOpacity(0);
  };
  const handleClose = () => {
    setShow(false);
    setOpacity(0);
  };
  const handleShow = () => {
    setShow(true);
    setOpacity(1);
  };

  return (
    <div className="theme-container">
      <div className="contentCard2">
        <div className="contentCardHeader addProdctHeader">
          <h3>Poultry Log</h3>
          <button onClick={goBack}>
            <img
              src={Back}
              alt=""
            />{" "}
            Back
          </button>
        </div>
        <div className="cardbg">
          <img
            src={cardBoxImage}
            alt="card bg"
          />
        </div>
      </div>
      {/* -------------------Form---------------------- */}
      <form onSubmit={addPoultryLogFormik.handleSubmit}>
        <div className="politryStockForm">
          <div className="politryStock addForm">
            <div className="addFormPage">
              <div className="row1">
                <div className="row">
                  <div className="col-md-3">
                    <div className=" addFormRow">
                      <label>DOB</label>
                      <input
                        required
                        name="dob"
                        type="date"
                        placeholder="select date"
                        value={addPoultryLogFormik.values.dob}
                        max={moment(new Date()).format("YYYY-MM-DD")}
                        onChange={addPoultryLogFormik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="addFormRow">
                      <label> Full Name</label>
                      <input
                        required
                        name="funName"
                        type="text"
                        placeholder="Enter Full Name"
                        value={addPoultryLogFormik.values.funName}
                        onChange={addPoultryLogFormik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="addFormRow">
                      <label> Breed </label>
                      <input
                        required
                        name="breed"
                        type="text"
                        placeholder="Enter Breed"
                        value={addPoultryLogFormik.values.breed}
                        onChange={addPoultryLogFormik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="addFormRow">
                      <label> Gender</label>
                      <select
                        value={addPoultryLogFormik.values.gender}
                        onChange={(e) => {
                          addPoultryLogFormik.setFieldValue(
                            "gender",
                            Number(e.target.value)
                          );
                        }}>
                        <option value="">Select Gender</option>
                        <option value={1}>Male</option>
                        <option value={2}>Female</option>
                        <option value={3}>Other</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row2">
                <div className="row">
                  <div className="col-md-3">
                    <div className="addFormRow">
                      <label>Size</label>
                      <select
                        name="sizeId"
                        id=""
                        value={addPoultryLogFormik.values.sizeId}
                        onChange={(e) => {
                          addPoultryLogFormik.setFieldValue(
                            "sizeId",
                            e.target.value
                          );
                        }}>
                        <option value="Sizes">Select Size</option>
                        {sizes &&
                          sizes?.map((value) => {
                            return (
                              <option value={value._id}>{value.name}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className=" addFormRow">
                      <label>Purchase Date</label>
                      <input
                        required
                        name="purchaseDate"
                        type="date"
                        placeholder="select date"
                        value={addPoultryLogFormik.values.purchaseDate}
                        max={moment(new Date()).format("YYYY-MM-DD")}
                        onChange={addPoultryLogFormik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className=" addFormRow">
                      <label>Egg Laying Date</label>
                      <input
                        required
                        name="eggLayingDate"
                        type="date"
                        placeholder="select date"
                        value={addPoultryLogFormik.values.eggLayingDate}
                        max={moment(new Date()).format("YYYY-MM-DD")}
                        onChange={addPoultryLogFormik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className=" addFormRow">
                      <label>Sale Date</label>
                      <input
                        required
                        name="saleDate"
                        type="date"
                        placeholder="select date"
                        value={addPoultryLogFormik.values.saleDate}
                        max={moment(new Date()).format("YYYY-MM-DD")}
                        onChange={addPoultryLogFormik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row3">
                <div className="row">
                  <div className="col-md-3">
                    <div className=" addFormRow">
                      <label>Chick Purchase Date</label>
                      <input
                        required
                        name="chickPurchaseDate"
                        type="date"
                        placeholder="select date"
                        value={addPoultryLogFormik.values.chickPurchaseDate}
                        max={moment(new Date()).format("YYYY-MM-DD")}
                        onChange={addPoultryLogFormik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="addFormRow">
                      <label>Color</label>
                      <select
                        name="colorId"
                        id=""
                        value={addPoultryLogFormik.values.colorId}
                        onChange={(e) => {
                          addPoultryLogFormik.setFieldValue(
                            "colorId",
                            e.target.value
                          );
                        }}>
                        <option value="Colors">Select Color</option>
                        {colors &&
                          colors?.map((value) => {
                            return (
                              <option
                                value={value._id}
                                onChange={(e) => {
                                  addPoultryLogFormik.setFieldValue(
                                    "colorId",
                                    e.target.value
                                  );
                                }}>
                                {value.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                    <div className=" addFormRow">
                      <label>Type</label>
                      <input
                        name="type"
                        type="number"
                        placeholder="Enter Type"
                        value={addPoultryLogFormik.values.type}
                        onChange={addPoultryLogFormik.handleChange}
                      />
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <div className="addFormRow">
                      <label>Notes</label>
                      <input
                        name="notes"
                        type="text"
                        placeholder="Enter Notes"
                        value={addPoultryLogFormik.values.notes}
                        onChange={addPoultryLogFormik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottomButtons">
            <button
              type="submit"
              className="addprdt active"
              id="addprdt">
              Add Poultry Log
            </button>
            <button
              type="reset"
              className="reset"
              id="rst"
              onClick={handleShow}>
              {" "}
              Reset
            </button>
          </div>
        </div>
      </form>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ opacity: opacity }}>
        <Modal.Header closeButton>
          <Modal.Title> Reset?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}>
            No
          </Button>
          <Button
            variant="primary"
            onClick={addUserReset}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddPoultryLog;
