import { requestWithToken } from "../helpers/helpers";

const crateProduction = async (payload) => {
  try {
    const response = await requestWithToken("POST", "/production", payload);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchMainCropCategories = async () => {
  try {
    const response = await requestWithToken(
      "GET",
      "/productType/cropCategories"
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchCropSubCategories = async () => {
  try {
    const response = await requestWithToken("GET", "/productType/products");

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const crateSchoolProgram = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      "/production/schoolSupportProgram",
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchProductiongList = async (
  pageNo,
  pageSize,
  regionId,
  startDate,
  endDate
) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/production/productionList?pageNo=${pageNo}&pageSize=${pageSize}&regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/production/productionList?pageNo=${pageNo}&pageSize=${pageSize}&regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchSchoolProgramList = async (
  pageNo,
  pageSize,
  regionId,
  startDate,
  endDate
) => {
  try {
    const response = await requestWithToken(
      "GET",
      startDate
        ? `/production/schoolSupportProgramList?pageNo=${pageNo}&pageSize=${pageSize}&regionId=${regionId}&startDate=${startDate}&endDate=${endDate}`
        : `/production/schoolSupportProgramList?pageNo=${pageNo}&pageSize=${pageSize}&regionId=${regionId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  crateProduction,
  fetchMainCropCategories,
  fetchCropSubCategories,
  crateSchoolProgram,
  fetchProductiongList,
  fetchSchoolProgramList,
};
