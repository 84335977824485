import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { performanceChart } from "../.././services/Chart";
import Shimmer from "../shimmer";
import Notfound from "../data-not-found";
import { useSelector } from "react-redux";
import Download from "../../assets/images/dwnldbtn.svg";

const PerformanceChart = () => {
  const zoneRefresh = useSelector((state) => state.updateZone.value);
  const [income, setIncome] = useState([]);
  const [expense, setExpense] = useState([]);
  const [dateArray, setDateArray] = useState([]);
  const [loader, setLoader] = useState(true);

  const mapSeriesData = async () => {
    let regionId = localStorage.getItem("selectedZone");
    let incomeArray = [],
      expenseArray = [];
    const startDate = localStorage.getItem("startDate");
    const endDate = localStorage.getItem("endDate");
    const response = await performanceChart(startDate, endDate, regionId);
    setLoader(false);
    if (response.status) {
      setDateArray(response.date);
      // console.log(dateArray);
      response?.income.map((value) => {
        incomeArray.push(value.income);
      });
      response?.expense.map((value) => {
        expenseArray.push(value.expense);
      });
      setIncome(incomeArray);
      setExpense(expenseArray);
    }
  };
  useEffect(() => {
    mapSeriesData();
  }, [zoneRefresh]);

  const chartOptions = {
    series: [
      {
        name: "Income",
        data: income,
      },
      {
        name: "Expense",
        data: expense,
      },
    ],
    options: {
      colors: ["#03BF9E", "#F17456"],
      toolbox: {
        saveAsImage: {}, // This enables the 'Save As Image' feature
      },
      chart: {
        // height: "100%",
        type: "area",
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            customIcons: [],
          },
          export: {
            csv: {
              filename: "PerformanceChart.csv",
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: "PerformanceChart.svg",
            },
            png: {
              filename: "PerformanceChart.png",
            },
          },
        },
      },

      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
      },
      stroke: {
        curve: "smooth",
        width: 1.75,
      },
      xaxis: {
        categories: dateArray,
      },
      // yaxis: {

      // },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  };
  // console.log("hi  " + chartOptions.series.income);

  // console.log(
  //   "dataLog",
  //   `${income.length}, ${expense.length}, ${
  //     income.length && expense.length > 0
  //   }`
  // );
  return (
    <>
      {loader ? (
        <Shimmer />
      ) : (
        <div id="chart">
          {dateArray.length > 0 ? (
            <div className="performanceChartview">
              <ReactApexChart
                options={chartOptions.options}
                series={chartOptions.series}
                type="area"
                height={350}
              />
            </div>
          ) : (
            <div className="performance">
              <Notfound />
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default PerformanceChart;
