import { React } from "react";
import { handleKeyDown } from "../../helpers/helpers";

const AddReportForm = ({ index, formData, changeFormValue }) => {
  const onInputChange = (value, key) => {
    changeFormValue(index, value, key);
  };

  return (
    <div>
      <div className="prodctRow addDrainageForm">
        <label className="prodctRowCol1 formFullLabel">{`${formData.name} Details`}</label>
        <div className="prodctRowinputBoxes">
          <div className="row">
            <div className="col-md-5">
              <div className="inputAdd">
                <label className="inputAddLabel">Count</label>
                <input
                  required
                  type="number"
                  value={formData?.count}
                  onKeyDown={handleKeyDown}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "count");
                  }}
                  placeholder="Enter Count"
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="inputAdd">
                <label className="inputAddLabel">Comments</label>
                <input
                  required
                  type="text"
                  value={formData?.comments}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "comments");
                  }}
                  placeholder="Enter Comment"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddReportForm;
