import React, { useEffect, useRef, useState } from "react";
import side from "../assets/images/side.png";
import agro from "../assets/images/Agro.png";
import agrologo from "../assets/images/agro.2x.png";
import logout from "../assets/images/logoutIcon.svg";
import well from "../assets/images/wellsmall.png";
import SideDrawer from "./sideDrawer";
import AddMoreDropdown from "./dropdown";
import { fetchMe } from "../services/home";
import { fetchRegions } from "../services/user";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import { USER_ROLE } from "../helpers/userRoleEnum";
import { useDispatch } from "react-redux";
import { updateZone } from "../redux/slice/counterSlice";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import {
  updateCardCarousalPosition,
  updateLatestUpdateCarousalPosition,
} from "../redux/slice/carousalSlice";

const HeaderMenu = () => {
  const dispatch = useDispatch();

  const [showTogal, setShowTogal] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [zoneList, setZoneList] = useState([]);
  const [arrow, setArrow] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [showDaterange, setShowDaterange] = useState(false);
  const [selectedZone, setSelectedZone] = useState("");
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const role = JSON.parse(localStorage.getItem("data"))?.roles?.some(
    (value) => {
      return value.shortCode === USER_ROLE.FIELDOFFICER;
    }
  );

  const userRole = JSON.parse(localStorage.getItem("data"))?.roles?.some(
    (value) => {
      return value.shortCode === USER_ROLE.SUPERADMIN;
    }
  );

  const addUserBtn = JSON.parse(
    localStorage.getItem("data")
  )?.permissionType?.some((value) => {
    return value === 1;
  });

  const hideSideMenu = () => {
    setShowSideMenu(!showSideMenu);
  };
  const rotateArrow = () => {
    setArrow(!arrow);
  };

  const getMeData = async () => {
    const res = await fetchMe();
    if (res.status) {
      setUserDetails(res.data[0]);
      // setZoneList(res.data[0].regionDetails);
    }
  };

  const getRegions = async () => {
    // let role = JSON.parse(localStorage.getItem("data"))?.roles[0]?.shortCode;
    const res = await fetchRegions();
    if (res.status) {
      setZoneList(res.data);
    }
  };

  const addDefaultDate = () => {
    localStorage.setItem(
      "startDate",
      moment(new Date()).subtract(7, "days").format("yyyy-MM-DD")
    );
    localStorage.setItem("endDate", moment(new Date()).format("yyyy-MM-DD"));
  };

  useEffect(() => {
    setSelectedZone(localStorage.getItem("selectedZone") ?? "");
    addDefaultDate();
    getMeData();
    getRegions();
    if (localStorage.getItem("selectedZone") === null) {
      localStorage.setItem("selectedZone", "");
    }
  }, []);

  const ref2 = useRef();
  const ref3 = useRef();

  useEffect(() => {
    // console.log("JIJOIJOOJOJIOJO");
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (rotateArrow && ref2.current && !ref2.current.contains(e.target)) {
        // setIsMenuOpen(false);
        setArrow(false);
      }
      if (rotateArrow && ref3.current && !ref3.current.contains(e.target)) {
        setShowDaterange(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);

  const apllyDateRange = () => {
    localStorage.setItem(
      "startDate",
      moment(dateState[0].startDate).format("yyyy-MM-DD")
    );
    localStorage.setItem(
      "endDate",
      moment(dateState[0].endDate).format("yyyy-MM-DD")
    );
    setShowDaterange(false);
    dispatch(updateZone());
  };
  const clearDate = () => {
    setDateState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    addDefaultDate();
    setShowDaterange(false);
    dispatch(updateZone());
  };
  const navigate = useNavigate();
  const logOut = () => {
    localStorage.clear();
    navigate("/login");
  };
  return (
    <div className="navbar">
      <ul className="leftNav">
        <li>
          <Link className="sideline" onClick={hideSideMenu}>
            <img src={side} alt="" />
          </Link>
        </li>
        <li>
          <Link
            className="homelogo"
            to="/"
            onClick={() => {
              dispatch(updateLatestUpdateCarousalPosition(0));
              dispatch(updateCardCarousalPosition(0));
            }}
          >
            {" "}
            <img src={agrologo} alt="" />
          </Link>
        </li>
        <li className="zone">
          {userRole === true ? (
            <>
              <select
                value={selectedZone}
                onChange={(e) => {
                  setSelectedZone(e.target.value);
                  localStorage.setItem("selectedZone", e.target.value);
                  dispatch(updateZone());
                }}
                onClick={rotateArrow}
                ref={ref2}
              >
                {userRole === true ? <option value="">All Zones</option> : " "}
                {zoneList &&
                  zoneList?.map((value) => {
                    return <option value={value._id}>{value.name}</option>;
                  })}
              </select>
              <>
                {arrow ? <i class="arrow up "></i> : <i class="arrow down"></i>}
              </>
            </>
          ) : (
            <div>
              {zoneList.map((value) => {
                return <div className="fieldOfficerZone">{value.name}</div>;
              })}
            </div>
          )}
        </li>
        <li>
          <Link href="/">
            {" "}
            <img src="" alt="" />
          </Link>
        </li>
      </ul>
      <ul></ul>
      <ul className="rightNav">
        <li>
          {" "}
          <button
            className="dateRangebtn "
            onClick={() => {
              setShowDaterange(!showDaterange);
            }}
          >
            {localStorage.getItem("startDate") &&
            localStorage.getItem("endDate") ? (
              <p style={{ margin: "0" }}>
                {moment(localStorage.getItem("startDate")).format("DD-MM-YYYY")}{" "}
                - {moment(localStorage.getItem("endDate")).format("DD-MM-YYYY")}
              </p>
            ) : (
              <p style={{ margin: "0" }}>
                {moment(localStorage.getItem("startDate")).format("DD-MM-YYYY")}{" "}
                - {moment(localStorage.getItem("endDate")).format("DD-MM-YYYY")}
              </p>
            )}
          </button>
          <div
            className={showDaterange ? "datePopup" : "datePopup datehide"}
            ref={ref3}
          >
            <DateRange
              style={{ display: "block" }}
              editableDateInputs={true}
              ranges={dateState}
              moveRangeOnFirstSelection={false}
              onChange={(item) => {
                setDateState([item.selection]);
              }}
              maxDate={new Date()}
              dateFormat="yyyy-MM-dd"
            />
            {/* <div class="inputRow">
              <label className="dateRangeLabel">Start date</label>
              <input
                type="date"
                name="startDate"
                value={moment(startDate).format("yyyy-MM-DD")}
                // min={moment(startDate).format("yyyy-MM-DD")}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  localStorage.setItem("startDate", startDate);
                }}
              />
            </div>
            <div class="inputRow">
              <label className="dateRangeLabel">End date</label>

              <input
                type="date"
                name="endDate"
                max={moment(new Date()).format("yyyy-MM-DD")}
                value={moment(endDate).format("yyyy-MM-DD")}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  localStorage.setItem("endDate", endDate);
                }}
              />
            </div> */}
            <div className="datefilterbtnbox">
              <button className="datefilterapllybtn" onClick={clearDate}>
                Clear
              </button>
              <button className="datefilterapllybtn" onClick={apllyDateRange}>
                Apply
              </button>
            </div>
          </div>
        </li>
        <li>
          <div className="add">{role === true ? <AddMoreDropdown /> : " "}</div>
        </li>
        {/* <li>
          <Link href="/">
            {" "}
            <img src={well} alt="" />
          </Link>
        </li> */}
        <li>
          <div className="topRightCard">
            {/* <img
              src={prof}
              alt=""
            /> */}
            <div className="topRightCardText">
              {/* <select name="" id="profile">
                <option value="">
                  {userDetails?.firstName}
                  {userDetails?.lastName}
                </option>
                <option value="">
               
                </option>
              </select > */}
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className={
                    role === false
                      ? "toprightbtn addUserbtn"
                      : "toprightbtn addUserbtn toprightbtn2 "
                  }
                >
                  {userDetails?.firstName + " "}
                  {userDetails?.lastName}
                </Dropdown.Toggle>
                <Dropdown.Menu className="addMore user-dropdown">
                  {role === false ? (
                    <>
                      <Dropdown.Item>
                        <Link to="/add-user">Add User</Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link to="/users">User List</Link>
                      </Dropdown.Item>
                    </>
                  ) : (
                    ""
                  )}
                  <Dropdown.Item>
                    <div
                      className="logoutText"
                      onClick={() => {
                        logOut();
                      }}
                    >
                      <img className="logOutIcon" src={logout} alt="" />
                      Logout
                    </div>
                  </Dropdown.Item>
                  {/* <div className="logOutBtn">
                    <button
                      type="button"
                      onClick={() => {
                        logOut();
                      }}>
                      Logout
                    </button>
                  </div>{" "} */}
                </Dropdown.Menu>
              </Dropdown>

              <p>ID {userDetails?.email}</p>
            </div>
          </div>
        </li>
        <li>
          <Link href="/">
            {" "}
            <img src="" alt="" />
          </Link>
        </li>
      </ul>
      <SideDrawer showSideMenu={showSideMenu} hideSideMenu={hideSideMenu} />
    </div>
  );
};

export default HeaderMenu;
