import { React, useState } from "react";
import { handleKeyDown } from "../../helpers/helpers";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

const AddPlantHealthControlForm = ({
  index,
  formData,
  changeFormValue,
  deleteEggLog,
}) => {
  const onInputChange = (value, key) => {
    changeFormValue(index, value, key);
  };

  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const [selectedId, setSelectedId] = useState("");

  const handleClose = () => {
    setShow(false);
    setOpacity(0);
  };
  const handleShow = () => {
    setShow(true);
    setOpacity(1);
  };

  const handleDelete = () => {
    deleteEggLog(selectedId);
    handleClose();
  };

  return (
    <div>
      <div className="prodctRow">
        <label className="prodctRowCol1">{`${formData.animalTypeName} Details`}</label>
        <div className="prodctRowinputBoxes">
          <div className="row">
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Disease Name</label>
                <input
                  required
                  type="text"
                  value={formData?.diseaseName}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "diseaseName");
                  }}
                  placeholder="Enter Disease Name"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Villages Affected</label>
                <input
                  required
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={formData?.villagesAffected}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "villagesAffected");
                  }}
                  placeholder="Enter Villages Affected"
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Severity</label>
                <input
                  required
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={formData?.severity}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "severity");
                  }}
                  placeholder="Enter Severity"
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="inputAdd">
                <label className="inputAddLabel">Comment</label>
                <input
                  required
                  type="text"
                  value={formData?.comment}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "comment");
                  }}
                  placeholder="Enter Comment"
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="inputAdd">
                <label className="inputAddLabel">
                  Agro-Chemical Recommended
                </label>
                <input
                  required
                  type="text"
                  value={formData?.agroRecommended}
                  name="small"
                  onChange={(e) => {
                    onInputChange(e.target.value, "agroRecommended");
                  }}
                  placeholder="Enter Agro Recommended."
                />
              </div>
            </div>
          </div>
        </div>
        <div className="planthealthDeletebtn">
          <Button
            key={formData.animalTypeName}
            className="addEggdlt planthealthdltbutton"
            onClick={() => {
              handleShow();
              console.log(formData.cropId);
              setSelectedId(formData.cropId);
            }}
          >
            Delete
          </Button>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ opacity: opacity }}
      >
        <Modal.Header closeButton>
          <Modal.Title> Delete?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you wish to proceed with delete?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleDelete();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddPlantHealthControlForm;
