import { React, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { handleKeyDown } from "../../helpers/helpers";
import showNotification from "../../components/notification/notification";

const EggLaid = ({
  logDetailsIndex,
  firstIndex,
  logDetails,
  changeFormValue,
  deleteEggLog,
}) => {
  const [show, setShow] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const [selectedId, setSelectedId] = useState("");

  const handleClose = () => {
    setShow(false);
    setOpacity(0);
  };
  const handleShow = () => {
    setShow(true);
    setOpacity(1);
  };

  const handleDelete = () => {
    deleteEggLog(firstIndex, selectedId);
    handleClose();
  };

  const onInputChange = (index, value) => {
    const inputValue = value;
    const maxValue = 1000000;

    if (inputValue === "" || parseInt(inputValue) <= maxValue) {
      changeFormValue(firstIndex, logDetailsIndex, index, value);
    } else {
      // alert("Value exceeds the limit of " + maxValue);
      showNotification(`"Value exceeds the limit of " + ${maxValue}`, "danger");
    }
  };
  // const handleKeyDown = (event) => {
  //   if (
  //     (event.key >= '0' && event.key <= '9') ||
  //     event.key === 'Backspace' ||
  //     event.key === 'Delete' ||
  //     event.key === 'ArrowLeft' ||
  //     event.key === 'ArrowRight' ||
  //     event.key === 'Home' ||
  //     event.key === 'End'
  //   ) {
  //     // Allow the key
  //   } else {
  //     // Prevent the key press for all other keys
  //     event.preventDefault();
  //   }
  // };

  return (
    <div className="prodctRow addEggRows">
      <label className="prodctRowCol1">{logDetails.name + " Eggs"}</label>
      <div className="row">
        {logDetails.eggCount.map((eggDetails, index) => {
          return (
            <div className="col-md-3">
              <div className="inputAdd">
                <label className="inputAddLabel">{eggDetails.name}</label>
                <input
                  type="number"
                  value={eggDetails.count}
                  max="1000000"
                  name="small"
                  onKeyDown={handleKeyDown}
                  maxLength={7}
                  onChange={(e) => {
                    onInputChange(index, e.target.value);
                  }}
                  placeholder="Enter Qty"
                  required
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>
            </div>
          );
        })}
      </div>
      {/* <div div className="col-md-2"> */}
      <Button
        className="addEggdlt"
        variant="primary"
        onClick={() => {
          handleShow();
          setSelectedId(logDetails.colorId);
        }}
      >
        Delete
      </Button>

      {/* </div> */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ opacity: opacity }}
      >
        <Modal.Header closeButton>
          <Modal.Title> Delete?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you wish to proceed with delete?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleDelete();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EggLaid;
