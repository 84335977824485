import { React, useEffect, useState } from "react";
import EggLaid from "./egg-laid";

const Animaltype = ({
  firstIndex,
  animalData,
  changeFormValue,
  deleteEggLog,
}) => {
  return (
    <div>
      <label className="prodctRowCol1">
        {"Animal: " + animalData.animalTypeName}{" "}
      </label>
      {animalData.logDetails.map((e, index) => (
        <EggLaid
          logDetailsIndex={index}
          firstIndex={firstIndex}
          key={index + e}
          logDetails={e}
          changeFormValue={changeFormValue}
          deleteEggLog={deleteEggLog}
        />
      ))}
    </div>
  );
};

export default Animaltype;
